import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { AppState } from '../redux-modules/root';
import { ContainerProps } from './Container';
import * as family from '../redux-modules/family';
import * as dynamicclient from '../redux-modules/dynamicclient';
import * as Types from '../api/definitions';
import FamilySearch from '../components/FamilySearchComponent/FamilySearch';
import DynamicFamilyDetailsContainer from './DynamicFamilyDetailsContainer';
import DynamicNewFamilyContainer from './DynamicNewFamilyContainer';
import EditClientContainer from './EditClientContainer';
import { KeyUpService } from '../Services/KeyUpService';
import { domainPath } from '../App';
interface MatchParams {
    index: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

export interface DynamicExistingFamilyContainerState {
    isLoading: boolean;
    error: string;
    hasError: boolean;
    program_completion_response: string | null;
    is_Searched: boolean;
    config: string;
    subConfig: string;
}

export interface DynamicExistingFamilyContainerProp extends ContainerProps, WithSnackbarProps {
    searchDFamily: (
        client_code: string,
        ssn: string,
        first_name: string,
        last_name: string,
        dob: string,
        is_accessToken: any,

        version: number,
        id_n: string
    ) => void;
    dynamicSearchFamily: (
        data: any,
        is_accessToken: string,
        configType: string,
        subConfigType: string,
        keySearch: any
    ) => void;
    getSearchFields: (  config: string, subConfig: string, is_accessToken: string ) => void;
    // getAvailablePrograms: () => Promise<void>;
    // getReferral: () => Promise<void>;
}

export class DynamicExistingFamilyContainer extends React.Component<
    DynamicExistingFamilyContainerProp,
    DynamicExistingFamilyContainerState
> {
    constructor(props: DynamicExistingFamilyContainerProp) {
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState() {
        return {
            isLoading: false,
            hasError: false,
            error: '',
            program_completion_response: null,
            is_Searched: false,
            config: "",
            subConfig: ""
        };
    }

    async componentDidMount() {
        this.props.closeSnackbar();
        const is_accessToken: string | any = this.props.user && this.props.user.user.accessToken;
        const {Config, subConfig} = KeyUpService.getConfSubConf(this.props.location.pathname);
        await this.props.getSearchFields(Config, subConfig, is_accessToken)
        this.setState({config:Config, subConfig:subConfig})
    }

    async componentDidUpdate(prevProps, prevState) {
        const is_accessToken: string | any = this.props.user && this.props.user.user.accessToken;
        if (!this.props.location.pathname?.includes("edit-details") &&this.props.location !== prevProps.location) {
            const {Config, subConfig} = KeyUpService.getConfSubConf(this.props.location.pathname);
            await this.props.getSearchFields(Config, subConfig, is_accessToken)
            this.setState({config:Config, subConfig:subConfig})
        }
      }

    dynamicSearchFamily = async (
        data: any,
        keySearch: any
    ) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        try {
            const {Config, subConfig} = KeyUpService.getConfSubConf(this.props.location.pathname);
            await this.props.dynamicSearchFamily(data, is_accessToken, Config, subConfig, keySearch);
            this.setState({
                is_Searched: true
            });
        } catch (error) {
            console.log(error);
            const { history } = this.props;
            if (error.status === 403) {
                history.push(`/${domainPath}/logout/`);
            }
        }
    };

    searchDFamily = async (
        client_code: string,
        ssn: string,
        first_name: string,
        last_name: string,
        dob: string,
        id_n: string
    ) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        // const locationVersion =
        //     this.props && this.props.location && this.props.location.state && this.props.location.state.version;
        const locationVersionComplete =
            this.props && this.props.location && (this.props.location.state as Types.CustomizedHistoryState);
        const locationVersion: string = locationVersionComplete.version;

        const version: any =
            locationVersion &&
            (parseInt(locationVersion.substring(1)) > 0
                ? parseInt(locationVersion.substring(1)) - 1
                : parseInt(locationVersion.substring(1)));
        try {
            await this.props.searchDFamily(client_code, ssn, first_name, last_name, dob, is_accessToken, version, id_n);
            this.setState({
                is_Searched: true
            });
        } catch (error) {
            console.log(error);
            const { history } = this.props;
            if (error.status === 403) {
                history.push(`/${domainPath}/logout/`);
            }
        }
    };

    isDataAvailable = () => {
        this.setState({
            is_Searched: false
        });
    };

    render() {
        const { family: familyState, client: clientState } = this.props;
        const familyList = (familyState && familyState.familyList) || {};
        const uniqueField: any = (familyState && familyState.familyUniqueId) || '';
        const familyQuestionsList: any = (familyState && familyState.familyQuestionsList) || '';
        const searchFieldsList = (familyState && familyState.searchFieldsList) || [];
        const headerColor: any = this.props.user && this.props.user.user.header_color;
        const { is_Searched } = this.state;
        
        return (
            <Switch>
                <Route exact path={`/${domainPath}/existing-family/config=:config&subConfig=:subConfig`}>
                    <FamilySearch
                        familyList={is_Searched ? Object.values(familyList) : []}
                        {...this.state}
                        headerColor={headerColor}
                        user={this.props && this.props.user}
                        onFormSubmit={this.searchDFamily}
                        onSearchSubmit={this.dynamicSearchFamily}
                        clearData={this.isDataAvailable}
                        searchFieldsList={searchFieldsList}
                        uniqueField={uniqueField}
                        configuredQuestionsList={familyQuestionsList}
                    />
                </Route>
                <Route
                    exact
                    path={`/${domainPath}/existing-family/edit-details/config=:config&subConfig=:subConfig&index=:index&isEdit=:isEdit&Rerefer=:Rerefer`}
                    
                    component={DynamicNewFamilyContainer}
                ></Route>
                <Route
                    exact
                    path={`/${domainPath}/existing-family/family-details/config=:config&subConfig=:subConfig&index:index`}
                    component={DynamicFamilyDetailsContainer}
                ></Route>
                <Route
                    exact
                    path={`/${domainPath}/existing-family/edit-details/:index/2`}
                    component={EditClientContainer}
                ></Route>
                
            </Switch>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        family: state.family,
        client: state.client,
        program: state.program,
        referral: state.referral,
        user: state.user
    };
};

const mapDispatchToProps = {
    searchDFamily: family.actions.searchDFamily,
    dynamicSearchFamily: family.actions.dynamicSearchFamily,
    getSearchFields: family.actions.getSearchFields
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(DynamicExistingFamilyContainer));