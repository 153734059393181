interface Condition {
    section: string;
    question: string;
    answer_type: string;
    questionsList: string[];
    answersList: string[];
    selectedAnswers: string[];
}

interface Selection {
    section: string;
    question: string[];
    questionsList: string[];
}

interface Section {
    section: string;
}

export interface InitialState {
    displayLink: string;
    conditions: Condition[];
    selections: Selection[];
    time_filter: string;
    date_field: string;
    days_count: number | null;
    comparison: string;
    sections: Section[];
    showConfirmModal: boolean;
    action: string;
    showMessage: boolean;
    showLoader: boolean;
    customerId: number | null;
    selectedOptions: string[]; // Assuming selectedOptions is an array of strings
    datequestions: string[]; // Assuming datequestions is an array of strings
    filterBy: string[];
    start_date: string;
    end_date: string;
}

export const initialCondition: Condition = 
    {
        section: '',
        question: '',
        answer_type: '',
        questionsList: [],
        answersList: [],
        selectedAnswers: []
    }

    export const initialSelection: Selection = { section: '', question: [], questionsList: [] }

export const initialState: InitialState = {
    displayLink: '',
    conditions: [],
    selections: [],
    time_filter: '',
    date_field: '',
    days_count: null,
    comparison: '',
    sections: [],
    showConfirmModal: false,
    action: '',
    showMessage: false,
    showLoader: false, 
    customerId: null,
    selectedOptions: [],
    datequestions: [],
    filterBy: [],
    start_date: '',
    end_date: ''
};
