/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { fieldRow, twoCol, txtDetail, txtLabel, dataTable, tableHeader, tableRow } from '../styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const DisplayDynamicQuestions = ({ data, index, is_date, sections }) => {

    const checkListAnswers = (item, listAnswers) => {
        if( data?.child_questions?.length > 0){
        let filterAnswers = sections?.flatMap((sec) => sec.questions.filter((list)=> list.question === item));
        filterAnswers = filterAnswers[0].suggested_answers.map((ans) => listAnswers.includes(ans.id?.toString()) || listAnswers.includes(ans.value) ? ans.value : null);
        filterAnswers = filterAnswers.filter((item) => item !== null)?.toString();
        
       return filterAnswers;
        } else {
            return null;
        }
    }
    
    return (
        <React.Fragment>
            <div css={twoCol} key={index}>
                <label css={txtLabel}>{data.question}</label>
                <div css={txtDetail}>{data.answer?.value}</div>

                {data.child_questions.length > 0 &&
                    data.child_questions.find((item) => item.answer === data.answer?.value) && (
                        <Table aria-label="clients table" css={dataTable}>
                            <TableHead>
                                <TableRow css={tableHeader}>
                                    {data.child_questions
                                        .find((item) => item.answer === data.answer?.value)
                                        ?.questions?.map((item, i) => (
                                            <TableCell>{item}</TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.answer?.repeated.map((list) => (
                                    <TableRow>
                                        {Object.keys(list).map((item, i) => (
                                            <TableCell>
                                                <div css={txtDetail}>
                                                    {list[item]?.includes('/')
                                                        ? is_date(list[item]?.replace( /\//g,"-"))
                                                        : Array.isArray(list[item]) ? 
                                                        checkListAnswers(item, list[item])
                                                        : list[item]}
                                                </div>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
            </div>
            {/* <div css={twoCol} key={index}>
                {data.child_questions.length > 0 &&
                    data.child_questions.find((item) => item.answer === data.answer?.value) &&
                    data.answer?.repeated.map((list) =>
                        Object.keys(list).map((item, i) => (
                            <div  key={i}>
                                <label css={txtLabel}>{item}</label>
                                <div css={txtDetail}>{list[item]}</div>
                                <br />
                            </div>
                        ))
                    )}
            </div> */}
        </React.Fragment>
    );
};

export default DisplayDynamicQuestions;
