/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Button } from '@material-ui/core';
import { ErrorMessage } from 'formik';
import { inputField, searchScreenDateinputField, label } from './styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import { style } from '../components/FamilySearchComponent/IFamilySearch.Interface';
import { selectField } from '../components/styles';


const DynamicSearchFields: React.FC<any> = ({
    searchFieldsList,
    handleChange,
    setFieldValue,
    headerColor,
    values,
    dateHandler
}) => {
    const classes = style();
    
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {searchFieldsList.map((item, index) =>
                    ['text', 'number'].includes(item.answer_type?.toLowerCase()) ? (
                        <Grid item xs={3} key={index}>
                            <label css={label}>
                                <strong>{item.question}</strong>
                            </label>
                            <input
                                type={item.answer_type}
                                name={item.question}
                                css={inputField}
                                placeholder={item.question}
                                value={values[item.question] || ''}
                                onChange={handleChange}
                                //style={{ width: '165px' }}
                            />
                            <div style={{ color: 'red' }}>
                                <ErrorMessage component="span" name={item.question} />
                            </div>
                        </Grid>
                    ) : item.answer_type?.toLowerCase() === 'date' ? (
                        <Grid item xs={3} key={index}>
                            <label css={label}>
                                <strong>{item.question}</strong>
                            </label>
                            <KeyboardDatePicker
                                clearable
                                //label="DOB"
                                css={searchScreenDateinputField}
                                name={item.question}
                                value={values[item.question] || null}
                                onChange={(date) =>
                                    dateHandler(date, item.question, function (result) {
                                        if (result) {
                                            setFieldValue(item.question, result);
                                        } else {
                                            setFieldValue(item.question, null);
                                        }
                                    })
                                }
                                format="MM/dd/yyyy"
                                placeholder="mm/dd/yyyy"
                                autoOk={true}
                                allowKeyboardControl={true}
                                animateYearScrolling={true}
                                inputVariant="filled"
                                orientation="landscape"
                                defaultValue={new Date('1900-01-01')}
                            />
                            {/* </MuiPickersUtilsProvider> */}
                            <div style={{ color: 'red' }}>
                                <ErrorMessage component="span" name={item.question} />
                            </div>
                        </Grid>
                    ) : ['select', 'radio'].includes(item.answer_type?.toLowerCase()) ? (
                        <Grid item xs={3} key={index}>
                            <label css={label}>
                                <strong>{item.question}</strong>
                            </label>
                            <select name={item.question} css={selectField} onChange={handleChange}>
                                <option value="">{item.question}</option>
                                {item.suggested_answers.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.value}
                                    </option>
                                ))}
                            </select>
                            <div style={{ color: 'red' }}>
                                <ErrorMessage component="span" name={item.question} />
                            </div>
                        </Grid>
                    ) : (
                        ''
                    )
                )}
            </Grid>
            <Grid container justifyContent="flex-end">
                <Grid item xs={3}>
                    <Button
                        className={classes.titleItemRight}
                        type="submit"
                        size="small"
                        variant="contained"
                        style={{
                            marginLeft: 100,
                            backgroundColor: headerColor,
                            color: '#fff',
                            height: '44px',
                            marginTop: '11px'
                        }}
                    >
                        <SearchIcon />
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default DynamicSearchFields;
