/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LineChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Line } from 'recharts';
import { selectField, panel, panelHeading, panelHeader, txtLabel, tableHeader, tableRow, dataTable } from '../styles';
import { Card } from '@material-ui/core';
import CustomTooltip from './Utils/CustomTooltip';

interface Market_AnalyticsProps {
    marketList: any;
    Referral: any;
    PerformanceList: any;
    referralChange: (src: any) => void;
}

const Market_Analytics: React.FC<Market_AnalyticsProps> = (props) => {
    let referralsourcedata = props.PerformanceList;
    const [referral_source, setreferral_source] = useState('0');
    useEffect(() => {
        props.referralChange(referral_source);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referral_source]);

    // const CustomTooltip: any = ({ active, payload, labelText }) => {
    //     console.log({ payload });
    //     if (active && payload && payload.length) {
    //         return (
    //             <div
    //                 style={{
    //                     backgroundColor: 'black',
    //                     opacity: 0.6,
    //                     color: 'white',
    //                     borderRadius: '5px',
    //                     padding: '4px'
    //                 }}
    //             >
    //                 <p style={{ marginBottom: 0, marginTop: '1px' }}>{`${payload[0]?.payload?.date}`}</p>
    //                 <div>
    //                     <span
    //                         style={{
    //                             height: '13px',
    //                             width: '13px',
    //                             backgroundColor: payload[0]?.color,
    //                             borderRadius: '50%',
    //                             display: 'inline-block'
    //                         }}
    //                     ></span>
    //                     <p
    //                         style={{ paddingLeft: '10px', display: 'inline' }}
    //                     >{`${labelText} : ${payload[0]?.payload?.count}`}</p>
    //                 </div>
    //             </div>
    //         );
    //     }

    //     return null;
    // };

    return (
        <div>
            <Accordion>
                <AccordionSummary css={panelHeader} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                    <h1 css={panelHeading}>Referral Source Analysis ​</h1>
                </AccordionSummary>
                <AccordionDetails css={panel}>
                    <Table aria-label="clients table" css={dataTable}>
                        <TableHead>
                            <TableRow css={tableHeader}>
                                <TableCell>Referral Source Name</TableCell>
                                <TableCell>Number of referrals​</TableCell>
                                <TableCell>Contribution in %</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.marketList.length > 0 ? (
                                props.marketList.map((pgm, key) => (
                                    <TableRow hover key={key} css={tableRow}>
                                        <TableCell>{pgm.referral_source}</TableCell>
                                        <TableCell>{pgm.count}</TableCell>
                                        <TableCell>{pgm.percentage}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow key={9999}>
                                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                        No results found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>

            <br />
            <Grid item xs={6} sm={3}>
                <label
                    css={txtLabel}
                    //style={{ marginTop: 16 }}
                >
                    Referral sources:
                </label>

                <select
                    css={selectField}
                    name="referral_source"
                    value={referral_source || ''}
                    onChange={(e) => setreferral_source(e.target.value)}
                >
                    {props.Referral.map((r) => (
                        <option key={r.id} value={r.id}>
                            {r.value}
                        </option>
                    ))}
                </select>
            </Grid>
            <Card style={{ padding: '20px', marginTop: '10px' }}>
                <h4 style={{ marginBottom: '30px' }}>Performance Graph</h4>
                <ResponsiveContainer width="100%" aspect={3}>
                    <LineChart
                        width={500}
                        height={300}
                        data={referralsourcedata}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip labelText="Number of referrals" nameKey="date" />} />
                        <Line
                            isAnimationActive={false}
                            type="monotone"
                            dataKey="count"
                            stroke="#ffae00"
                            activeDot={{ r: 8 }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Card>
        </div>
    );
};

export default Market_Analytics;
