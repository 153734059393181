/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useHistory, withRouter } from 'react-router-dom';
import { Formik, FormikErrors } from 'formik';
import 'react-loading-skeleton/dist/skeleton.css';
import Table from '@material-ui/core/Table';
import { wrap, subHeading, mainContent, dataTable, fieldRow, txtDetail } from '../styles';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { FamilySearchProps, FormValues, initialStateValues } from './IFamilySearch.Interface';
import DynamicSearchFields from '../DynamicSearchFields';
import DynamicTableListView from '../DynamicTableListView';
import * as ValidateRegex from '../../Common/CommonRegex';
import { downloadDataDumpReport } from '../../api/api';
import ClientSearchFilters from '../ClientSearchFilters';
import { KeyUpService } from '../../Services/KeyUpService';
import { Button } from '@material-ui/core';

const FamilySearch: React.FC<FamilySearchProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [searchItems, setSearchItems] = useState<any>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const history = useHistory();
    const {Config, subConfig} = KeyUpService.getConfSubConf(props.location.pathname);
    /** */
    let { familyList, searchFieldsList, user } = props;

    useEffect(() => {
        props.clearData();
        if (props.searchFieldsList.length > 0) {
            let list = searchFieldsList.map((cl) => cl.question);
            setSearchItems(list);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadDump = async (data) => {
        const is_accessToken: any = props.user && props.user.user.accessToken;
        await downloadDataDumpReport(data,is_accessToken, Config, subConfig);
    };

    familyList = familyList.map((sec) => sec.sections);
    const family =
        searchFieldsList.length > 0
            ? props.familyList.length > 0 &&
              props.familyList.map((q) =>
                  q.sections.map((list) => list.questions.filter((item) => searchItems.includes(item.question)))?.flat()
              )
            : familyList.map((q) => q[0].questions);

    const is_date = (date) => {
        let dateObj: any = new Date(date.replace(/-/g, '/'));

        if (ValidateRegex.dateRegex.test(date)) {
            let year = dateObj.getFullYear();
            let month = 1 + dateObj.getMonth();
            let datee = dateObj.getDate();
            let date1 = [month.toString().length > 1 ? month : `0${month}`, datee > 9 ? datee : `0${datee}`, year].join(
                '/'
            );
            return date1;
        }
        return date;
    };

    let dynamicInitialStateValues: any = {};
    let dynamicFormValues: any = {};

    Array.isArray(searchFieldsList) &&
        searchFieldsList.map(
            (item, index) => (
                (dynamicInitialStateValues[item.question] = ''), (dynamicFormValues[item.question] = 'string')
            )
        );

    interface DFormValues {
        [key: string]: any;
    }
   
    const handleDatePicker = (date, name, callBack) => {
        let customDate = '';
        if (date) {
            var date2 = new Date(date),
                mnth = ('0' + (date2.getMonth() + 1)).slice(-2),
                day = ('0' + date2.getDate()).slice(-2);
            customDate = [date2.getFullYear(), mnth, day].join('/');
        } else {
            customDate = '';
        }
        if (!['NaN-aN-aN', 'NaN/aN/aN']?.includes(customDate)) {
            dynamicInitialStateValues[name] = customDate;
            if(callBack) {
                callBack(customDate); 
            }
            
        }
        
    };

    return (
        <div css={wrap}>
            <div css={mainContent}>
            <div css={fieldRow} style={{ justifyContent: 'center' }}>
                    <Button
                        type="submit"
                        size="small"
                        variant="contained"
                        style={{
                            marginRight: 10,
                            backgroundColor: props.headerColor,
                            color: '#fff'
                        }}
                        css={txtDetail}
                        onClick={() => setOpenFilters(!openFilters)}
                    >
                        Download FirstMatch Data Report
                    </Button>
                    {openFilters && 
                    <ClientSearchFilters {...props} downloadDump={downloadDump} openFilters={openFilters} setOpenFilters ={setOpenFilters}/>
                }
               
                </div>
                <Formik
                    initialValues={searchFieldsList.length > 0 ? dynamicInitialStateValues : initialStateValues}
                    enableReinitialize
                    validate={(values) => {
                        const errors: FormikErrors<FormValues | DFormValues> = {};

                        if(Object.keys(dynamicInitialStateValues)?.some((item) => dynamicInitialStateValues[item].includes('/'))){
                            values = Object.assign({}, values, dynamicInitialStateValues);
                        }

                        if (!Object.values(values).some((item) => item)) {
                            Object.keys(values).map((item) => (errors[item] = 'Enter either of the fields'));
                        }
                        return errors;
                    }}
                    onSubmit={async (values, helpers) => {
                        const code: any = values.client_code ? parseInt(values.client_code) : '';
                        const ssncode: any = values.ssn ? parseInt(values.ssn) : '';
                        const dob = values.dob ? format(new Date(values.dob), 'yyyy-MM-dd') : '';
                        const id_n = values.id_n ? values.id_n : '';
                        
                        if(Object.keys(dynamicInitialStateValues)?.some((item) => dynamicInitialStateValues[item].includes('/'))){
                            values = Object.assign({}, values, dynamicInitialStateValues);
                        }
                        
                        Object.keys(values).map((item) => {
                            if (ValidateRegex.numberRegex.test(values[item])) {
                                values[item] = parseInt(values[item]);
                            }
                        });
                        history.push({ state: { version: undefined } });
                        setLoading(true);
                        if (searchFieldsList.length > 0) {
                            await props.onSearchSubmit(values, props.uniqueField);
                        } else {
                            await props.onFormSubmit(code, ssncode, values.first_name, values.last_name, dob, id_n);
                        }
                        setLoading(false);
                        // helpers.resetForm();
                    }}
                >
                    {({ values, handleSubmit, handleChange, setFieldValue }) => (
                        <form name="FamilySearchForm" onSubmit={handleSubmit}>
                            <Table aria-label="clients table" css={dataTable}>
                                {searchFieldsList.length > 0 && (
                                    <DynamicSearchFields
                                        searchFieldsList={searchFieldsList}
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                        headerColor={props.headerColor}
                                        values={values}
                                        dateHandler={handleDatePicker}
                                    />
                                )}
                            </Table>
                        </form>
                    )}
                </Formik>
                <div>
                    <h1 css={subHeading} style={{ color: props.headerColor }}>
                        Family List
                    </h1>
                    <DynamicTableListView
                        searchFieldsList={searchFieldsList}
                        listDetails={family}
                        loading={loading}
                        is_date={is_date}
                        type="family"
                        uniqueField={props.uniqueField}
                        config={props.config}
                        subConfig={props.subConfig}
                    />
                </div>
            </div>
            {/* MAIN CONTENT */}
        </div>
    );
};

export default withRouter(FamilySearch);