import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react';
import { models, Report, Embed, service, Page } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { subHeading, panelHeading } from './styles';
import { getEmbedAnalytics } from '../api/api';
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}));
interface apiResponse {
    ReportId: string;
    EmbedUrl: string;
    EmbedToken: string;
}

function EmbedDashboard() {
    const classes = useStyles();
    let {
        user: { header_color, accessToken }
    } = useSelector((state: any) => state.user);
    const [dashboardConfig, setDashboardConfig] = useState<apiResponse>({ ReportId: '', EmbedUrl: '', EmbedToken: '' });
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let getEmbedDashboard = async () => {
            setLoading(true);

            const getembedres = await getEmbedAnalytics(accessToken);

            if (getembedres.status === 'failed') {
                setError(getembedres.message);
            } else {
                setDashboardConfig({
                    ReportId: getembedres.response.report_id,
                    EmbedUrl: getembedres.response.embed_url,
                    EmbedToken: getembedres.response.embed_token
                });
            }

            // const response = await axios
            //     .get(
            //         'https://firstmatchfunctionapp.azurewebsites.net/api/HttpTrigger1?code=rBsqvnm2VerusoYeSSi4uUHX7yddMI9BHadiHFHBZQ9CAzFuEv6IZQ==&name=FirstMatchFunctionApp',
            //         {
            //             headers: {
            //                 'Content-Type': 'application/json'
            //             }
            //         }
            //     )
            //     .then((res) => {
            //        return res.data
            //     })
            //     .catch((error) => error);

            setLoading(false);
        };
        getEmbedDashboard();
    }, []);

    let customError =
        error &&
        error
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');

    return (
        <Fragment>
            <Grid item xs={12}>
                <h1 css={subHeading} style={{ color: header_color }}>
                    Dashboard
                </h1>
            </Grid>
            {isLoading ? (
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : customError ? (
                <Grid item xs={12} spacing={5}>
                    <h3 css={panelHeading} style={{ color: 'red', textAlign: 'center' }}>
                        {customError}
                    </h3>
                </Grid>
            ) : (
                <header>
                    <PowerBIEmbed
                        embedConfig={{
                            //hostname: "https://app.powerbigov.us/",
                            type: 'report', // Supported types: report, dashboard, tile, visual and qna
                            id: dashboardConfig.ReportId,
                            embedUrl: dashboardConfig.EmbedUrl,
                            accessToken: dashboardConfig.EmbedToken,
                            tokenType: models.TokenType.Embed,
                            settings: {
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: false
                                    },
                                    pageNavigation: {
                                        visible: true
                                    }
                                },
                                background: models.BackgroundType.Transparent
                            }
                        }}
                        eventHandlers={
                            new Map([
                                [
                                    'loaded',
                                    function () {
                                        console.log('Report loaded');
                                    }
                                ],
                                [
                                    'rendered',
                                    function () {
                                        console.log('Report rendered');
                                    }
                                ],
                                [
                                    'error',
                                    function (event) {
                                        console.log(event);
                                    }
                                ]
                            ])
                        }
                        cssClassName={'embed_container report-style-class'}
                    />
                </header>
            )}
        </Fragment>
    );
}
export default EmbedDashboard;
