export const menuData = [
    {
        organization_type: 1,
        id: 1,
        menu_list: [
            {
                name: 'Account',
                link: 'changepassword',
                id: 1,
                checkCondition: true,
                condition: (values) => values.is_configured === true && values.is_pwd_expired
            },
            {
                name: 'Dashboard',
                link: 'embed-dashboard',
                id: 2,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true &&
                    values.is_dashboard_published === 'yes' &&
                    !values.is_pwd_expired
            },
            {
                name: 'New Client',
                link: 'new-client',
                referralType: 'referral_1',
                configType: 1,
                
                id: 3,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Existing Client',
                link: 'existing-client',
                referralType: 'referral_1',
                configType: 1,
                id: 4,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Notifications',
                link: 'notifications',
                referralType: 'referral_1',
                configType: 1,
                id: 6,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Billing',
                link: 'billing',
                id: 7,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true && values.billing_status === true && values.role_type !== 'Tester' && !values.is_pwd_expired
            },
            {
                name: 'Account',
                link: 'changepassword',
                id: 8,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Users',
                link: 'configuration/users',
                id: 9,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true &&
                    (!values.is_pwd_expired || values.role_type?.toLowerCase() !== 'tester')
            },
            {
                name: 'Logout',
                link: 'logout',
                id: 10,
                checkCondition: false,
                condition: (values) => values.is_configured === true
            }
        ]
    },
    {
        organization_type: 2,
        id: 2,
        menu_list: [
            {
                name: 'Account',
                link: 'changepassword',
                id: 1,
                checkCondition: true,
                condition: (values) => values.is_configured === true && values.is_pwd_expired
            },
            {
                name: 'Dashboard',
                link: 'embed-dashboard',
                id: 2,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true &&
                    values.is_dashboard_published === 'yes' &&
                    !values.is_pwd_expired 
            },
            {
                name: 'New Client',
                link: 'new-client',
                referralType: 'referral_1',
                configType: 1,
                
                id: 3,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Existing Client',
                link: 'existing-client',
                referralType: 'referral_1',
                configType: 1,
                id: 4,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Notifications',
                link: 'notifications',
                referralType: 'referral_1',
                configType: 1,
                id: 6,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Billing',
                link: 'billing',
                id: 7,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true && values.role_type != 'Tester' && !values.is_pwd_expired
            },
            {
                name: 'Account',
                link: 'changepassword',
                id: 8,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Users',
                link: 'configuration/users',
                id: 9,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true &&
                    (!values.is_pwd_expired || values.role_type?.toLowerCase() !== 'tester')
            },
            {
                name: 'Logout',
                link: 'logout',
                id: 10,
                checkCondition: false,
                condition: (values) => values.is_configured === true
            }
        ]
    },
    {
        organization_type: 3,
        id: 3,
        menu_list: [
            {
                name: 'Account',
                link: 'changepassword',
                id: 1,
                checkCondition: true,
                condition: (values) => values.is_configured === true && values.is_pwd_expired
            },
            {
                name: 'Dashboard',
                link: 'embed-dashboard',
                id: 2,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true &&
                    values.is_dashboard_published === 'yes' &&
                    !values.is_pwd_expired 
            },
            {
                name: 'New Client',
                link: 'new-client',
                referralType: 'referral_1',
                configType: 1,
                
                id: 3,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Existing Client',
                link: 'existing-client',
                referralType: 'referral_1',
                configType: 1,
                id: 4,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'New Family',
                link: 'new-family',
                referralType: 'referral_2',
                configType: 2,
                
                id: 5,
                checkCondition: true,
                condition: (values) => values.is_configured && !values.is_pwd_expired
            },
            {
                name: 'Existing Family',
                link: 'existing-family',
                referralType: 'referral_2',
                configType: 2,
                id: 5,
                checkCondition: true,
                condition: (values) => values.is_configured && !values.is_pwd_expired
            },
            {
                name: 'Notifications',
                link: 'notifications',
                referralType: 'referral_1',
                configType: 1,
                id: 6,
                checkCondition: true,
                condition: (values) => values.is_configured && !values.is_pwd_expired
            },
            {
                name: 'Billing',
                link: 'billing',
                id: 7,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true && values.role_type != 'Tester' && !values.is_pwd_expired
            },
            {
                name: 'Account',
                link: 'changepassword',
                id: 8,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Users',
                link: 'configuration/users',
                id: 9,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true &&
                    (!values.is_pwd_expired || values.role_type?.toLowerCase() !== 'tester')
            },
            {
                name: 'Logout',
                link: 'logout',
                id: 10,
                checkCondition: false,
                condition: (values) => values.is_configured === true
            }
        ]
    },
    {
        organization_type: 4,
        id: 4,
        menu_list: [
            {
                name: 'Account',
                link: 'changepassword',
                id: 1,
                checkCondition: true,
                condition: (values) => values.is_configured === true && values.is_pwd_expired
            },
            {
                name: 'Dashboard',
                link: 'embed-dashboard',
                id: 2,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true &&
                    values.is_dashboard_published === 'yes' &&
                    !values.is_pwd_expired 
            },
            {
                name: 'New Client',
                link: 'new-client',
                referralType: 'referral_1',
                configType: 1,
                
                id: 3,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Existing Client',
                link: 'existing-client',
                referralType: 'referral_1',
                configType: 1,
                id: 4,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Notifications',
                link: 'notifications',
                referralType: 'referral_1',
                configType: 1,
                id: 6,
                checkCondition: true,
                condition: (values) => values.is_configured && !values.is_pwd_expired
            },
            {
                name: 'Billing',
                link: 'billing',
                id: 7,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true && values.role_type != 'Tester' && !values.is_pwd_expired
            },
            {
                name: 'Account',
                link: 'changepassword',
                id: 8,
                checkCondition: true,
                condition: (values) => values.is_configured === true && !values.is_pwd_expired
            },
            {
                name: 'Users',
                link: 'configuration/users',
                id: 9,
                checkCondition: true,
                condition: (values) =>
                    values.is_configured === true &&
                    (!values.is_pwd_expired || values.role_type?.toLowerCase() !== 'tester')
            },
            {
                name: 'Logout',
                link: 'logout',
                id: 10,
                checkCondition: false,
                condition: (values) => values.is_configured === true
            }
        ]
    }
  ];