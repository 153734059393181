/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { selectField, fieldRow, txtLabel, label, inputField } from '../styles';
import Helmeticon from './../../assets/helmet.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 240,
        width: 100
    },
    row: {
        height: 540,
        width: 100
    },
    iconClasses: {
        marginTop: '28px'
    }
}));
interface Occupancy_AnalyticsProps {
    Occupancy_analytics: any;
    Location: any;
    Referral: any;
    filter: any;
    onSelectChange: (src: any) => void;
}

const Occupancy_Analytics: React.FC<Occupancy_AnalyticsProps> = (props) => {
    const classes = useStyles();
    const [filters, setfilters] = useState({ referral_source: '', location: '', occupancy_date: '' });

    useEffect(() => {
        props.onSelectChange(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.referral_source, filters.location, filters.occupancy_date]);

    useEffect(() => {
        setfilters((prevState) => {
            return { ...prevState, referral_source: '', location: '', occupancy_date: '' };
        });
    }, [props.filter.days_count]);
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                    <label
                        css={txtLabel}
                        //style={{ marginTop: 16 }}
                    >
                        Referral sources:
                    </label>

                    <select
                        css={selectField}
                        name="referral_source"
                        value={filters.referral_source || ''}
                        onChange={(e) => {
                            const val = e.target.value;
                            setfilters((prevState) => {
                                return { ...prevState, referral_source: val, location: '', occupancy_date: '' };
                            });
                        }}
                    >
                        <option value="">Select</option>
                        {props.Referral.map((r) => (
                            <option key={r.id} value={r.id}>
                                {r.value}
                            </option>
                        ))}
                    </select>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <label
                        css={txtLabel}
                        //style={{ marginTop: 16 }}
                    >
                        Locations:
                    </label>

                    <select
                        css={selectField}
                        name="location"
                        value={filters.location || ''}
                        onChange={(e) => {
                            const val = e.target.value;
                            setfilters((prevState) => {
                                return { ...prevState, location: val };
                            });
                        }}
                    >
                        <option value="">Select</option>
                        {props.Location.map((l) => (
                            <option key={l.location} value={l.location_names}>
                                {l.location_names}
                            </option>
                        ))}
                    </select>
                </Grid>
                <Grid item xs={4}>
                    <label
                        css={label}
                        // style={{ marginTop: 16 }}
                    >
                        Occupancy Date:
                    </label>

                    <input
                        type="date"
                        name="occupancy_date"
                        css={inputField}
                        placeholder=""
                        value={filters.occupancy_date || ''}
                        onChange={(e) => {
                            const val = e.target.value;

                            setfilters((prevState) => {
                                return { ...prevState, occupancy_date: val };
                            });
                        }}
                    />
                </Grid>
            </Grid>

            <div css={fieldRow} style={{ display: 'grid', justifyContent: 'center', marginTop: '10px' }}>
                <Typography className={classes.root}>
                    <div className="pieWidth">
                        <div
                            style={{
                                borderRadius: '18px',
                                border: '4px solid #00E6A7',
                                padding: '20px',
                                width: '170px',
                                height: '200px',
                                textAlign: 'center',
                                marginTop: '20px'
                            }}
                        >
                            <img className={classes.iconClasses} src={Helmeticon} alt="Shuffle Crossing arrow" />
                            <p
                                style={{
                                    marginTop: '-5px',
                                    fontSize: '45px',
                                    color: '#00E6A7',
                                    fontWeight: 600
                                }}
                            >
                                {props.Occupancy_analytics.occupancy_rate
                                    ? props.Occupancy_analytics.occupancy_rate
                                    : 0}
                            </p>
                            <p
                                style={{
                                    marginTop: '-10px',
                                    fontSize: '12px',
                                    lineHeight: '1.3em',
                                    letterSpacing: '1px'
                                }}
                            >
                                Occupancy rate
                            </p>
                        </div>
                    </div>
                </Typography>
            </div>
        </div>
    );
};

export default Occupancy_Analytics;
