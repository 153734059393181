import { TraumaScoreService } from '../Services/TraumaScoreService';
import { QuestionJumpService } from '../Services/NestedQuestionService';
import { Persues_House_Score } from '../components/TramaQuestion';

export class DropDownService {
    static handleDropDown = async (e, data, idx, idy, state, updateState) => {
        let DynamicQuestions = state.DynamicQuestions;
        const value = e.hasOwnProperty('value') ? e.value?.toString() : e.target.value;
        const id = e.hasOwnProperty('value') ? e.id?.toString() :  e.target.dataset.id;
        let SelectedItem = data.suggested_answers.find((item) => item.id?.toString() === value?.toString())?.value;
        const name = DynamicQuestions[idx].questions[idy].question;
        let jumpto =
            data.suggested_jump.length > 0 && value?.toString()
                ? data.suggested_jump.find((sj) => !sj.is_deleted && SelectedItem === sj.answer)?.jumpto
                : [];

        let ques_jumpto =
            data.suggested_jump.length > 0 && value?.toString()
                ? data.suggested_jump.find((sj) => !sj.is_deleted && SelectedItem === sj.answer)?.question_jumpto
                : [];

        DynamicQuestions[idx].questions[idy].answer = value?.toString()
            ? DynamicQuestions[idx].questions[idy].is_parent === 'yes'
                ? {
                      value: DynamicQuestions[idx].questions[idy].suggested_answers.find(
                          (item) => item.id === Number(value)
                      )?.value,
                      repeated: []
                  }
                : DynamicQuestions[idx].questions[idy].suggested_answers.find((item) => item.id === Number(value))
                      ?.value
            : [];

        DynamicQuestions = await QuestionJumpService.handleNestedJump(DynamicQuestions, idx, name, []);
        // DynamicQuestions = await DropDownService.handleParentChildQuestion(
        //     idx,
        //     name,
        //     data,
        //     value,
        //     state,
        //     updateState,
        //     DynamicQuestions
        // );
        DynamicQuestions[idx].questions.forEach((que, i) =>
            ques_jumpto && ques_jumpto.includes(que.question)
                ? (DynamicQuestions[idx].questions[i].related = 'no')
                : state.prevQuestionJump[name] &&
                  state.prevQuestionJump[name].includes(que.question) &&
                  ((DynamicQuestions[idx].questions[i].related = 'yes'),
                  (DynamicQuestions[idx].questions[i].answer = ''))
        );

        DynamicQuestions.forEach((sec, i) =>
            jumpto && jumpto.includes(sec.section)
                ? (DynamicQuestions[i].related = 'false')
                : state.prevJump[name] &&
                  state.prevJump[name].includes(sec.section) &&
                  (DynamicQuestions[i].related = 'true')
        );

        DynamicQuestions = await DropDownService.MappedAnswersHandler(id, idx, idy, data, DynamicQuestions, 'false', updateState);
        
        updateState({
            DynamicQuestions,
            prevJump: {
                ...state.prevJump,
                [name]: jumpto,
                hasError: false
            },
            prevQuestionJump: {
                ...state.prevQuestionJump,
                [name]: ques_jumpto,
                hasError: false
            }
        });
        if (Persues_House_Score.length > 0) {
            await TraumaScoreService.addTraumaScore(name, id, state, updateState, Persues_House_Score);
        }

        if (DynamicQuestions[idx].questions[idy]?.api_call === 'yes') {
            state.onProgramSelect(value, name, state.uniqueIdField);
        }
    };

    static handleParentChildQuestion = (idx, name, data, value, state, updateState, DynamicQuestions) => {
        if (data.is_parent === 'yes' && data.child_questions.some((item) => item.answer === data.answer)) {
            DynamicQuestions = DynamicQuestions[idx].questions.filter((ques) => ques.is_child === 'yes');
            updateState({
                childQuestionsList: data.child_questions[0].questions
            });
        } else {
            updateState({
                childQuestionsList: []
            });
        }
        return DynamicQuestions;
    };

    static MappedAnswersHandler = (id, idx, idy, data, DynamicQuestions, isLoadTime, updateState) => {
        let mapped_answers_list = data.suggested_jump.find(
            (item) => item.answer?.toString() === data.answer?.toString()
        )?.mapping_answers;
        mapped_answers_list = mapped_answers_list?.filter((i) => i && i !== '' && i !== undefined && i !== null);

        if (mapped_answers_list && mapped_answers_list.length !== 0) {
            let getQuestions = mapped_answers_list?.map((item) =>
                DynamicQuestions[idx]?.questions.find(
                    (list, index) =>
                        list !== null &&
                        list.question === item.question &&
                        list.suggested_answers.map((ans, i) =>
                            item.answers?.includes(ans.value)
                                ? ((DynamicQuestions[idx].questions[index].suggested_answers[i].is_deleted = false),
                                  !isLoadTime && (DynamicQuestions[idx].questions[index].answer = ''))
                                : ((DynamicQuestions[idx].questions[index].suggested_answers[i].is_deleted = true),
                                  isLoadTime !== 'true' && (DynamicQuestions[idx].questions[index].answer = ''))
                        )
                )
            );
        }
        return DynamicQuestions;
    };
}
