
import React, { useEffect, useState, Fragment } from 'react'
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js'
import { domainPath } from '../App';
import Modal from 'react-modal';
import '../App.css';

const timeout = 1000 * 60 * 20
//const timeout = 10_000

const promptBeforeIdle = 4_000

const customStyles = {
    content: {
        top: '20%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '10%',
        border: '1px solid rgb(235 72 72)',
        boxShadow: '0px 2px 16px 0px #f44336'
    }
};


export default function IdleTimerContainer() {
  const [state, setState] = useState<string>('Active')
  const [remaining, setRemaining] = useState<number>(timeout)
  const [open, setOpen] = useState<boolean>(false)
  const history = useHistory();

  const onIdle = () => {
    setState('Idle')
    setOpen(false)
  }

  const onActive = () => {
    setState('Active')
    setOpen(false)
  }

  const onPrompt = () => {
    setState('Prompted')
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })
 
  useEffect(() => {
     if(state === 'Idle') {
        localStorage.setItem('sessionOut', 'true');
        history.push(`/${domainPath}/logout/`);
     }
  }, [state])

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
  

  return (
    <Fragment>
        <Modal
                        isOpen={open}
                        ariaHideApp={false}
                        onRequestClose={()=>setOpen(false)}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                       <div className='session-out-message'><h3>Your session has expired. You have been logged out.</h3></div>
                    </Modal>
      
    </Fragment>
  )
}
