import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
// import { createSelector } from 'reselect';

import createReducer from './createReducer';
import UserState from './definitions/UserState';
import { AppState } from '../redux-modules/root';
import * as Types from '../api/definitions';
import { login, preLogin, Logout, fetchBillingStatus } from '../api/api';

export const emptyUser: Types.User = {
    email: '',
    accessToken: '',
    role_type: '',
    user_id: '',
    org_type: '',
    referral_info: '',
    name: '',

    is_pwd_updated: '',
    is_pwd_expired: '',
    pwd_expires_in: '',
    logo_path: '',
    header_color: '',
    is_fully_configured: '',
    performance_entry: '',
    is_prediction_available: '',
    is_kid_report_configured: '',

    count_down: 0,
    is_dashboard_published: '',
    billing_status: true

};

const initialState: UserState = {
    user: emptyUser
};

const { reducer, update } = createReducer<UserState>('User/UPDATE', initialState);
export const userReducer = reducer;

export const actions = {
    update,

    login(credential: Types.Credential): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const email = credential.email;
            const password = credential.password;
            const domain = credential.domain;
            let user: Types.User;
            return await login(email, password, domain).then(async(response) => {
                if (response && response.status === 'success') {
                    const {
                        token,
                        role_type,
                        name,
                        user_id,
                        org_type,
                        is_pwd_updated,
                        is_pwd_expired,
                        pwd_expires_in,
                        logo_path,
                        is_fully_configured,
                        header_color,
                        is_prediction_available,
                        is_kid_report_configured,
                        is_suspended,
                        is_dashboard_published,
                        referral_info
                    } = response.response;
                    let billingResponse =  await fetchBillingStatus(token);
                    let  billing_status = true;
                    if( ["Yet to be configured", "Billing not started"].includes(billingResponse?.message)) {
                        billing_status = false;
                    }
                    const entries: any = performance.getEntriesByType('navigation');
                    const action = entries.map((nav) => nav.type);
                    user = {
                        email,
                        accessToken: token,
                        role_type: role_type,
                        user_id: user_id,

                        name: name,
                        referral_info: referral_info,
                        org_type: org_type,
                        is_pwd_updated: is_pwd_updated,
                        is_pwd_expired: is_pwd_expired,
                        pwd_expires_in: pwd_expires_in,
                        logo_path: logo_path,
                        header_color: header_color,
                        is_fully_configured: is_fully_configured,
                        performance_entry: action[0],
                        is_prediction_available:
                            is_prediction_available === true
                                ? is_suspended === true
                                    ? false
                                    : is_prediction_available
                                : is_prediction_available,
                        is_kid_report_configured: is_kid_report_configured,
                        count_down: 0,
                        is_dashboard_published,
                        billing_status: billing_status

                    };
                    dispatch(update({ user }));
                }
                if (response && response.status === 'failed') {
                    const { count_down } = response.response;

                    let user1 = {
                        ...emptyUser,
                        email,
                        count_down: count_down
                    };
                    dispatch(update({ user: user1 }));
                }

                return response;
            });
        };
    },

    preLogin(email: string): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const userEmail: string = email;
            let user1: Types.User = {
                ...emptyUser,
                email: userEmail,
                count_down: 0
            };
            dispatch(update({ user: user1 }));
            return await preLogin(email).then((response) => {
                if (response && response.status === 'success') {
                    const { count_down } = response.response;
                    user1 = {
                        ...user1,
                        email: userEmail,
                        count_down: count_down
                    };
                    dispatch(update({ user: user1 }));
                }
                if (response && response.status === 'failed') {
                    const count_down = response.response ? response.response.count_down : 0;
                    user1 = {
                        ...user1,
                        email: userEmail,
                        count_down: count_down
                    };
                    dispatch(update({ user: user1 }));
                }
                return response;
            });
        };
    },

    logout(accessToken: any): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            return await Logout(accessToken).then((response) => {
                dispatch(update({ user: emptyUser }));
            });
        };
    }
    //   logout(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
    //    return async (dispatch,getState) => {
    //     return  await Logout()
    //     dispatch(update({ user: emptyUser }));

    //      };
    //   }
};

export const selectors = {
    //
};
