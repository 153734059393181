import ProgressMessage from '../containers/ProgressMessagesList.json';

export class ProgressLoaderService {
    static setProgressMessage = async (isPredictionAvailable, orgType, updateLocalState, formData, configType) => {
        let message = {};
                
        ProgressMessage.map((item) => {
            
            if(orgType === 1 && isPredictionAvailable && formData.hasOwnProperty('Exclusionary Criteria Exists/Referral Rejected')) {
                if((formData['Exclusionary Criteria Exists/Referral Rejected'] === '1' || formData['Exclusionary Criteria Exists/Referral Rejected'][0] === '1')){
                    isPredictionAvailable = true;
                } else {
                    isPredictionAvailable = false;
                }
            }
            
            if(item.orgType === orgType && item.isPredictionsAvailable === isPredictionAvailable && item.configType == configType) {
                message = item;
            } 
            
        })
        if(Object.keys(message).length === 0) {
            message = {
                "orgType": orgType,
                "configType": configType,
                "isPredictionsAvailable": false,
                "timeLag": 4000,
                "messages": ["FirstMatch is submitting data..."]
            };
        } 
        updateLocalState({
            progressMessage: message,
            isLoading: true
        });
    };
}