/* tslint:disable */
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Formik, FormikErrors } from 'formik';
import Button from '@material-ui/core/Button';
import SnackNotification from './SnackNotification';
import { Step2ValidationSchema } from './ValidationSchema';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { wrap, subHeading, fieldRow, mainContent, backdrop } from './styles';
import * as Types from '../api/definitions';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BasicRequirementAddFamily from './BasicRequirementAddFamily';
import { dynamicSearchFamily } from '../api/api';

interface BasicRequirementStep1Props {
    client: Types.Client;
    onFormSubmit: (payLoad: any) => void;
    isLoading: boolean;
    hasError: boolean;
    error: string;
    errors: FormikErrors<Types.Client> | undefined;
    onSearchFamily: (data: any, keySearch: any) => void;
    is_accessToken: string;
    uniqueIdField: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: '8px'
    }
}));

const BasicRequirementStep1: React.FC<BasicRequirementStep1Props> = (props) => {
    const classes = useStyles();
    let [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    let [showAddFamiliy, setShowAddFamiliy] = useState<boolean>(false);
    let [showMoreFamilies, setShowMoreFamilies] = useState<boolean>(false);
    let [SuggestedFamilies, setSuggestedFamilies] = useState<any>(props.client.SuggestedFamilies || []);

    useEffect(() => {
        let newSuggestedFamilies = SuggestedFamilies.length > 0 ? SuggestedFamilies.map((item: any) => {
            return {
                ...item,
                addMoreFamily: {searchFamily: item.value, isManual: false},
                isAdded: true,
                isChecked: false
            };
        }) : [];
        setSuggestedFamilies(newSuggestedFamilies);
      }, [])

    const renderErrorNotification = () => {
        const { errors } = props;
        if (!errors) {
            return null;
        }
        return <SnackNotification errors={errors} />;
    };

    interface FormValues {
        [key: string]: any;
    }
    let initialStateValues: any = {};
    let formValues: FormValues = {};

    SuggestedFamilies &&
        SuggestedFamilies.map(
            (item: any, index) => ((initialStateValues[item[`${props.uniqueIdField}`]] = ''), (formValues[item[`${props.uniqueIdField}`]] = 'string'))
        );

    const handleOnChange = (e, data, index) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            SuggestedFamilies[index].isChecked = true;
        } else {
            SuggestedFamilies[index].isChecked = false;
        }
        setSuggestedFamilies([...SuggestedFamilies]);
    };

    const SearchExistingFamily = async (data: any, keySearch: any, callBack) => {
        try {
            let response = await dynamicSearchFamily(data, props.is_accessToken);
            
            if (response && response.length > 0) {
                if (callBack) {
                    callBack(response);
                }
            } else {
                if (callBack) {
                    callBack('');
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const FamiliesCount = SuggestedFamilies
        ? showMoreFamilies
            ? SuggestedFamilies.filter((item) => item?.isAdded)?.length
            : SuggestedFamilies && SuggestedFamilies.filter((item) => item?.isAdded)?.length < 5
            ? SuggestedFamilies.filter((item) => item?.isAdded)?.length
            : 5
        : 0;

    return (
        <div css={wrap}>
            {renderErrorNotification()}
            <div css={mainContent}>
                <Backdrop css={backdrop} open={props.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Formik
                    initialValues={initialStateValues}
                    initialErrors={props.errors}
                    validationSchema={Step2ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values, helpers) => {
                        if (SuggestedFamilies.some((item) => item.isChecked)) {
                            setShowErrorMessage(false);
                            let finalResult: any = [];
                            SuggestedFamilies.map((item) => item.isChecked && finalResult.push({
                                _id: item._id,
                                id_n: item[`${props.uniqueIdField}`],
                                value: item.value
                            }) )
                            await props.onFormSubmit(finalResult);
                        } else {
                            setShowErrorMessage(true);
                            
                        }

                        //helpers.resetForm();
                    }}
                >
                    {({ values, handleSubmit, handleChange, errors }) => (
                        <form name="newClientForm2" onSubmit={handleSubmit}>
                            <h1 css={subHeading}>Families that Meet Basic Requirements of the Youth {props.client['Identification Number']}</h1>
                            {showErrorMessage && (
                                <React.Fragment>
                                    <span style={{ color: 'red', textAlign: 'center' }}>
                                        <h2>Please select one Family for Youth.</h2>
                                    </span>
                                    <br />
                                </React.Fragment>
                            )}
                            <BasicRequirementAddFamily
                            {...props}
                                showModal={showAddFamiliy}
                                closeModal={setShowAddFamiliy}
                                onSearchFamily={SearchExistingFamily}
                                SuggestedFamilies={SuggestedFamilies}
                                setSuggestedFamilies={setSuggestedFamilies}
                                uniqueIdField={props.uniqueIdField} 
                            />
                            {SuggestedFamilies && SuggestedFamilies.length > 0 ? (
                                SuggestedFamilies.filter((item) => item?.isAdded)?.map(
                                    (item: any, index) =>
                                      (index <= FamiliesCount - 1) && item?.isAdded  && (
                                            <React.Fragment>
                                                <div className={classes.root}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={3}>
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => handleOnChange(e, item, index)}
                                                                name={item.value}
                                                                value={values[item[`${props.uniqueIdField}`]]}
                                                                checked={item.isChecked}
                                                            />
                                                            
                                                            <label style={{ fontSize: '22px', fontWeight: 'bold' }}>
                                                            {item.value?.replace(',', ' ')}: 
                                                                </label>
                                                                {/* <br /> */}
                                                                {" "} 
                                                                <small style={{ display: 'flex', justifyContent: 'center' , color: 'orange' }}>
                                                               ( {item[`${props.uniqueIdField}`]})
                                                                    </small>
                                                            
                                                            {item?.addMoreFamily?.isManual && (
                                                                <React.Fragment>
                                                                    <br />
                                                                    <span>(Manually Entered)</span>
                                                                </React.Fragment>
                                                            )}
                                                        </Grid>
                                                        {!item?.addMoreFamily?.isManual && (
                                                            <React.Fragment>
                                                                <Grid item xs={12} sm={4}>
                                                                    <label style={{ fontWeight: 'bold' }}>
                                                                        Basic Requirements Met:{' '}
                                                                    </label>
                                                                </Grid>
                                                                <Grid item xs={6} sm={5}>
                                                                    {item.matched_questions}
                                                                </Grid>
                                                            </React.Fragment>
                                                        )}
                                                    </Grid>
                                                    {!item?.addMoreFamily?.isManual && (
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} sm={3}>
                                                                &nbsp;
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label style={{ color: 'red', fontWeight: 'bold' }}>
                                                                    {' '}
                                                                    Basic Requirements Not Met:{' '}
                                                                </label>
                                                            </Grid>
                                                            <Grid item xs={6} sm={5}>
                                                                {item.unmatched_questions}
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        )
                                )
                            ) : (
                                <strong>Families Match Not Found.</strong>
                            )}
                            <div css={fieldRow}>
                                <div style={{ justifyContent: 'flex-start', fontWeight: 'bold' }}>&nbsp;</div>
                            </div>
                            { SuggestedFamilies && SuggestedFamilies.filter((item) => item?.isAdded === true)?.length > 0  && FamiliesCount >= 5 &&(
                                <div css={fieldRow}>
                                        <div style={{ justifyContent: 'flex-start', fontWeight: 'bold' }}>
                                            <u
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                onClick={() => setShowMoreFamilies(!showMoreFamilies)}
                                            >
                                               {showMoreFamilies ? "Hide" : "Show"} {" "} Additional Families
                                            </u>{' '}
                                        </div>
                                        <div style={{ justifyContent: 'flex-end', fontWeight: 'bold' }}>
                                            
                                              { `${FamiliesCount} out of ${SuggestedFamilies.filter((item) => item?.isAdded)?.length}` }
                                            
                                        </div>
                                    </div>
                            )}
                            <div css={fieldRow}>
                                <div style={{ justifyContent: 'flex-start', fontWeight: 'bold' }}>
                                    Click{' '}
                                    <u
                                        style={{ color: 'red', cursor: 'pointer' }}
                                        onClick={() => setShowAddFamiliy(!showAddFamiliy)}
                                    >
                                        here
                                    </u>{' '}
                                    to add family considerations to this list.
                                </div>
                                <div style={{ justifyContent: 'flex-end' }}>
                                    <Button type="submit" size="large" variant="contained" color="primary">
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            {/* MAIN CONTENT */}
        </div>
    );
};

export default BasicRequirementStep1;
