import { TraumaScoreService } from '../Services/TraumaScoreService';

export class CheckboxService {
    
    static handleCheckboxChange = async (selectedOptions, data, sectionIndex, questionIndex, prevState, updateLocalState, Persues_House_Score) => {
        let name = data.question;
        let selectedValues = selectedOptions.map((item) => item.value);
        const { DynamicQuestions, client_form } = prevState;
        let updatedDynamicQuestions = DynamicQuestions;
 
        const idx = sectionIndex;
            const idy = questionIndex;
            const checked = data.hasOwnProperty('answer') ? selectedValues?.length > data?.answer?.length : true ;
            updatedDynamicQuestions[idx].questions[idy].answer = selectedValues;
            if(Persues_House_Score?.length > 0) {
                await TraumaScoreService.addTraumaScore(name, [idy, checked], prevState, updateLocalState, Persues_House_Score);
            } else{
                updateLocalState( {
                    DynamicQuestions: updatedDynamicQuestions,
                   // client_form: updatedClientForm,
                    hasError: false
                });
            }
        
        
           
    };
}
