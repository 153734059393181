import { domainPath } from '../App';
import { searchDClient, dynamicSearchDetails } from '../api/api';

export class KeyUpService {
    static handleKeyUp = async (e, state, data, configType, subConfigType) => {
        const { name, value } = e.target;
        const { client_form, isEdit } = state;
        const {
            'First Name': First_Name,
            'Last Name': Last_Name
        } = client_form;
        const isAccessToken = state.user?.user?.accessToken || '';

        if (isEdit !== 'true') {
            if (['perseus-house'].includes(domainPath)) {
                if (name === 'Date of Birth') {
                    let response = await searchDClient('', '', First_Name, Last_Name, value, isAccessToken, '');
                    if (response.status === 'success' && response.response && response.response.length > 0) {
                        alert(
                            `${data.question} already exists. Data will not save if you continue. Please use the existing client search to update any existing client data.`
                        );
                    }
                }
            } else {
                if (data?.is_primary_key) {
                    let clientCode = client_form[`${data.question}`];
                    
                    if(clientCode){
                        let response = [];
                        response = await dynamicSearchDetails({ [data.question]: clientCode }, isAccessToken, configType, subConfigType, true);
                       if (response && response.length > 0) {
                           alert(
                           `${data.question} already exists. Data will not save if you continue. Please use the existing client search to update any existing client data.`
                           )
                       }
                    }
                     
                }
            }
        }
    };

   static getConfSubConf = (locationDetails) => {
    let indexOfUrl = locationDetails.indexOf('config');
        if(indexOfUrl > 0 && indexOfUrl !== -1){
            locationDetails =  locationDetails.slice(indexOfUrl)
        }
        const params = new URLSearchParams(locationDetails);
        const Config: any = params.get('config');
        const subConfig: any = params.get('subConfig');
        const index: any = params.get('index');
        const isEdit: any = params.get('isEdit');
        const Rerefer: any = params.get('Rerefer');
       
        return {Config: Config, subConfig: subConfig, index:index, isEdit:isEdit, Rerefer:Rerefer}
    }
}