/* tslint:disable */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import Button from '@material-ui/core/Button';
import { wrap, inputField, fieldRow } from './styles';
import React, { useState, useCallback, memo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'react-modal';
import { Add, CloseOutlined } from '@material-ui/icons';
import _debounce from 'lodash/debounce';

interface BasicRequirementAddFamilyProps {
    showModal: boolean;
    closeModal: any;
    onSearchFamily: (
        data: any,
        keySearch: any,
        callBack : (result: any) => void,
     ) => void;
    SuggestedFamilies: any;
     setSuggestedFamilies: any;
     uniqueIdField: string;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '50%',
        overflowY: 'scroll'
    }
};
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: '8px'
    },
}));

const BasicRequirementAddFamily: React.FC<BasicRequirementAddFamilyProps> = ({
    showModal,
    closeModal,
    onSearchFamily,
    SuggestedFamilies,
    setSuggestedFamilies,
    uniqueIdField
}) => {
    const classes = useStyles();

    const searchFamily =[
        { _id: '', id_n: '', value: '', addMoreFamily: {searchFamily: '', isManual: true}, isAdded: false, message: '', color: '', isChecked: false }
    ];

    const moreFamily = () => {
        setSuggestedFamilies([...SuggestedFamilies, ...searchFamily]);
        
    };

    const debounceFn =  useCallback(
        _debounce((value, uniqueField,callBack)=>

          value?.trim() !== "" &&       onSearchFamily({[uniqueField]: value}, 
            uniqueField, function(result) {
                                   
            if(result && result.length === 1) {
                callBack(result)
            } else
             {callBack('')} 
        }) , 1000),
        []
      );

    const handleChange = async(event, record, index) => {
        let value = event.target.value;
        SuggestedFamilies[index].addMoreFamily.searchFamily = value;
        SuggestedFamilies[index].value = value;
        SuggestedFamilies[index].isChecked = false;
        SuggestedFamilies[index].isAdded = false;
        SuggestedFamilies[index].message = '';
    setSuggestedFamilies([...SuggestedFamilies])
    
      if(value?.trim() !== '') {
       debounceFn(value , uniqueIdField, function(result){
                
        if(result) {
            
             let _id = result[0]?._id;
                let id_n = result[0][uniqueIdField];
                let family_name = ''; 
                
                result[0].sections[0]?.questions.map((item) => {
                    if (item?.question.includes('First Name') || item?.question.includes('Last Name')) {
                        family_name += `${item.answer},`;
                    }
                });
                
                SuggestedFamilies = SuggestedFamilies.map((item, i) => {
                    if (item.value === value) {
                      return {
                        ...item,
                        _id: _id,
                        [uniqueIdField]: id_n,
                        value: family_name?.substring(0, family_name.length - 1),
                        message: `Family found ${family_name?.substring(0, family_name.length - 1)?.replace(/,/g, " ")}`,
                        color: { color: 'blue', fontWeight: 'bold' }
                      };
                    }
                    return item;
                  });
                  setSuggestedFamilies([...SuggestedFamilies])
                
        } else {
           SuggestedFamilies = SuggestedFamilies.map((item, i) => {
                if (item.value === value) {
                  return {
                    ...item,
                    message: 'Family not found',
                    color: {color: 'red', fontWeight: 'bold'},
                    isChecked: false,
                    isAdded: false,
                  };
                }
                return item;
              });
              setSuggestedFamilies([...SuggestedFamilies]) 
        }
       });
    } else {
        SuggestedFamilies = SuggestedFamilies.filter((item, i) => index !== i);
        SuggestedFamilies.message = '';
        SuggestedFamilies.color = '';
        SuggestedFamilies.isChecked= false;
        SuggestedFamilies.isAdded= false;
          setSuggestedFamilies([...SuggestedFamilies]) 
    }
    };
  
    const addFamily = (record, index) => {
        let list = SuggestedFamilies.filter((item) => (item._id === record._id));
        
        if(list && list.length > 1) {
            SuggestedFamilies[index].message = `${SuggestedFamilies[index].value} alreday exists in the families list`;
        } else {
            SuggestedFamilies[index].isAdded = true;
            SuggestedFamilies[index].isChecked = true;
        }
        let manualFamilies = SuggestedFamilies.filter((item) => item?.addMoreFamily?.isManual);
        let filteredFamilies = SuggestedFamilies.filter((item) => !item?.addMoreFamily?.isManual);
        setSuggestedFamilies([...manualFamilies, ...filteredFamilies]);
    }

    SuggestedFamilies = SuggestedFamilies.some((list) => list?.addMoreFamily?.isManual) ? SuggestedFamilies : [...SuggestedFamilies, ...searchFamily]
    let count = 0;
    
    return (
        <div css={wrap}>
            <Modal
                isOpen={showModal}
                ariaHideApp={false}
                onRequestClose={() => closeModal(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h3>Manual Entry of Basic families list screen:​</h3>
                <div className={classes.root}>
                    {SuggestedFamilies.map((item, index) => 
                    { 
                        if(item?.addMoreFamily?.isManual) {
                            count = count + 1;
                            return (
                                <React.Fragment>
                                <Grid container spacing={2} style={{ alignItems: 'center' }}>
                            {count === SuggestedFamilies.filter((item) => item?.addMoreFamily?.isManual)?.length ?(
                                <Grid item xs={1} sm={1} xl={1}>
                                            <Add
                                                onClick={() => moreFamily()}
                                                style={{ fontSize: '24px', fontWeight: 'bold', cursor: 'pointer' }}
                                            />
                                        </Grid>)
                                        :  (
                                            <Grid item xs={1} sm={1} xl={1}>
                                                {/* <CloseOutlined 
                                                onClick={()=>removeFamily(index)} 
                                                style={{ fontSize: '24px', fontWeight: 'bold', cursor: 'pointer' }} /> */}
                                                &nbsp;
                                            </Grid>
                                        )
                        }
                                    <Grid item xs={8} sm={8} xl={8}>
                                        <input
                                            type="text"
                                            name="family"
                                            css={inputField}
                                            placeholder="Insert Family Code here"
                                            value={item?.addMoreFamily?.searchFamily || ''}
                                            onChange={(e) => handleChange(e, item, index)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        <Button
                                            type="submit"
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => addFamily(item, index)}
                                            disabled={item.message === "Family not found" 
                                            //  || SuggestedFamilies.find((item) => item.isAdded)
                                        }
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                </Grid>
                                {item.message && <span style={item.color}> {item.message} </span>}
                                </React.Fragment>
                            )
                        }
                        })}
                </div>
                <div css={fieldRow}>
                    <div style={{ justifyContent: 'flex-start' }}>&nbsp;</div>
                    <div style={{ justifyContent: 'right' }} onClick={() => closeModal(false)}> 
                        <Button type="submit" size="large" variant="contained" color="primary">
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default memo(BasicRequirementAddFamily);
