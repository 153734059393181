/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import { domainPath } from '../../App';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    wrap,
    fieldRow,
    mainContent,
    twoCol,
    backdrop,
    txtDetail,
    panelHeader,
    panel,
    panelHeading,
    txtLabel
} from '../styles';
import { FamilyDetailsProps, FamilyDetailsState } from './IFamilyDetails.Interface';
import { Link } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, CircularProgress } from '@material-ui/core';
import DisplayDynamicQuestions from './DisplayDynamicQuestions';
export class FamilyDetails extends React.Component<FamilyDetailsProps, FamilyDetailsState> {
    constructor(props: FamilyDetailsProps) {
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState() {
        return {
            isLoading: false,
            ClientCode: '',
            hasError: true,
            isSubmitted: false,
            DynamicQuestions: [],
            error: [],
            errors: '',
            DateTypeQuestions: [],
            isValid_date: [],
            minStartDateValue: '',
            err_msg: [],
            header_color: '',
            isSuccess: false,
            selectedVersion: [],
            currentVersion: ''
        };
    }
    async componentDidMount() {
        //# Every time latest data from Redux Store (Either last Version or Notifications current version)
        let DynamicQuestions = [...this.props.DynamicQuestions];
        let DynamicQues: any = [];

        //Find and store : all date type question
        let date: any[] = [];
        DynamicQuestions &&
            DynamicQuestions.map((section) => {
                section.questions &&
                    section.questions.map((ques) => {
                        if (ques.answer_type === 'DATE') {
                            date.push(ques.question.toString());
                        }
                    });
            });

        //Find and calculate :  The minimum Start Date value (preProgramYLSDate or dateOfReferral):  among both which ever is greater
        let preProgramYLSDate = '',
            dateOfReferral = '';
        this.props.family.sections.map(
            (section) =>
                section.questions &&
                section.questions.length > 0 &&
                section.questions.filter((question) => {
                    if (question.question === 'Pre-Program: YLS Date') {
                        preProgramYLSDate = question.answer;
                    }
                    if (question.question === 'Date of Referral') {
                        dateOfReferral = question.answer;
                    }
                })
        );
        let minStartDateValue: any = preProgramYLSDate > dateOfReferral ? preProgramYLSDate : dateOfReferral;

        // 1. Getting current question jumpTo value
        const currQuesJump =
            DynamicQuestions[0] &&
            DynamicQuestions[0].questions &&
            DynamicQuestions[0].questions
                .map(
                    (ques) =>
                        ques.question === 'Referral Status' &&
                        ques.suggested_jump.find((ans, i) => ans.answer === ques.answer || i.toString() === ques.answer)
                            ?.question_jumpto
                )
                .find((item) => item !== false);

        //2. Checking length of question jumpTo
        if (currQuesJump?.length > 0) {
            let DynamicSectionQues = [...DynamicQuestions];
            DynamicQues =
                DynamicSectionQues[0] &&
                DynamicSectionQues[0].questions &&
                DynamicSectionQues.map((sec) => {
                    sec.questions.map((ques) => {
                        if (currQuesJump?.includes(ques.question)) {
                            ques.related = 'no';
                        }
                        if (
                            (ques.answer && ques.answer != '') ||
                            (Array.isArray(ques.answer) && ques.answer.length > 0)
                        ) {
                            ques.related = 'no';
                        }
                        if (ques.answer_type == 'DATE') {
                            ques.answer = ques.answer.replaceAll('-', '/');
                        }
                        return ques;
                    });
                    return sec;
                });
        }
        //# Every time latest data based on versionIndex (Either last Version or Notifications current version)
        let onSearchData = [...this.props.searchData];
        let searchData = onSearchData[0];
        //. Finding Data for client form
        let client_data: any = {};
        searchData?.sections
            .find((section) => section.section === 'Outcomes')
            ?.questions.map((ques: any) => (client_data[ques.question] = ques.answer));
        await this.setState({
            DynamicQuestions: currQuesJump?.length > 0 ? DynamicQues : [...this.props.DynamicQuestions],
            DateTypeQuestions: date,
            minStartDateValue: minStartDateValue,
            err_msg: this.props.errors,
            header_color: this.props.user && this.props.user.user.header_color,
            selectedVersion: searchData && [searchData],
            currentVersion: searchData && searchData?.version
        });
    }

         //To group nested question which are related   
     groupingNestingQuestions = (id) => {
        let questionsList = this.state.selectedVersion && [...this.state.selectedVersion[0].sections[id].questions];
        let filteredQuestions: any = [];
        let relatedJumptoQuestions: any = [];
        let unrelatedJumpToQuestions: any = [];
        let relatedJumptoQuestionsForArrayType: any = [];
        let unrelatedJumpToQuestionsForArrayType: any = [];
        for (let question = 0; question < questionsList.length; question++) {
            if (questionsList[question]?.answer_type === 'SELECT' || questionsList[question]?.answer_type === 'RADIO') {
                if (questionsList[question]?.suggested_jump?.length > 0) {
                    //To check whether the answer type is Array
                    if (Array.isArray(questionsList[question]?.answer)) {
                        const relatedSuggestedJump = questionsList[question]?.suggested_jump?.filter((item) =>
                            item.answer?.includes(questionsList[question].answer)
                        );
                        const unrelatedSuggestedJump = questionsList[question]?.suggested_jump?.filter(
                            (item) => !item.answer?.includes(questionsList[question].answer)
                        );
                        if (relatedSuggestedJump.length > 0) {
                            relatedSuggestedJump.forEach((obj) => {
                                obj.question_jumpto.forEach((ques) => {
                                    relatedJumptoQuestionsForArrayType.push(ques);
                                });
                            });
                        }
                        if (unrelatedSuggestedJump.length > 0) {
                            unrelatedSuggestedJump.forEach((obj) => {
                                obj.question_jumpto.forEach((ques) => {
                                    unrelatedJumpToQuestionsForArrayType.push(ques);
                                });
                            });
                        }
                    } else {
                        const relatedSuggestedJump = questionsList[question]?.suggested_jump?.filter(
                            (item) => item.answer === questionsList[question].answer
                        );
                        const unrelatedSuggestedJump = questionsList[question]?.suggested_jump?.filter(
                            (item) => item.answer !== questionsList[question].answer
                        );
                        if (relatedSuggestedJump.length > 0) {
                            relatedSuggestedJump.forEach((obj) => {
                                obj.question_jumpto.forEach((ques) => {
                                    relatedJumptoQuestions.push(ques);
                                });
                            });
                        }
                        if (unrelatedSuggestedJump.length > 0) {
                            unrelatedSuggestedJump.forEach((obj) => {
                                obj.question_jumpto.forEach((ques) => {
                                    unrelatedJumpToQuestions.push(ques);
                                });
                            });
                        }
                    }
                }
            }
        }
        const allRelated = [...relatedJumptoQuestions, ...relatedJumptoQuestionsForArrayType];
        const allunRelated = [...unrelatedJumpToQuestions, ...unrelatedJumpToQuestionsForArrayType];
        const mainUnRelatedQuestions = allunRelated.filter((element) => !allRelated.includes(element));
        for (let que = 0; que < questionsList.length; que++) {
            const unrelated = mainUnRelatedQuestions?.includes(questionsList[que]?.question);

            if (unrelated === false) {
                filteredQuestions.push(questionsList[que]);
            }
        }

        return filteredQuestions;
    };

    display = (id, ver) => {
        const tempArray = [] as any;
        let questionsList = this.groupingNestingQuestions(id);
        
        const length: any = questionsList && questionsList?.length;
        for (let i = 0; i < length; i++) {
            if ((i + 1) % 2 !== 0) {
                var tempArray1 = [] as any;
                tempArray1.push(questionsList[i]);
                if (length === i + 1) {
                    tempArray.push(tempArray1);
                }
            } else {
                tempArray1.push(questionsList[i]);
                tempArray.push(tempArray1);
            }
        }
        return tempArray;
    };

    is_date = function (date) {
        let dateObj: any = new Date(date.replace(/-/g, '/'));
        const regex = new RegExp(
            '([0-9]{4}[-](0[1-9]|1[0-2])[/]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})'
        );
        if (regex.test(date?.trim())) {
            let year = dateObj.getFullYear();
            let month = 1 + dateObj.getMonth();
            let datee = dateObj.getDate();
            let date1 = [month.toString().length > 1 ? month : `0${month}`, datee > 9 ? datee : `0${datee}`, year].join(
                '/'
            );
            
            return date1;
        }
        
        return date;
    };

    render() {
        const { selectedVersion } = this.state;
        const { searchData, configType, subConfigType } = this.props; 
        const selectedIndex = selectedVersion && selectedVersion.length - 1;
        
        return (
            <React.Fragment>
                <div css={wrap}>
                    <div css={mainContent}>
                        <Backdrop css={backdrop} open={this.props.isLoading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>

                        {selectedVersion &&
                            selectedVersion[selectedIndex]?.sections?.map(
                                (ques, id) =>
                                    ques.section !== 'Outcomes' && (
                                        <Accordion defaultExpanded={id === 0 ? true : false} key={id}>
                                            <AccordionSummary
                                                css={panelHeader}
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                            >
                                                <h1 css={panelHeading}>{ques.section}</h1>
                                            </AccordionSummary>
                                            <AccordionDetails css={panel}>
                                                {this.display(id, selectedVersion).map((item, index) => {
                                                    return (
                                                        <React.Fragment>
                                                            <div css={fieldRow} key={index}>
                                                                {item.map((ques, index_1) =>
                                                                    ques.is_child !== 'yes' &&
                                                                    ques.is_parent !== 'yes' ? (
                                                                        <div css={twoCol} key={index_1}>
                                                                            <label css={txtLabel}>
                                                                                {ques.question}
                                                                            </label>
                                                                            <div css={txtDetail}>
                                                                                {typeof ques.answer === 'object' &&
                                                                                !Array.isArray(ques.answer)
                                                                                    ? ques.answer.value
                                                                                    : Array.isArray(ques.answer)
                                                                                    ? ques.answer?.toString()
                                                                                    : ques.answer
                                                                                          ?.toString()
                                                                                          .includes('-')
                                                                                    ? this.is_date(ques.answer)
                                                                                    : ques.question === 'Age'
                                                                                    ? this.props.DynamicQuestions[0]
                                                                                          ?.ageAtEpisodeStart
                                                                                        ? this.props.DynamicQuestions[0]
                                                                                              .ageAtEpisodeStart
                                                                                        : ques.answer
                                                                                    : ques.answer}
                                                                                {ques.is_parent === 'yes'}
                                                                            </div>
                                                                        </div>
                                                                    ) 
                                                                    : (
                                                                        ques.is_child !== 'yes' &&
                                                                        <DisplayDynamicQuestions data={ques} index={index_1} is_date={this.is_date} sections={item}/>
                                                                    
                                                                    )
                                                                )}
                                                            </div>
                                                            {/* <DisplayDynamicQuestions data={this.display(id, selectedVersion)} /> */}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                            )}
                    </div>

                    {/* MAIN CONTENT */}
                </div>
                <React.Fragment>
                    <h3>
                        Click{' '}
                        <Link
                            to={{
                                pathname: `/${domainPath}/existing-family/edit-details/config=${configType}&subConfig=${subConfigType}&index=${this.props.index}&isEdit=true&Rerefer=false`,
                                state: {
                                    isPredictionScores: false
                                }
                            }}
                        >
                            <u style={{ color: 'red' }}>here</u>
                        </Link>{' '}
                        to update Family Information.
                    </h3>
                </React.Fragment>
            </React.Fragment>
        );
    }
}
export default FamilyDetails;