import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export const ErrorMessageComponent = ({ state, isSuccess, handleClose, subHeading, headerColor }) => {
    return (
        <Modal
            isOpen={state.isOpen}
            ariaHideApp={false}
            onRequestClose={handleClose}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div>
                {!isSuccess && (
                    <h1 css={subHeading} style={{ color: headerColor }}>
                        {state.staticHeader}
                    </h1>
                )}
                {state.err_msg && Array.isArray(state.err_msg) ? (
                    <div
                        style={{
                            overflowY: 'scroll',
                            height: state.err_msg.length > 25 ? '80vh' : 'auto'
                        }}
                    >
                        {state.err_msg.map((e, i) => (
                            <div style={{ color: 'red' }} key={i}>
                                {state.err_msg[i]}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div style={{ color: isSuccess ? headerColor : 'red' }}>{state.err_msg}</div>
                )}
            </div>
        </Modal>
    );
};
