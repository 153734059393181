/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { selectField, fieldRow, txtLabel } from '../styles';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import CustomPieChartLabel from './Utils/CustomPieChartLabel';
interface Total_AnalysisProps {
    AnalyticsList: any;
    Location: any;
    Referral: any;
    filter: any;
    onSelectChange: (src: any) => void;
}
const COLORS = [
    { start: '#00ffa7', end: '#06cc71' },
    { start: '#00b4f1', end: '#00e1ff' },
    { start: '#fa2102', end: '#ff5036' },
    { start: '#FFC443', end: '#FFE970' },
    { start: '#5f43ff', end: '#5f43ffb9' }
];

export const Total_Analytics: React.FC<Total_AnalysisProps> = (props) => {
    let i = useRef(1);
    console.log(`Total analytics rendered  ${i.current++} time(s)`);
    const analyticsData = [
        { name: 'Accepted & Placed', value: props.AnalyticsList.placed ? props.AnalyticsList.placed.count : 0 },
        {
            name: 'Accepted, not placed',
            value: props.AnalyticsList.not_placed ? props.AnalyticsList.not_placed.count : 0
        },
        { name: 'Rejected', value: props.AnalyticsList.rejected ? props.AnalyticsList.rejected.count : 0 },
        { name: 'Pending', value: props.AnalyticsList.pending ? props.AnalyticsList.pending.count : 0 },
        { name: 'Rescinded', value: props.AnalyticsList.rescinded ? props.AnalyticsList.rescinded.count : 0 }
    ];

    const [filters, setfilters] = useState({ referral_status: '', referral_source: '', location: '' });
    useEffect(() => {
        props.onSelectChange(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.referral_status, filters.referral_source, filters.location]);

    useEffect(() => {
        setfilters((prevState) => {
            return { ...prevState, referral_status: '', referral_source: '', location: '' };
        });
    }, [props.filter.days_count]);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                    <label
                        css={txtLabel}
                        //style={{ marginTop: 16 }}
                    >
                        Referral status:
                    </label>

                    <select
                        css={selectField}
                        name="referral_status"
                        value={filters.referral_status || ''}
                        onChange={(e) => {
                            const val = e.target.value;
                            setfilters((prevState) => {
                                return { ...prevState, referral_status: val, referral_source: '', location: '' };
                            });
                        }}
                    >
                        <option value="">Select</option>
                        <option value="placed">Accepted &amp; placed</option>
                        <option value="not_placed">Accepted not placed</option>
                        <option value="rejected">Rejected</option>
                        <option value="pending">Pending</option>
                        <option value="rescinded">Rescinded</option>
                    </select>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <label
                        css={txtLabel}
                        //style={{ marginTop: 16 }}
                    >
                        Referral sources:
                    </label>

                    <select
                        css={selectField}
                        name="referral_source"
                        value={filters.referral_source || ''}
                        onChange={(e) => {
                            const val = e.target.value;
                            setfilters((prevState) => {
                                return { ...prevState, referral_source: val };
                            });
                        }}
                    >
                        <option value="">Select</option>
                        {props.Referral.map((r) => (
                            <option key={r.id} value={r.id}>
                                {r.value}
                            </option>
                        ))}
                    </select>
                </Grid>
                <Grid item xs={6} sm={3}>
                    {filters.referral_status === 'placed' && (
                        <div>
                            <label
                                css={txtLabel}
                                //style={{ marginTop: 16 }}
                            >
                                Locations:
                            </label>

                            <select
                                css={selectField}
                                name="location"
                                value={filters.location || ''}
                                onChange={(e) => {
                                    const val = e.target.value;
                                    setfilters((prevState) => {
                                        return { ...prevState, location: val };
                                    });
                                }}
                            >
                                <option value="">Select</option>
                                {props.Location.map((l) => (
                                    <option key={l.location} value={l.location_names}>
                                        {l.location_names}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </Grid>
            </Grid>
            <div css={fieldRow} style={{ marginTop: '10px' }}>
                <ResponsiveContainer width="90%" height={400}>
                    <PieChart>
                        <Legend layout="vertical" iconType="circle" verticalAlign="middle" align="right" />
                        <defs>
                            {analyticsData.map((entry, index) => (
                                <linearGradient key={`myGradient${index}`} id={`myGradient${index}`}>
                                    <stop offset="0%" stopColor={COLORS[index % COLORS.length].start} />
                                    <stop offset="100%" stopColor={COLORS[index % COLORS.length].end} />
                                </linearGradient>
                            ))}
                        </defs>
                        <Pie
                            isAnimationActive={false}
                            data={analyticsData}
                            labelLine={false}
                            label={
                                <CustomPieChartLabel
                                    centerText={{
                                        title: ' Total Referrals',
                                        value: props.AnalyticsList.total && props.AnalyticsList.total.count
                                    }}
                                    labelRadiusPercent={4}
                                />
                            }
                            dataKey="value"
                            nameKey="name"
                            outerRadius={140}
                            innerRadius={80}
                            fill="#8884d8"
                        >
                            {analyticsData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={`url(#myGradient${index})`} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default Total_Analytics;
