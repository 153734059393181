import { makeStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';

export interface FamilySearchProps extends RouteComponentProps {
    familyList: any;
    searchFieldsList: any;
    onFormSubmit: (
        client_code: string,
        ssn: string,
        first_name: string,
        last_name: string,
        dob: string,
        id_n: string
    ) => void;
    onSearchSubmit: (
       data: any,
       keySearch: any
    ) => void;
    clearData: () => void;
    isLoading: boolean;
    hasError: boolean;
    error: string;
    headerColor: string;
    user: any;
    uniqueField: string;
    configuredQuestionsList: any;
    config: string;
    subConfig: string;
}

export interface FormValues {
    first_name: string;
    last_name: string;
    client_code: string;
    ssn: string;
    id_n?: string;
    dob: string;
}

export const initialStateValues: FormValues = {
    first_name: '',
    last_name: '',
    client_code: '',
    ssn: '',
    id_n: '',
    dob: ''
};

export const style = makeStyles({
    titleItemRight: {
        color: 'white',
        height: 30,
        float: 'right',
        position: 'relative',
        transform: 'translateY(-10%)'
    }
});