/** @jsx jsx */
import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { jsx, css } from '@emotion/core';
import { Formik } from 'formik';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { domainPath } from '../App';
import { baseApiUrl } from '../api/api';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { fieldRow, backdrop } from './styles';

const App = css`
    margin: 80px auto;
    width: 100%;
    background-color: #fff;
    padding: 16px;
    position: relative;
    @media all and (min-width: 520px) {
        padding: 40px;
        margin: 100px auto;
        width: 60vw;
    }
    @media all and (min-width: 520px) and (max-width: 1024px) {
        width: 90vw;
    }
`;

const useStyles = makeStyles((theme) => ({
    link: {
        margin: theme.spacing(1, 1.5)
    },

    root: {
        // height: "80vh",
        width: '100%'
    },
    image: {
        background: 'linear-gradient(to right, #0078ff, #00bfff)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    paper: {
        width: 'auto',
        margin: theme.spacing(8, 4)
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    appBar: {
        backgroundColor: '#fff',
        width: '200px',
        paddingTop: 10,
        paddingLeft: 8
    },
    submit: {
        width: '45%',
        height: 'auto',
        // marginLeft: "100px",
        margin: theme.spacing(2, 2, 2)
    },
    logo: {
        width: '150px',
        height: 'auto',
        marginRight: '10px'
    },
    toolbar: {
        flexWrap: 'wrap'
    },
    toolbarTitle: {
        flexGrow: 1
    },
    brand: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        textDecoration: 'none'
    },
    brandTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 25,
        color: '#5cb85c'
    },
    errormsg: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'capitalize',
        fontSize: 15,
        color: 'red'
    },
    expTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'capitalize',
        color: 'red'
    },
    expSubTitle: {
        fontSize: 16,
        color: '#6C757D',
        margin: '25px 0px',
        padding: '10px 0px'
    },
    userName: {
        color: '#28A745'
    }
}));

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -110%)'
    }
};

interface CreatePasswordValues {
    password: string;
    confirm_password: string;
}
interface User {
    [key: string]: any;
}
const CreatePassword: React.FC<any> = (props) => {
    const classes = useStyles();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [expire, setExpire] = useState<string>('');
    const [user, setUser] = useState<User>({});
    const history = useHistory();
    let { token }: any = useParams();

    const pwdTokenValidation = async () => {
        try {
            let response = await axios
                .post(
                    `${baseApiUrl}/${domainPath}/validate/pwd-token`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('forgotPassword')}`
                        }
                    }
                )
                .then((response) => {
                    return response.data;
                });

            if (response.status === 'success') {
                history.push(`./${response.mode === 'set' ? 'create-password' : 'forgot-password'}`);
                setExpire('');
                setUser(response);
            } else {
                history.push('./expire-password');
                setExpire(response.message);
            }
        } catch (error) {
            const error1 = error.data
                ? error.data.message
                    ? error.data.message
                    : 'Something went wrong. Please try again later. '
                : 'Something went wrong. Please try again later. ';
            history.push('./expire-password');
            setExpire(error1);
        }
    };

    useEffect(() => {
        if (token.toString().length > 15) {
            localStorage.setItem('forgotPassword', token);
        }
        history.push(`./${localStorage.getItem('forgotPassword')}-password`);
        pwdTokenValidation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    const initialValues: CreatePasswordValues = {
        password: '',
        confirm_password: ''
    };

    let orgName = domainPath
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');

    // if (orgName === 'Perseus House') {
    //     orgName = 'Perseus House, Inc.';
    // }

    const ValidationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Please Enter your New Password')
            .min(8, 'Password must be longer than 8 characters.')
            .max(12, 'Password must be shorter than 12 characters.')
            .matches(/^(?=.*[A-Z])/, 'Must contain at least one capital letter')
            .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
            .matches(/^(?=.*[!@#$%^&*+=])/, 'Must contain at least one special character (+@#$%^&+=!*)')
            .matches(
                /^(?=.*[a-zA-Z0-9!@#$%^&*+=])/,
                'Must contain 8-12 characters, at least one capital letter, one number, and one special character (+@#$%^&+=!*)'
            ),

        confirm_password: Yup.string()
            .required('Please Enter your Re-Type New Password')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });
    const { mode, name, org_name } = user;

    return (
        <div>
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.toolbarTitle}>
                        {/* <RouterLink to="https://firstmatchcom.wpcomstaging.com/" className={classes.brand}> */}
                        <a className={classes.brand} href="https://firstmatch.com/" title="firstmatch logo">
                            <img src="/img/logo_stroke.png" alt="" className={classes.logo} />
                        </a>
                    </div>
                </Toolbar>
            </AppBar>
            {expire ? (
                <Fragment>
                    <Container component="main" maxWidth="md" css={App}>
                        <div className={classes.paper}>
                            <Typography component="div" color="error" variant="h5" className={classes.expTitle}>
                                {expire}
                            </Typography>
                            <Typography
                                component="div"
                                color="primary"
                                align="center"
                                className={classes.expSubTitle}
                                paragraph={true}
                            >
                                Please revisit the login screen and select Forgot Password to generate a new link
                            </Typography>

                            <div css={fieldRow} style={{ justifyContent: 'center' }}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => history.push(`/${domainPath}/login`)}
                                >
                                    Go to login
                                </Button>
                            </div>
                        </div>
                    </Container>
                </Fragment>
            ) : (
                <Fragment>
                    <Backdrop css={backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Container component="main" maxWidth="sm" css={App}>
                        <div className={classes.paper}>
                            <Typography component="div" variant="h5">
                                {mode && mode === 'set' ? 'Create New' : 'Forget'} Password{' '}
                                <i className={classes.userName}>{name}</i> !{' '}
                                <small>{orgName === 'Perseus House' ? 'Perseus House, Inc.' : org_name}</small>
                            </Typography>
                            {error && (
                                <Typography component="div" variant="body1" color="error" className={classes.errormsg}>
                                    {error}
                                </Typography>
                            )}
                            <Formik
                                initialValues={initialValues}
                                validationSchema={ValidationSchema}
                                onSubmit={async (values, { resetForm }) => {
                                    try {
                                        setLoading(true);
                                        const response: any = await axios.post(
                                            `${baseApiUrl}/${domainPath}/create-password`,
                                            {
                                                password: values.password,
                                                retype_password: values.confirm_password
                                            },
                                            {
                                                headers: {
                                                    Authorization: `Bearer  ${localStorage.getItem('forgotPassword')}`
                                                }
                                            }
                                        );
                                        if (response.data.status === 'success') {
                                            setError('Your password has been changed successfully. Go for Login!');
                                            setMessage('Your password has been changed successfully.');
                                            openModal();
                                        } else {
                                            setError(response.data.message);
                                        }
                                        setLoading(false);
                                        return response.data;
                                    } catch (e) {
                                        setLoading(false);
                                        const error = e.data
                                            ? e.data.message
                                                ? e.data.message
                                                : 'Something went wrong. Please try again later. '
                                            : 'Something went wrong. Please try again later. ';
                                        setError(error);
                                        setMessage('');
                                        return;
                                    }
                                }}
                            >
                                {({
                                    values,
                                    setFieldValue,
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    errors,
                                    touched
                                }) => (
                                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="New Password"
                                            type="password"
                                            id="password"
                                            value={values.password || ''}
                                            onChange={handleChange}
                                            autoComplete="current-password"
                                        />
                                        {touched.password && errors.password ? (
                                            <div style={{ color: 'red' }}>{errors.password}</div>
                                        ) : null}
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="confirm_password"
                                            label="Re-Type New Password"
                                            type="password"
                                            id="confirm_password"
                                            value={values.confirm_password || ''}
                                            onChange={handleChange}
                                            autoComplete="current-password"
                                        />
                                        {touched.confirm_password && errors.confirm_password ? setError('') : null}
                                        {touched.confirm_password && errors.confirm_password ? (
                                            <div style={{ color: 'red' }}>{errors.confirm_password}</div>
                                        ) : null}

                                        <div css={fieldRow} style={{ justifyContent: 'center' }}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                            >
                                                {mode && mode === 'set' ? 'Submit' : 'Update'}
                                            </Button>
                                        </div>

                                        <Modal
                                            isOpen={modalIsOpen}
                                            ariaHideApp={false}
                                            onRequestClose={closeModal}
                                            style={customStyles}
                                            contentLabel="Example Modal"
                                        >
                                            <form>
                                                {message && message ? (
                                                    <React.Fragment>
                                                        <Typography
                                                            component="div"
                                                            variant="h6"
                                                            className={classes.brandTitle}
                                                        >
                                                            {message} Please&nbsp;{' '}
                                                            <Link onClick={() => history.push(`/${domainPath}/login`)}>
                                                                <span style={{ color: 'red' }}>Click Here</span>
                                                            </Link>{' '}
                                                            &nbsp;to login
                                                        </Typography>
                                                        <div css={fieldRow} style={{ justifyContent: 'center' }}>
                                                            <Button
                                                                type="submit"
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.submit}
                                                                onClick={() => {
                                                                    setMessage('');
                                                                    closeModal();
                                                                    history.push(`/${domainPath}/login`);
                                                                }}
                                                            >
                                                                Close
                                                            </Button>
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                )}
                                            </form>
                                        </Modal>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </Container>
                </Fragment>
            )}
        </div>
    );
};

export default CreatePassword;
