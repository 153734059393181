/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { dataTable, tableHeader, tableRow } from './styles';
import * as ValidateRegex from '../Common/CommonRegex';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        
        '& .MuiTableCell-head': {
          backgroundColor: '#e3e4fb',
        },
    },
    tableContainer: {
      maxHeight: 440,
      fontFamily: 'Quicksand, Helvetica, sans-serif !important',
    },
    table: {
      minWidth: 650,
    },
    tableCell: {
      fontFamily: 'Quicksand, Helvetica, sans-serif !important',
    },
    tableRow: {
        cursor: 'pointer',
      },
    tdCell: {
         fontFamily: 'Quicksand, Helvetica, sans-serif !important',
      },
  }));

const DynamicTableListView: React.FC<any> = ({ searchFieldsList, listDetails, loading, is_date, type, uniqueField, config, subConfig }) => {
    const history = useHistory();
    const classes = useStyles();
    const list = Array.isArray(searchFieldsList) ? searchFieldsList.map((item) => item.question) : [];
   
    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="clients table" className={classes.table}>
            <TableHead className={classes.root}> 
                <TableRow>
                    {searchFieldsList.length > 0 &&
                        listDetails.length > 0 &&
                        searchFieldsList.map((cl, i) => <TableCell key={i} className={classes.tableCell}>{cl.question}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {searchFieldsList.length > 0 && listDetails.length > 0 ? (
                    listDetails.map((cl, i) => (
                        <TableRow
                            hover
                            key={i}
                            onClick={() =>
                                history.push(
                                    `${type}-details/config=${config}&subConfig=${subConfig}&index=${
                                        cl.find((id) => id.question === uniqueField)?.answer
                                    }`
                                )
                            }
                            className={classes.tableRow}
                        >
                            <React.Fragment>
                                {cl.map(
                                    (item, i) =>
                                        list.includes(item.question) ? (
                                            <TableCell key={i} className={classes.tdCell}>
                                                {ValidateRegex.dateRegex.test(cl[i].answer)
                                                    ? is_date(cl[i].answer)
                                                    : cl[i].answer}
                                            </TableCell>
                                        )
                                            :  (
                                                <TableCell>
                                                    ''
                                                </TableCell>
                                        )
                                )}
                            </React.Fragment>
                        </TableRow>
                    ))
                ) : (
                    <TableRow key={9999}>
                        {loading ? (
                            <React.Fragment>
                                <TableCell key={1} colSpan={4} style={{ textAlign: 'center' }}>
                                <h1>
                                    <Skeleton key={1} height="60px" />
                                </h1>
                                <Skeleton key={1} height="25px" />
                                </TableCell>
                            </React.Fragment>
                        ) : (
                            <TableCell className={classes.tdCell} key={1} colSpan={4} style={{ textAlign: 'center' }}>
                                No results found
                            </TableCell>
                        )}
                    </TableRow>
                )}
            </TableBody>
        </Table>
        </TableContainer>
    );
};

export default DynamicTableListView;