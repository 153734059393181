import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
const styles = (theme) => ({
    root: {
        // ...theme.mixins.gutters()
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        }
        // paddingTop: theme.spacing.unit * 1,
        // paddingBottom: theme.spacing.unit * 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper
        // marginTop: theme.spacing.unit * 1,
        // padding: `${theme.spacing.unit * 1}px 0`,
    }
});

const firstMatchLogo = css`
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: -0px;
    @media all and (max-width: 520px) {
        width: 80px;
    }
`;
const App = css`
    margin: 50px auto;
    width: 100%;
    background-color: #fff;
    padding: 16px;
    position: relative;
    @media all and (min-width: 520px) {
        padding: 40px;
        margin: 100px auto;
        width: 60vw;
    }
    @media all and (min-width: 520px) and (max-width: 1024px) {
        width: 90vw;
    }
`;
function Footer(props) {
    const currentYear = new Date().getFullYear();
    return (
        <footer>
            <Paper css={App} elevation={1}>
                <Table aria-label="users table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6">
                                    &copy; Copyright, All rights reserved by FirstMatch - {currentYear}
                                </Typography>
                            </TableCell>
                            <TableCell>
                              <h2>{process.env.REACT_APP_STATIC_SERVER_NAME}</h2> 
                            </TableCell>
                            <TableCell>
                            <TableRow>
                            <TableCell>
                            <Typography>Powered by:</Typography>
                            </TableCell>
                            <TableCell>
                            <img
                                css={firstMatchLogo}
                                height="30px"
                                width="100px"
                                alt="FirstMatch Logo"
                                src="/img/logo_stroke.png"
                            />
                            </TableCell>
                                </TableRow>
                                
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </Paper>
        </footer>
    );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
