/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { selectField, fieldRow, txtLabel, flexRow_content_center } from '../styles';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Helmeticon from './../../assets/helmet.svg';
import CustomPieChartLabel from './Utils/CustomPieChartLabel';
Chart.register(ChartDataLabels);

const data = [
    { name: 'Accepted & Placed', value: 200 },
    { name: 'Accepted not placed', value: 300 },
    { name: 'Rejected', value: 200 },
    { name: 'Pending', value: 210 }
];

interface Program_AnalyticsProps {
    ROCList: any;
    PCRList: any;
    Location: any;
    Referral: any;
    filter: any;
    onSelectChange: (src: any) => void;
}

const PA_COLORS = [
    { start: '#FFC443', end: '#FFE970' },
    { start: '#008AE8', end: '#00B4FF' },
    { start: '#fa2102', end: '#ff5036' }
];

const PAS_COLORS = [
    { start: '#00ffa7', end: '#06cc71' },
    { start: '#00b4f1', end: '#00e1ff' },
    { start: '#fa2102', end: '#ff5036' },
    { start: '#FFC443', end: '#FFE970' }
];

const ProgramAnalytics: React.FC<Program_AnalyticsProps> = (props) => {
    const AnalaticData = [
        {
            name: 'Positive Program Completion',
            value: props.PCRList.positive_pcr ? props.PCRList.positive_pcr.count : 0
        },
        {
            name: 'Negative Program Completion',
            value: props.PCRList.negative_pcr ? props.PCRList.negative_pcr.count : 0
        },
        { name: 'Program Completion not avaliable', value: props.PCRList.blank_pcr ? props.PCRList.blank_pcr.count : 0 }
    ];

    const ROCdata = [
        { name: 'Remained Out of Care', value: props.ROCList.positive_roc ? props.ROCList.positive_roc.count : 0 },
        { name: 'Record not avaliable', value: props.ROCList.negative_roc ? props.ROCList.negative_roc.count : 0 },
        {
            name: 'Threshold for review not met (<1 year)',
            value: props.ROCList.less_than_1_year ? props.ROCList.less_than_1_year.count : 0
        },
        {
            name: 'Returned to Care',
            value: props.ROCList.greater_than_1_year ? props.ROCList.greater_than_1_year.count : 0
        }
    ];

    const [filters, setfilters] = useState({ referral_source: '', location: '' });
    useEffect(() => {
        props.onSelectChange(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.referral_source, filters.location]);

    useEffect(() => {
        setfilters((prevState) => {
            return { ...prevState, referral_status: '', referral_source: '', location: '' };
        });
    }, [props.filter.days_count]);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                    <label
                        css={txtLabel}
                        //style={{ marginTop: 16 }}
                    >
                        Referral sources:
                    </label>

                    <select
                        css={selectField}
                        name="referral_source"
                        value={filters.referral_source || ''}
                        onChange={(e) => {
                            const val = e.target.value;
                            setfilters((prevState) => {
                                return { ...prevState, referral_source: val, location: '' };
                            });
                        }}
                    >
                        <option value="">Select</option>
                        {props.Referral.map((r) => (
                            <option key={r.id} value={r.id}>
                                {r.value}
                            </option>
                        ))}
                    </select>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <label
                        css={txtLabel}
                        //style={{ marginTop: 16 }}
                    >
                        Locations:
                    </label>

                    <select
                        css={selectField}
                        name="location"
                        value={filters.location || ''}
                        onChange={(e) => {
                            const val = e.target.value;
                            setfilters((prevState) => {
                                return { ...prevState, location: val };
                            });
                        }}
                    >
                        <option value="">Select</option>
                        {props.Location.map((l) => (
                            <option key={l.location} value={l.location_names}>
                                {l.location_names}
                            </option>
                        ))}
                    </select>
                </Grid>
            </Grid>
            <div css={fieldRow} style={{ justifyContent: 'center', marginTop: '10px' }}>
                <Grid container spacing={0} justifyContent="center">
                    <Grid item xs={7}>
                        <ResponsiveContainer width="100%" height={450}>
                            <PieChart className="pieWidth">
                                <Legend layout="vertical" iconType="circle" verticalAlign="bottom" align="center" />
                                <defs>
                                    {AnalaticData.map((entry, index) => (
                                        <linearGradient id={`paGradient${index}`}>
                                            <stop offset="0%" stopColor={PA_COLORS[index % PA_COLORS.length].start} />
                                            <stop offset="100%" stopColor={PA_COLORS[index % PA_COLORS.length].end} />
                                        </linearGradient>
                                    ))}
                                </defs>
                                <Pie
                                    isAnimationActive={false}
                                    data={AnalaticData}
                                    labelLine={false}
                                    label={
                                        <CustomPieChartLabel
                                            centerText={{
                                                title: 'Total Accepted and Placed',
                                                value: props.PCRList.total && props.PCRList.total.count
                                            }}
                                            labelRadiusPercent={6}
                                        />
                                    }
                                    dataKey="value"
                                    nameKey="name"
                                    outerRadius={140}
                                    innerRadius={80}
                                    fill="#8884d8"
                                >
                                    {AnalaticData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={`url(#paGradient${index})`} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className="pieWidth" css={flexRow_content_center}>
                            <div
                                style={{
                                    borderRadius: '18px',
                                    border: '4px solid #00E6A7',
                                    padding: '20px',
                                    width: '170px',
                                    height: '200px',
                                    textAlign: 'center',
                                    marginTop: '60px'
                                }}
                            >
                                <img src={Helmeticon} alt="Shuffle Crossing arrow" />
                                <p
                                    style={{
                                        marginTop: '-5px',
                                        fontSize: '45px',
                                        color: '#00E6A7',
                                        fontWeight: 600
                                    }}
                                >
                                    {props.PCRList.pc_rate ? props.PCRList.pc_rate : 0}
                                </p>
                                <p
                                    style={{
                                        marginTop: '-10px',
                                        fontSize: '12px',
                                        lineHeight: '1.3em',
                                        letterSpacing: '1px'
                                    }}
                                >
                                    Program Completion Rate
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <ResponsiveContainer width="100%" height={450}>
                            <PieChart className="pieWidth1" style={{ marginTop: '10px' }}>
                                <Legend layout="vertical" iconType="circle" verticalAlign="bottom" align="center" />
                                <defs>
                                    {data.map((entry, index) => (
                                        <linearGradient id={`pasGradient${index}`}>
                                            <stop offset="0%" stopColor={PAS_COLORS[index % PAS_COLORS.length].start} />
                                            <stop offset="100%" stopColor={PAS_COLORS[index % PAS_COLORS.length].end} />
                                        </linearGradient>
                                    ))}
                                </defs>
                                <Pie
                                    isAnimationActive={false}
                                    data={ROCdata}
                                    labelLine={false}
                                    label={
                                        <CustomPieChartLabel
                                            centerText={{
                                                title: `Positive Program Completion`,
                                                value: props.ROCList.total && props.ROCList.total.count
                                            }}
                                            labelRadiusPercent={5}
                                        />
                                    }
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={110}
                                    innerRadius={60}
                                    fill="#8884d8"
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={`url(#pasGradient${index})`} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className="pieWidth" css={flexRow_content_center}>
                            <div
                                style={{
                                    borderRadius: '18px',
                                    border: '4px solid #00E6A7',
                                    padding: '20px',
                                    width: '170px',
                                    height: '200px',
                                    textAlign: 'center',
                                    marginTop: '50px'
                                }}
                            >
                                <img src={Helmeticon} alt="Shuffle Crossing arrow" />
                                <p
                                    style={{
                                        marginTop: '-5px',
                                        fontSize: '45px',
                                        color: '#00E6A7',
                                        fontWeight: 600
                                    }}
                                >
                                    {props.ROCList.roc_rate ? props.ROCList.roc_rate : 0}
                                </p>
                                <p
                                    style={{
                                        marginTop: '-10px',
                                        fontSize: '12px',
                                        lineHeight: '1.3em',
                                        letterSpacing: '1px'
                                    }}
                                >
                                    Remained Out of Care Rate
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default ProgramAnalytics;
