/** @jsx jsx */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { jsx } from '@emotion/core';

const useStyles = makeStyles({
    shadow: {
        filter: 'drop-shadow( 0 0 1.5px rgba(0, 0, 0, .5))'
    }
});
const RADIAN = Math.PI / 180;

interface RenderCustomizedLabelProps {
    cx: any;
    cy: any;
    midAngle: any;
    outerRadius: any;
    innerRadius: any;
    fill: any;
    percent: any;
    value: any;
    centerText: any;
    labelRadiusPercent: number;
    showLabelWithPercent: boolean;
}
const RenderCustomizedLabel: React.FC<Partial<RenderCustomizedLabelProps>> = (props) => {
    const {
        cx,
        cy,
        midAngle,
        outerRadius,
        innerRadius,
        fill,
        percent,
        value,
        centerText,
        labelRadiusPercent,
        showLabelWithPercent
    } = props;

    const classes = useStyles();

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 30;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <g>
            {centerText ? (
                <g>
                    <text
                        x={cx}
                        y={cy - 5}
                        fill="indigo"
                        className="recharts-text recharts-label"
                        textAnchor="middle"
                        dominantBaseline="central"
                    >
                        <tspan alignmentBaseline="middle" fill="indigo" fontSize="27">
                            {centerText.value}
                        </tspan>
                    </text>
                    <text
                        x={cx}
                        y={cy + 20}
                        fill="#868790"
                        className="recharts-text recharts-label"
                        textAnchor="middle"
                        dominantBaseline="central"
                    >
                        {centerText.title !== 'Positive Program Completion' ? (
                            <tspan fill="#868790" fontSize="12">
                                {centerText.title}
                            </tspan>
                        ) : (
                            <tspan>
                                <tspan fill="#868790" fontSize="12" x={cx}>
                                    Positive Program
                                </tspan>
                                <tspan fill="#868790" fontSize="12" x={cx} dy={12}>
                                    Completion
                                </tspan>
                            </tspan>
                        )}
                    </text>
                </g>
            ) : (
                ''
            )}
            {percent < 0.1 ? (
                !value ? (
                    ''
                ) : (
                    <g>
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        <text
                            style={{ fontWeight: 'bold' }}
                            x={ex + (cos >= 0 ? 1 : -1) * 12}
                            y={ey}
                            textAnchor={textAnchor}
                            fill={fill}
                        >
                            {showLabelWithPercent ? value.toFixed(1) + '%' : value}
                        </text>
                    </g>
                )
            ) : (
                <g>
                    <circle cx={x} cy={y} r={`${labelRadiusPercent}%`} fill="white" className={classes.shadow} />
                    <text
                        x={x}
                        y={y}
                        text-anchor="middle"
                        fill={fill}
                        style={{ transform: 'translate(-0.2%, 1.3%)', fontWeight: 'bold' }}
                    >
                        {showLabelWithPercent ? value.toFixed(1) + '%' : value}
                    </text>
                </g>
            )}
        </g>
    );
};

const CustomPieChartLabel = React.memo(RenderCustomizedLabel);

export default CustomPieChartLabel;
