// DatePickerService.js

export class DatePickerService {
    static handleDatePicker = (idx, idy, date, prevState, record, updateLocalState) => {
        
        let DynamicQuestions = prevState.DynamicQuestions;
        let customDate = '';
        if (date) {
            const date2 = new Date(date);
            const mnth = ('0' + (date2.getMonth() + 1)).slice(-2);
            const day = ('0' + date2.getDate()).slice(-2);
            customDate = [date2.getFullYear(), mnth, day].join('/');
        } else {
            customDate = '';
        }

        DynamicQuestions[idx].questions[idy].answer = customDate;

        let isValidDate = date == 'Invalid Date' ? true : false;

        const validateDate = (maxStartDate, eDate) => {
            const minDefaultDate = eDate ? eDate : prevState.client_form['Date of Birth'] ? new Date(prevState.client_form['Date of Birth']) : new Date('1900-01-01');
            const isValid =
                minDefaultDate > new Date(customDate.toString()) || new Date(customDate.toString()) > maxStartDate
                    ? true
                    : false;
            return isValid;
        };

        if (customDate !== 'NaN-aN-aN') {
            let maxStartDate = new Date('9998-12-31');
            if (DynamicQuestions[idx].questions[idy].question === 'Date of Birth') {
                const minStartDate = new Date('1900-01-01');
                isValidDate =
                    minStartDate > new Date(customDate.toString()) || new Date(customDate.toString()) > maxStartDate
                        ? true
                        : false;

                if (!isValidDate) {
                    const validDate = prevState.isValid_date;
                    Object.keys(validDate).forEach((item) => {
                        if (validDate[item] !== 'Date of Birth') {
                            validDate[item] = validateDate(maxStartDate, customDate);
                        }
                    });
                }
            } else {
                isValidDate = validateDate(maxStartDate, null);
            }
        }

        const updatedDynamicQuestions = [...DynamicQuestions];
        updatedDynamicQuestions[idx].questions[idy].answer = customDate;
        updateLocalState({
            DynamicQuestions: updatedDynamicQuestions,
            client_form: {
                ...prevState.client_form,
                [DynamicQuestions[idx].questions[idy].question]: customDate
            },
            isValidDate: isValidDate,
            hasError: isValidDate,
        });
    };
}

export default DatePickerService;
