/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { ResponsiveContainer, Legend, Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts';
import CustomTooltip from './Utils/CustomTooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 240,
        width: 100
    },
    row: {
        height: 540,
        width: 100
    }
}));
interface Tool_AnalyticsProps {
    ROCCalibrationList: any;
    PCRCalibrationList: any;
    onSelectChange: (src: any) => void;
}

const Tool_Analytics: React.FC<Tool_AnalyticsProps> = (props) => {
    const classes = useStyles();
    const pcrData = Object.entries(props.PCRCalibrationList).map((data) => {
        var z: any = data;
        return {
            name: z[0],
            count: z[1].positive_percent
        };
    });

    const rocData = Object.entries(props.ROCCalibrationList).map((data) => {
        var z: any = data;
        return {
            name: z[0],
            count: z[1].positive_percent
        };
    });
    
    return (
        <div>
            <Typography className={classes.root}>
                <Card style={{ padding: '20px' }}>
                    <h4 style={{ marginBottom: '30px' }}>Performance Graph</h4>
                    <ResponsiveContainer width="100%" aspect={3}>
                        <LineChart
                            width={500}
                            height={300}
                            data={pcrData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name">
                                <Label
                                    value="Predicted Likelihood Percentage"
                                    offset={0}
                                    dy={20}
                                    position="center"
                                    textAnchor="middle"
                                />
                            </XAxis>
                            <YAxis>
                                <Label
                                    value="Actual Outcome"
                                    angle={-90}
                                    dx={20}
                                    dy={30}
                                    position="insideLeft"
                                    textAnchor="middle"
                                />
                            </YAxis>
                            <Tooltip content={<CustomTooltip labelText="%" nameKey="name" />} />
                            <Legend
                                layout="horizontal"
                                iconType="line"
                                verticalAlign="top"
                                align="right"
                                height={36}
                                formatter={() => <span>Program Completion Rate</span>}
                            />

                            <Line
                                isAnimationActive={false}
                                type="monotone"
                                dataKey="count"
                                stroke="#05c776"
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Card>

                <Card style={{ padding: '20px', marginTop: '10px' }}>
                    <h4 style={{ marginBottom: '30px' }}>Performance Graph</h4>
                    <ResponsiveContainer width="100%" aspect={3}>
                        <LineChart
                            width={500}
                            height={300}
                            data={rocData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name">
                                <Label
                                    value="Predicted Likelihood Percentage"
                                    offset={0}
                                    dy={20}
                                    position="center"
                                    textAnchor="middle"
                                />
                            </XAxis>
                            <YAxis>
                                <Label
                                    value="Actual Outcome"
                                    angle={-90}
                                    dx={20}
                                    dy={30}
                                    position="insideLeft"
                                    textAnchor="middle"
                                />
                            </YAxis>
                            <Tooltip content={<CustomTooltip labelText="%" nameKey="name" />} />
                            <Legend
                                layout="horizontal"
                                iconType="line"
                                verticalAlign="top"
                                align="right"
                                height={36}
                                formatter={() => <span>Remained out of care</span>}
                            />

                            <Line
                                isAnimationActive={false}
                                type="monotone"
                                dataKey="count"
                                stroke="#006efd"
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Card>
            </Typography>
        </div>
    );
};

export default Tool_Analytics;
