/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { Formik, FormikErrors } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fieldRow, backdrop } from './styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    link: {
        margin: theme.spacing(1, 1.5)
    },

    root: {
        // height: "80vh",
        width: '100%'
    },
    image: {
        background: 'linear-gradient(to right, #0078ff, #00bfff)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },

    paper: {
        width: 'auto',
        margin: theme.spacing(8, 4)
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    appBar: {
        backgroundColor: '#fff',
        width: '200px',
        paddingTop: 10,
        paddingLeft: 8
    },
    submit: {
        width: '45%',
        height: 'auto',
        // marginLeft: "100px",
        margin: theme.spacing(2, 0, 0)
    },
    logo: {
        width: '150px',
        height: 'auto',
        marginRight: '10px'
    },
    toolbar: {
        flexWrap: 'wrap'
    },
    toolbarTitle: {
        flexGrow: 1
    },
    brand: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        textDecoration: 'none'
    },
    brandTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 25,
        color: '#1e3056'
    },
    errormsg: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 15,
        color: 'red'
    },
    table: {
        minWidth: 650,
        border: 'none'
    }
}));

interface ChangePasswordValues {
    old_password: string;
    password: string;
    retype_password: string;
}

interface ChangePasswordProps {
    onLogin: (old_password: string, password: string, retype_password: string) => void;
    isLoading: boolean;
    error: string;
    hasLoginError: boolean;
    headerColor: any;
    user: any;
}

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
    const classes = useStyles();
    const initialValues: ChangePasswordValues = { old_password: '', password: '', retype_password: '' };
    const [tabIndex, setTabIndex] = useState(0);
    const { error } = props;

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const { name, role_type, email, is_pwd_expired, pwd_expires_in } = props.user.user;

    function createData(name, value) {
        return { name, value };
    }

    const rows = [
        createData('Name', name),
        createData('Role', role_type),
        createData('Expired In', pwd_expires_in),
        createData('Email Id', email)
    ];

    return (
        <div>
            <Backdrop css={backdrop} open={props.isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.paper}>
                {error && (
                    <Typography component="div" variant="body1" color="error" className={classes.errormsg}>
                        {error}
                    </Typography>
                )}
                <Formik
                    initialValues={initialValues}
                    validate={(values) => {
                        const errors: FormikErrors<ChangePasswordValues> = {};
                        if (!values.old_password) {
                            errors.old_password = 'Required';
                        }
                        if (!values.password) {
                            errors.password = 'Required';
                        }
                        if (!values.retype_password) {
                            errors.retype_password = 'Required';
                        }
                        if (values.password !== values.retype_password) {
                            errors.retype_password = 'New Password and Re-Type New Password must match';
                        }
                        if (values.password) {
                            const regex = `^(?=.*[A-Z])(?=.*\\d)(?=.*[+@#$%^&+=!*]).{8,12}$` 
                            const passwordRegex = new RegExp(regex);
                            if(!passwordRegex.test(values.password)){
                                errors.password = 'Must contain 8-12 characteristics, at least on capital letter, one number, and one special character (+@#$%^&+=!*)';
                            }
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        props.onLogin(values.old_password, values.password, values.retype_password);
                    }}
                >
                    {({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                        <form className={classes.form} noValidate onSubmit={handleSubmit}>

                            <Box>
                                <Box sx={{ fontSize: '14px', color: 'blue' }}>
                                    <Tabs value={tabIndex} onChange={handleTabChange}>
                                        <Tab label="Change Password" />
                                        <Tab label="User Details" />
                                    </Tabs>
                                </Box>
                                <Box sx={{ padding: 2 }}>
                                    {tabIndex === 0 ? (
                                        <Box>
                                            <Typography>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    type="password"
                                                    id="old_password"
                                                    label="Current Password"
                                                    name="old_password"
                                                    value={values.old_password || ''}
                                                    onChange={handleChange}
                                                    autoComplete="old_password"
                                                    autoFocus
                                                />
                                                {touched.old_password && errors.old_password ? (
                                                    <div style={{ color: 'red' }}>{errors.old_password}</div>
                                                ) : null}
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    label="New Password"
                                                    type="password"
                                                    id="password"
                                                    value={values.password || ''}
                                                    onChange={handleChange}
                                                    autoComplete="current-password"
                                                />
                                                
                                                {touched.password && errors.password ? (
                                                    <div style={{ color: 'red' }}>{errors.password}</div>
                                                ) : <span>Must contain 8-12 characteristics, at least on capital letter, one number, and one special character (+@#$%^&+=!*)</span>}
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="retype_password"
                                                    label="Re-Type New Password"
                                                    type="password"
                                                    id="retype_password"
                                                    value={values.retype_password || ''}
                                                    onChange={handleChange}
                                                    autoComplete="current-password"
                                                />
                                                {touched.retype_password && errors.retype_password ? (
                                                    <div style={{ color: 'red' }}>{errors.retype_password}</div>
                                                ) : null}
                                                <div css={fieldRow} style={{ justifyContent: 'center' }}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: props.headerColor,
                                                            color: '#fff'
                                                        }}
                                                    >
                                                        Update
                                                    </Button>
                                                </div>
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableBody>
                                                    {rows.map((row) => (
                                                        <TableRow key={row.name}>
                                                            <TableCell component="th" scope="row">
                                                                <Typography>
                                                                    <strong>{row.name}</strong>
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left" colSpan={3}>
                                                                <Typography>
                                                                    {row.name === 'Expired In' ? (
                                                                        <Chip
                                                                            label={
                                                                                is_pwd_expired
                                                                                    ? 'Already Password Expired !'
                                                                                    : row.value + ' Days'
                                                                            }
                                                                            color={
                                                                                is_pwd_expired ? 'secondary' : 'primary'
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        row.value
                                                                    )}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                </Box>
                            </Box>

                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ChangePassword;
