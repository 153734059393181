/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { panel, panelHeading, panelHeader, backdrop } from '../styles';

import totalReferral from '../../img/DemoGraph/total_referral.png';
import program_analytics from '../../img/DemoGraph/program_analytics.png';
import replacement_rate from '../../img/DemoGraph/replacement_rate.png';
import average_length_of_stay from '../../img/DemoGraph/average_length_of_stay.png';
import occupancy_rate from '../../img/DemoGraph/occupancy_rate.png';
import program_selection from '../../img/DemoGraph/program_selection.png';
import market_analysis from '../../img/DemoGraph/market_analysis.png';
import total_analytics from '../../img/DemoGraph/total_analytics.png';
import client_demographics_gender_age from '../../img/DemoGraph/client_demographics_gender_age.png';
import client_demographics_race_involvement from '../../img/DemoGraph/client_demographics_race_involvement.png';
import client_demographics_legal_status from '../../img/DemoGraph/client_demographics_legal_status.png';

const DemoDashboard = () => {
    const [demoOrgGraph, setDemoOrgGraph] = useState([
        {
            id: 0,
            header: 'Total Referrals',
            images: [totalReferral],
            subHeading: ''
        },
        {
            id: 1,
            header: 'Program Analytics - of referrals who are accepted and placed',
            images: [program_analytics],
            subHeading: ''
        },
        {
            id: 2,
            header: 'Replacement Rate - of referrals who are accepted and placed',
            images: [replacement_rate],
            subHeading: ''
        },
        {
            id: 3,
            header: 'Average Length of Stay - of referrals who are accepted and placed',
            images: [average_length_of_stay],
            subHeading: ''
        },
        {
            id: 4,
            header: 'Occupancy Rate - of referrals who are accepted and placed',
            images: [occupancy_rate],
            subHeading: ''
        },
        {
            id: 5,
            header: 'Program Selection & Allocation Statistics',
            images: [program_selection],
            subHeading: ''
        },
        // Custom graph object
        {
            id: 6,
            header: 'Market Analysis - Customer Trend - Referral Source Analysis',
            images: [market_analysis],
            subHeading: 'Referral Source Analysis'
        },
        {
            id: 7,
            header: 'FirstMatch® Prediction Tools Analytics & Calibration',
            images: [total_analytics],
            subHeading: ''
        },
        {
            id: 8,
            header: 'Client Demographics at the Time of Referral - of accepted and placed referrals',
            images: [
                client_demographics_gender_age,
                client_demographics_race_involvement,
                client_demographics_legal_status
            ],
            subHeading: ''
        }
    ]);

    return (
        <React.Fragment>
            {demoOrgGraph.map((graph, i) => {
                return (
                    <React.Fragment>
                        <Accordion defaultExpanded={i === 0 && true} key={graph.id}>
                            <AccordionSummary
                                css={panelHeader}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                            >
                                <h1 css={panelHeading}>{graph.header}</h1>
                            </AccordionSummary>
                            <AccordionDetails css={panel}>
                                {graph.subHeading && (
                                    <div css={panelHeader}>
                                        <p css={panelHeading}>&nbsp;&nbsp;{graph.subHeading}</p>
                                    </div>
                                )}
                                {graph.images.map((image, i) => (
                                    <React.Fragment>
                                        <img
                                            key={i}
                                            alt="images"
                                            src={image}
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                        <br />
                                    </React.Fragment>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default DemoDashboard;
