import { array } from "yup";

export class QuestionJumpService {
  static handleNestedJump(DynamicQuestions, idx, name, outcomesRelatedQuestions) {
    let currQues: any = [];
    let prevQues: any = [];
    let finalPrevQues: any = [];

    DynamicQuestions[idx].questions.forEach((que) => {
      if (que.question.replace(/ /g, '_') === name.replace(/ /g, '_') && que.suggested_jump.length > 0 ) {
        que.suggested_jump.filter((sug, i) =>{
          if(Array.isArray(que.answer) && que.answer.length === 0) {

            sug.question_jumpto.length === 0 ? currQues.push(sug) : prevQues.push(sug)

          } else {
            sug?.answer === que.answer ? currQues.push(sug) : prevQues.push(sug)
          }
          
        });
      } 
    });

    const previousOptionsSuggestedQues = (currQues, prevQues) => {
      if (currQues.length > 0 && prevQues) {
        prevQues.forEach(
          (prev) =>
            prev.question_jumpto &&
            prev.question_jumpto.forEach(
              (ques) => !currQues[0].question_jumpto.includes(ques) && finalPrevQues.push(ques)
            )
        );
        } else {
        let prevOptionsQues = [...prevQues.map((each) => each.question_jumpto)];
        prevQues && finalPrevQues.push(...prevOptionsQues);
      }
    };

    previousOptionsSuggestedQues(currQues, prevQues);

    const finalPrevQuestions = finalPrevQues.filter(Boolean);

    if (finalPrevQuestions.length > 0) {
      const serviceInstance = new QuestionJumpService(); // Create an instance
      serviceInstance.handleQuestionRecursive(DynamicQuestions, idx, finalPrevQuestions);
    } else {
      DynamicQuestions[idx].questions.map((pQues) => {
        let length = 0;
        DynamicQuestions[idx].questions.map((ques) => {
          ques.suggested_jump &&
            ques.suggested_jump.map(
              (sug) => ((sug?.question_jumpto && sug.question_jumpto.includes(pQues.question)) || outcomesRelatedQuestions.includes(pQues.question)) && length++
            );
        });

        if (length === 0) {
          pQues.related = 'no';
        }
      });
    }

    return DynamicQuestions;
  }

  handleQuestionRecursive = (DynamicQuestions, idx, finalPrevQuestions) => {
    DynamicQuestions[idx].questions.map(
      (ques) =>
        finalPrevQuestions.includes(ques.question) && ((ques.related = 'yes'), (ques.answer = ''))
    );

    let nestedRelated: any = [];
    DynamicQuestions[idx].questions
      .filter((ques) => finalPrevQuestions.includes(ques.question))
      .forEach((ques) => {
        ques.suggested_jump &&
          ques.suggested_jump.forEach((sug: any) => sug.question_jumpto && nestedRelated.push(...sug.question_jumpto));
      });

    nestedRelated.length > 0 && this.handleQuestionRecursive(DynamicQuestions, idx, nestedRelated);
  };
}


