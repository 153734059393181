import * as Types from '../../api/definitions';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface messageList {
    orgType: number,
    configType: number,
    isPredictionsAvailable: boolean,
    timeLag: number,
    messages: string[]
    }

export interface FamilyDetailsFormStepState {
    isLoading: boolean;
    error: any;
    errors: any;
    hasError: boolean;
    isSubmitted: boolean;
    ClientCode: any;
    client_form: any;
    DateTypeQuestions: any;
    isValid_date: any;
    Required_List: any;
    DynamicQuestions: any;
    prevJump: any;
    prevQuestionJump: any;
    csvfile: any;
    isOpen: boolean;
    err_msg: any;
    staticHeader: string;
    isEdit: string;
    reReffer: string;
    header_color: string;
    visitedQuestion: any;
    client_id: string;
    isSuccess: boolean;
    childQuestionsList: string[] | any;
    uniqueIdField: string;
    isPartialListAvailable: boolean;
    PartialSavedList: string[];
    IsLoadSingleRecord: boolean;
    progressMessage: messageList;
    subConfig: string | null;
    configType: string | null;
}

export interface FamilyDetailsFormStepProps extends RouteComponentProps {
    Referral: Types.Referral[];
    DynamicQuestions: any;
    //client: Types.Client;
    onFormSubmit: (client: Types.Client, action: string) => void;
    getSingleSavedFamily: (id: string, Config: string, subConfig: string, accessToken: string, methodType: string) => void; 
    loadSavedFamily: (id: string, methodType: string, callback: (result: boolean) => void ) => void;
    onFormSave: (client: Types.Client) => void;
    GetQuestions: () => void;
    GetSoftSaveList: (callback: (result) => void) => void;
    redirectToNextStep: () => void;
    isSavedFamilyLoaded: boolean;
    isLoading: boolean;
    hasError: boolean;
    error: string;
    isEdit: string;
    isPredictionScores: boolean;
    isModifyVersion: string;
    reReffer: string;
    errors: any;
    user: any;
    client_id: string;
    kidCode: string; 
}

export interface DynamicGeneratedQuestionsState {
    isLoading: boolean;
    error: any;
    errors: any;
    hasError: boolean;
    isSubmitted: boolean;
    ClientCode: any;
    client_form: any;
    DateTypeQuestions: any;
    isValid_date: any;
    Required_List: any;
    DynamicQuestions: any;
    prevJump: any;
    prevQuestionJump: any;
    csvfile: any;
    isOpen: boolean;
    err_msg: any;
    staticHeader: string;
    isEdit: string;
    reReffer: string;
    header_color: string;
    visitedQuestion: any;
    client_id: string;
    isSuccess: boolean;
   
}

export interface DynamicGeneratedQuestionsProps {
   DynamicQuestions: any;
   
}

export const InitialState = {
   isLoading: false,
    ClientCode: '',
    hasError: true,
    isSubmitted: false,
    DynamicQuestions: [],
    error: [],
    errors: '',
    client_form: [],
    DateTypeQuestions: [],
    isValid_date: [],
    Required_List: [],
    prevJump: [],
    prevQuestionJump: [],
    csvfile: '',
    err_msg: [],
    staticHeader: 'Please correct the following errors and try again.',
    isOpen: false,
    isEdit: '',
    reReffer: '',
    header_color: '',
    visitedQuestion: [],
    client_id: '',
    isSuccess: false,
    childQuestionsList: [],
    uniqueIdField: '',
    isPartialListAvailable: false,
    PartialSavedList: [],
    IsLoadSingleRecord: false,
    progressMessage: {
        orgType: 0,
        configType: 0,
        isPredictionsAvailable: false,
        timeLag: 100000,
        messages: [
            "FirstMatch is submitting data..."
        ]},
        subConfig: null,
        configType: null
}