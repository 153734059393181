/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { inputField } from '../components/styles';
import * as Utility from "../Common/Base";

const NumberField = ({ data, sectionIndex, questionIndex, state, getAge, isPredictionScores, handleChange, updateLocalState, keyUp, disableField, configType, subConfigType}) => {
    const dataState = Array.isArray(state) ? state.length > 0 ? state[sectionIndex] && state[sectionIndex][data.question] : '' : state.client_form[data.question] || state.client_form[data.question.replace(/ /g, '_')];
      
    return (
        <React.Fragment>
            <input
                css={inputField}
                data-msg={data.error_msg}
                readOnly={
                    data.field_type === 0 ||
                    data.question === 'Trauma Score' ||
                    (data.is_primary_key && state.reReffer === 'true')
                }
                name={data.question}
                value={
                    data?.question === 'Age' && !Array.isArray(state)
                        ? 
                        getAge(state, data, function(result){
                            state.error['Age'] = result.error;
                             state.client_form[data.question] = result[data.question];
                        }) 
                        : data?.question === 'Length of Stay' && !Array.isArray(state)
                        ? 
                        state.client_form[data.question] = Utility.get_length_of_stay(state) :  dataState ? dataState : ''
                }
                min={data.validation1}
                max={data.validation2}
                type={data.answer_type?.toLowerCase()}
                onWheel={({ target }) => (target as HTMLInputElement).blur()}
                onBlur={(e)=>keyUp(e, state, data, configType, subConfigType)}
                disabled={(isPredictionScores) || data.for_prediction_screen === 'yes' || disableField}
                onChange={(e) => handleChange(e, data, sectionIndex, questionIndex, state, updateLocalState)}
            />
        </React.Fragment>
    );
};

export default NumberField;