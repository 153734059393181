import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../redux-modules/root';
import { ContainerProps } from './Container';
import { Switch, Route } from 'react-router-dom';
import { WithSnackbarProps } from 'notistack';
import * as notifications from '../redux-modules/notifications';
import * as dynamicclient from '../redux-modules/dynamicclient';
import Notifications1 from '../components/Notifications1';
import Notifications2 from '../components/Notifications2';
import Notifications3 from '../components/Notifications3';
import Notifications4 from '../components/Notifications4';
import Notifications5 from '../components/Notifications5';
import NotificationsFetchDetails from '../components/NotificationsFetchDetails';
import { format } from 'date-fns';
import { Download_Notifications } from '../api/api';
import { domainPath } from '../App';
import DynamicNotifications from '../components/DynamicNotifications';
import DynamicNotificationDetails from '../components/DynamicNotificationDetails';
import { KeyUpService } from '../Services/KeyUpService';
interface MatchParams {
    index: string;
}

export interface NotificationsContainerState {
    isLoading: boolean;
    error: string;
    headerColor: string;
    org_type: any;
    is_Searched: boolean;
}
export interface NotificationsContainerProps extends ContainerProps<MatchParams>, WithSnackbarProps {
    getPendingNotifications: (accessToken: any, type: any, startdate?: any, enddate?: any) => Promise<void>;
    getPCRNotifications: (accessToken: any, type: any, startdate?: any, enddate?: any) => Promise<void>;
    getROCNotifications: (accessToken: any, type: any, filter?: any, startdate?: any, enddate?: any) => Promise<void>;
    getRecidivismNotifications: (
        accessToken: any,
        type: any,
        filter?: any,
        startdate?: any,
        enddate?: any
    ) => Promise<void>;
    searchDClient: (
        client_code: string,
        ssn: string,
        first_name: string,
        last_name: string,
        dob: string,
        is_accessToken: any,
        version: number,
        id_n: string
    ) => void;
}

export class NotificationsContainer extends React.Component<NotificationsContainerProps, NotificationsContainerState> {
    constructor(props: NotificationsContainerProps) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            headerColor: '',
            org_type: null,
            is_Searched: false
        };
    }

    async componentDidMount() {
        const header_color: any = this.props.user && this.props.user.user.header_color;
        const org_type: any = this.props.user && this.props.user.user.org_type;
        this.setState({
            headerColor: header_color,
            org_type: org_type
        });
    }

    getAllNotifications = async (Type: any, filter?: any) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        this.setState({ isLoading: true });
        switch (Type) {
            case 'pending':
                try {
                    this.setState({ isLoading: true });
                    await this.props.getPendingNotifications(is_accessToken, 'pending');
                    this.setState({ isLoading: false });
                } catch (error) {
                    console.log(error);
                    this.setState({ isLoading: false });
                    this.props.enqueueSnackbar('An error occurred.' + error);
                }
                break;

            case 'pcr':
                try {
                    this.setState({ isLoading: true });
                    await this.props.getPCRNotifications(is_accessToken, 'pcr');
                    this.setState({ isLoading: false });
                } catch (error) {
                    console.log(error);
                    this.setState({ isLoading: false });
                    this.props.enqueueSnackbar('An error occurred.' + error);
                }
                break;

            case 'roc':
                try {
                    this.setState({ isLoading: true });
                    await this.props.getROCNotifications(is_accessToken, 'roc', filter);
                    this.setState({ isLoading: false });
                } catch (error) {
                    console.log(error);
                    this.setState({ isLoading: false });
                    this.props.enqueueSnackbar('An error occurred.' + error);
                }
                break;
            case 'recidivism':
                try {
                    this.setState({ isLoading: true });
                    await this.props.getRecidivismNotifications(is_accessToken, 'recidivism', filter);
                    this.setState({ isLoading: false });
                } catch (error) {
                    console.log(error);
                    this.setState({ isLoading: false });
                    this.props.enqueueSnackbar('An error occurred.' + error);
                }
                break;
        }
    };

    Notifications_DateRange = async (Type: any, startDate: any, endDate: any) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        switch (Type) {
            case 'pending':
                try {
                    this.setState({ isLoading: true });
                    await this.props.getPendingNotifications(is_accessToken, Type, startDate, endDate);
                    this.setState({ isLoading: false });
                } catch (error) {
                    console.log(error);
                    this.setState({ isLoading: false });
                }
                break;

            case 'pcr':
                try {
                    this.setState({ isLoading: true });
                    await this.props.getPCRNotifications(is_accessToken, Type, startDate, endDate);
                    this.setState({ isLoading: false });
                } catch (error) {
                    console.log(error);
                    this.setState({ isLoading: false });
                }
                break;

            case 'roc':
                try {
                    this.setState({ isLoading: true });
                    await this.props.getROCNotifications(is_accessToken, Type, startDate, endDate);
                    this.setState({ isLoading: false });
                } catch (error) {
                    console.log(error);
                    this.setState({ isLoading: false });
                }
                break;
        }
    };

    DownloadReport = async (Type: any, startDate: any, endDate: any, filter?: any) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        let start_date = startDate ? format(new Date(startDate), 'yyyy-MM-dd') : '';
        let end_date = endDate ? format(new Date(endDate), 'yyyy-MM-dd') : '';
        switch (Type) {
            case 'pending':
                try {
                    this.setState({ isLoading: true });
                    await Download_Notifications(Type, start_date, end_date, is_accessToken);
                    this.setState({ isLoading: false });
                } catch (error) {
                    console.log(error);
                    this.setState({ isLoading: false });
                }
                break;

            case 'pcr':
                try {
                    this.setState({ isLoading: true });
                    await Download_Notifications(Type, start_date, end_date, is_accessToken);
                    this.setState({ isLoading: false });
                } catch (error) {
                    console.log(error);
                    this.setState({ isLoading: false });
                }
                break;

            case 'roc':
                try {
                    this.setState({ isLoading: true });
                    await Download_Notifications(Type, start_date, end_date, is_accessToken, filter);
                    this.setState({ isLoading: false });
                } catch (error) {
                    console.log(error);
                    this.setState({ isLoading: false });
                }
                break;
            case 'recidivism':
                try {
                    this.setState({ isLoading: true });
                    await Download_Notifications(Type, start_date, end_date, is_accessToken, filter);
                    this.setState({ isLoading: false });
                } catch (error) {
                    console.log(error);
                    this.setState({ isLoading: false });
                }
                break;
        }
    };
    searchDClient = async (
        client_code: string,
        ssn: string,
        first_name: string,
        last_name: string,
        dob: string,
        version: number,
        id_n: string
    ) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        try {
            await this.props.searchDClient(client_code, ssn, first_name, last_name, dob, is_accessToken, version, id_n);
            this.setState({
                is_Searched: true
            });
        } catch (error) {
            console.log(error);
            const { history } = this.props;
            if (error.status === 403) {
                history.push(`/${domainPath}/logout/`);
            }
        }
    };
    isDataAvailable = () => {
        this.setState({
            is_Searched: false
        });
    };
    render() {
        const { notifications: notificationState } = this.props;
        const pendingData = (notificationState && notificationState.pendingList) || [];
        const pcrData = (notificationState && notificationState.pcrList) || [];
        const rocData = (notificationState && notificationState.rocList) || [];
        const recidivismData = (notificationState && notificationState.recidivism) || [];
        const downloadData = (notificationState && notificationState.downloadReport) || [];
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        const { client: clientState } = this.props;
        const clientList = (clientState && clientState.clientList) || {};
        const { is_Searched } = this.state;
        const { Config, subConfig } = KeyUpService.getConfSubConf(this.props.location.pathname);
        const url = this.props.location.pathname;
        const days: any = url.split('/').pop();
        return (
            <Switch>
                <Route exact path={`/${domainPath}/notifications/pending`}>
                    <Notifications2
                        {...this.state}
                        Notification_data={pendingData}
                        onSearch={this.Notifications_DateRange}
                        onSearchDClient={this.searchDClient}
                        downloadReport={this.DownloadReport}
                    />
                </Route>
                <Route exact path={`/${domainPath}/notifications/pcr`}>
                    <Notifications3
                        {...this.state}
                        Notification_data={pcrData}
                        onSearch={this.Notifications_DateRange}
                        onSearchDClient={this.searchDClient}
                        downloadReport={this.DownloadReport}
                    />
                </Route>
                <Route
                    exact
                    path={
                        days.length > 3
                            ? `/${domainPath}/notifications/roc?days:id`
                            : `/${domainPath}/notifications/roc`
                    }
                >
                    <Notifications4
                        {...this.state}
                        Download_data={downloadData}
                        Notification_data={rocData}
                        onSearch={this.Notifications_DateRange}
                        onSearchDClient={this.searchDClient}
                        downloadReport={this.DownloadReport}
                    />
                </Route>
                <Route
                    exact
                    path={
                        days.length > 3
                            ? `/${domainPath}/notifications/rec?days:id`
                            : `/${domainPath}/notifications/rec`
                    }
                >
                    <Notifications5
                        {...this.state}
                        Download_data={downloadData}
                        Notification_data={recidivismData}
                        onSearch={this.Notifications_DateRange}
                        onSearchDClient={this.searchDClient}
                        downloadReport={this.DownloadReport}
                    />
                </Route>
                <Route exact path={`/${domainPath}/notifications/fetching-client/client-details/config=:config&subConfig=:subConfig&index=:index`}>
                    <NotificationsFetchDetails
                        {...this.state}
                        clientList={is_Searched ? Object.values(clientList) : []}
                        clearData={this.isDataAvailable}
                    />
                </Route>
                <Route exact path={`/${domainPath}/notifications/config=:config&subConfig=:subConfig`}>
                    <DynamicNotifications
                        accessToken={is_accessToken}
                        header_color={this.state.headerColor}
                        Config={Config}
                        subConfig={subConfig}
                    />
                    {/* <Notifications1
                        headerColor={this.state.headerColor}
                        getNotifications={this.getAllNotifications}
                        org_type={this.state.org_type}
                    /> */}
                </Route>
                <Route exact path={`/${domainPath}/notifications/details/config=:config&subConfig=:subConfig&index=:id`}>
                    <DynamicNotificationDetails 
                    accessToken={is_accessToken} 
                    headerColor={this.state.headerColor}
                    Config={Config}
                    subConfig={subConfig}
                        />
                </Route>
            </Switch>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        client: state.client,
        user: state.user,
        notifications: state.notifications
    };
};
const mapDispatchToProps = {
    getPendingNotifications: notifications.actions.getPendingNotifications,
    getPCRNotifications: notifications.actions.getPCRNotifications,
    getROCNotifications: notifications.actions.getROCNotifications,
    getRecidivismNotifications: notifications.actions.getRecidivismNotifications,
    searchDClient: dynamicclient.actions.searchDClient
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);