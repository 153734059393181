import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import createReducer from './createReducer';
import { FamilyQuestionsState } from './definitions/State';
import { AppState } from './root';
import * as Types from '../api/definitions';
import {
    insertDFamily,
    updateFamily,
    fetchLocations,
    saveLocationAndProgram,
    fetchProgramsForClient,
    fetchConfiguredQuestions,
    searchDFamily,
    dynamicSearchFamily,
    getSearchFields,
    getSoftSaveList,
    softSaveRecord,
    getSingleSavedRecord
} from '../api/api';

const initialState: FamilyQuestionsState = {
    familyQuestionsList: [],
    partiallySavedFamilyQuestions: [],
    family: Types.emptyDFamily,
    familyList: {},
    searchFieldsList: {},
    searchData: {},
    errors: {},
    familyUniqueId: ''
};
// TODO handle negative cases
// 1. new client creation fails - with and without excl.
// 2. submit prediction responds with error - no program found.
// 3. get locations responds with error - no locations found or empty array.

const { reducer, update } = createReducer<FamilyQuestionsState>('Family/UPDATE', initialState);
export const familyReducer = reducer;

export const actions = {
    update,

    getConfiguredQuestions(Config: string, subConfig: string, is_accessToken: any): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const response = await fetchConfiguredQuestions(Config, subConfig, is_accessToken);
            let ConfiguredQuestions = response && response;
            let uniqueField = '';
            ConfiguredQuestions.map((sec) =>
                sec.questions.map((ques) => {
                    if (ques.is_primary_key) {
                        uniqueField = ques.question;
                    }
                })
            );
            dispatch(update({ familyQuestionsList: response && response, familyUniqueId: uniqueField, partiallySavedFamilyQuestions: [] }));
            return response;
        };
    },

    updateFormValues(client_code: string, values: any): ThunkAction<void, AppState, null, AnyAction> {
        return (dispatch, getState) => {
            const familyList = getState().family?.familyList;
            if (familyList && familyList[Number(client_code)]) {
                const family = familyList[client_code];
                const cl: Types.DynamicFamily = {
                    ...family,
                    Program_Completion: values.Program_Completion,
                    Returned_to_Care: values.Returned_to_Care,
                    Remained_Out_of_Care: values.Remained_Out_of_Care,
                    program_significantly_modified: values.program_significantly_modified
                };
                familyList[client_code] = cl;
                dispatch(update({ familyList }));
            }
        };
    },

    getProgramsForfamily(client_code: string): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const response = await fetchProgramsForClient(client_code, '');
            const familyList = getState().family?.familyList;
            if (!familyList || !familyList[Number(client_code)]) {
                throw new Error('family not found');
            }
            const family = familyList[client_code];

            if (response) {
                const cl: Types.DynamicFamily = {
                    ...family,
                    SuggestedPrograms: response.program_model_suggested || null,
                    SuggestedLocations: family.client_selected_locations ? [family.client_selected_locations] : [],
                    selected_program: response.selected_program,
                    selected_location: response.selected_location || null
                };
                familyList[client_code] = cl;
                dispatch(update({ familyList }));
            }
        };
    },

    saveLocationAndProgram(
        selected_location: string,
        selected_program?: string
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const family = getState().family!.family;
            const cl: Types.DynamicFamily = {
                ...family,
                client_selected_locations: selected_location
            };
            dispatch(update({ family: cl }));
            let programParam: string;
            if (!selected_program && family.client_selected_program) {
                programParam = family.client_selected_program;
            } else if (selected_program) {
                programParam = selected_program;
            } else {
                throw Error('something went wrong while submitting');
            }
            const response = await saveLocationAndProgram(
                family.client_code!,
                programParam,
                selected_location,
                'fff',
                'ss',
                'wwe'
            );
            if (!response) {
                throw Error('something went wrong while submitting');
            }
            const clresult: Types.DynamicFamily = {
                ...cl,
                result_final: response.result
            };
            dispatch(update({ family: clresult }));
            // return clresult;
        };
    },

    insertDFamily(
        family: Types.DynamicFamily,
        Config: string,
        subConfig: string,
        action: string,
        is_accessToken: any,
        isPredictionScores?: boolean
    ): ThunkAction<Promise<any>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            let locations: string[] = [];
            let updatedDClient: Types.DynamicClient;
            const is_prediction_available = getState().user?.user.is_prediction_available;
            try {
                const response = await insertDFamily(family, Config, subConfig, action, is_accessToken, isPredictionScores);
                // const cl = {
                //     ...family,
                //     program_type: (response.data.response.program_type && response.data.response.program_type) || null,
                //     Confidence: (response.data.response.confidence && response.data.response.confidence) || null,
                //     Roc_confidence:
                //         (response.data.response.roc_confidence && response.data.response.roc_confidence) || null,
                //     referred_program:
                //         (response.data.response.program_type && response.data.response.program_type) || null,
                //     model_program:
                //         (response.data.response.model_program && response.data.response.model_program) || null,
                //     SuggestedPrograms:
                //         (response.data.response.list_program_types && response.data.response.list_program_types) || null
                // };
                 dispatch(update({ partiallySavedFamilyQuestions: [] }));

                // return cl;
                return response;
            } catch (errors) {
                // const client :any ={ response: "failed"}
                dispatch(update({ errors: errors }));
                return errors;
            }
        };
    },

    softSaveFamily(
        Dclient: Types.DynamicClient,
        Config: string,
        subConfig: string,
        is_accessToken: any
    ): ThunkAction<Promise<any>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            try {
                const response = await softSaveRecord(Dclient, Config, subConfig, is_accessToken);
                dispatch(update({partiallySavedFamilyQuestions: []}))
                 return response;
            } catch (errors) {
                // const client :any ={ response: "failed"}
                dispatch(update({ errors: errors }));
                return errors;
            }
        };
    },

    partialSavedList(
        configType: string,
        subConfigType: string,
        is_accessToken: any
    ): ThunkAction<Promise<any>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            try {
                const response = await getSoftSaveList(configType, subConfigType, is_accessToken);
                dispatch(update({partiallySavedFamilyQuestions: []}))
                 return response;
            } catch (errors) {
                // const client :any ={ response: "failed"}
                dispatch(update({ errors: errors }));
                return errors;
            }
        };
    },

    getSingleSavedFamily(
        id: string,
        Config: string,
        subConfig: string,
        is_accessToken: string,
        methodType: string
       
    ): ThunkAction<Promise<any>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            try {
                const response = await getSingleSavedRecord( Config, subConfig, is_accessToken, id, methodType );
                
                let result ;
                if(response && response.response && Array.isArray(response.response)) {
                    dispatch(update({partiallySavedFamilyQuestions: response?.response[0]}))
                    result = response.response[0]
                } else if(methodType === 'delete' && response && response.response === "" ) {
                    result = []
                } else {
                    result = null;
                }
               
                 return result;
            } catch (errors) {
                // const client :any ={ response: "failed"}
                dispatch(update({ errors: errors }));
                return errors;
            }
        };
    },

    updateFamily(family: Types.DynamicFamily): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            let locations: string[] = [];
            let updatefamily: Types.DynamicFamily;
            family = {
                ...family,
                yls_FamCircumstances_Score: family.yls_FamCircumstances_Score || null,
                yls_Edu_Employ_Score: family.yls_Edu_Employ_Score || null,
                yls_Peer_Score: family.yls_Peer_Score || null,
                yls_Subab_Score: family.yls_Subab_Score || null,
                yls_Leisure_Score: family.yls_Leisure_Score || null,
                yls_Personality_Score: family.yls_Personality_Score || null,
                yls_Attitude_Score: family.yls_Attitude_Score || null,
                yls_PriorCurrentOffenses_Score: family.yls_PriorCurrentOffenses_Score || null,
                family_support: family.family_support || null,
                fire_setting: family.fire_setting || null,
                level_of_aggression: family.level_of_aggression || null,
                client_self_harm: family.client_self_harm || null,
                Screening_tool_Trauma: family.Screening_tool_Trauma || null,
                cans_LifeFunctioning: family.cans_LifeFunctioning || null,
                cans_YouthStrengths: family.cans_YouthStrengths || null,
                cans_CareGiverStrengths: family.cans_CareGiverStrengths || null,
                cans_Culture: family.cans_Culture || null,
                cans_YouthBehavior: family.cans_YouthBehavior || null,
                cans_YouthRisk: family.cans_YouthRisk || null,
                cans_Trauma_Exp: family.cans_Trauma_Exp || null,
                referred_program: family.referred_program || null
            };
            try {
                const response = await updateFamily(family);
                if (!response) {
                    throw Error('something went wrong while saving the family');
                }

                if (response['Result'] && response['Result'].trim() !== '') {
                    return;
                }

                const cl = {
                    ...family,
                    program_type: response.program_type || null,
                    Confidence: response.Confidence || null,
                    Roc_confidence: response.Roc_confidence || null,
                    referred_program: response.program_type || null,
                    model_program: response.model_program || null,
                    SuggestedPrograms: response.list_program_types || null
                };
                dispatch(update({ family: cl }));
                updatefamily = cl;
                // return cl;
            } catch (errors) {
                dispatch(update({ family, errors: errors }));
                throw errors;
            }
            try {
                if (!updatefamily.client_code || !updatefamily.program_type) {
                    throw new Error('ERROR OCCURRED WHILE FETCHING PROGRAM LOCATIONS');
                }
                const response = await fetchLocations(updatefamily.client_code, updatefamily.program_type, 'dffg');
                if (response && response['result'] && response['result'] !== '') {
                    throw new Error(response['result']);
                }
                if (response && response['Suggested Locations']) {
                    locations = response['Suggested Locations'];
                }
                if (locations.length > 0) {
                    const cl: Types.DynamicFamily = {
                        ...updatefamily,
                        SuggestedLocations: [...locations],
                        client_selected_program: updatefamily.program_type
                    };
                    dispatch(update({ family: cl }));
                    // return cl;
                }
            } catch (error) {
                throw error;
            }
        };
    },

    searchDFamily(
        client_code: string,
        ssn: string,
        first_name: string,
        last_name: string,
        dob: string,
        is_accessToken: any,
        version: number,
        id_n: string
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const response = await searchDFamily(client_code, ssn, first_name, last_name, dob, is_accessToken, id_n);
            let familyList: { [key: string]: Types.DynamicFamily } = {};
            let searchData = response.response;
            searchData &&
                searchData.map((c: any, i: number) => {
                    if (c.client_code) {
                        return (familyList[c.client_code] = c);
                    }
                    if (c.id_n) {
                        if (version === i) {
                            return (familyList[c.id_n] = c);
                        }
                    }
                    if (c.id_n) {
                        if (version === undefined) {
                            return (familyList[c.id_n] = c);
                        }
                    }
                    return null;
                });
            dispatch(update({ familyList, searchData: searchData }));
            return;
        };
    },

    dynamicSearchFamily(
        data: string[],
        is_accessToken: string,
        configType: string,
        subConfigType: string,
        keySearch: any
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const response = await dynamicSearchFamily(data, is_accessToken);
            let familyList: { [key: string]: Types.DynamicFamily } = {};
            let searchData = response;
            searchData &&
                searchData.map((c: any, i: number) => {
                 c.sections.map((sec) =>
                    sec.questions.map((q: any, index: number) => {
                        if (q.question?.toLowerCase() === keySearch?.toLowerCase()) {
                            return (familyList[q.answer] = c);
                        }
                    }
                    )  
                )   
                 
                return null;
                });
               
            dispatch(update({ familyList, searchData: searchData }));
            return;
        };
    },
    
    getSearchFields(config:string, subConfig:string, is_accessToken: string): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const response = await getSearchFields(config, subConfig, is_accessToken);
            let searchFieldsList: { [key: string]: Types.DynamicFamily } = {};
            let data = response.response;
            searchFieldsList = data;
             dispatch(update({ searchFieldsList }));
            return;
        };
    },
    upsertClient: (
        family: Types.DynamicFamily,
        page1FormCompleted: boolean = false,
        excludePage2: boolean = false
    ): ThunkAction<void, AppState, void, any> => {
        const newCl = {
            ...family,
            // TODO as per raghu these are hardcoded for now
            primaryRaceCode: '1',
            ageAtEnrollStart: '1',
            english_second_lang: '1',
            enrollStart_date: null,
            type_of_drugs: 'aaa',
            FAST_FamilyTogetherScore: '0',
            FAST_CaregiverAdvocacyScore: '0'
        };
        return (dispatch, getState) => {
            dispatch(update({ family: newCl }));
        };
    },

    clear(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch) => {
            dispatch(
                update({
                    family: Types.emptyDFamily
                    //page1FormCompleted: false,
                    //excludePage2: false
                })
            );
        };
    },

    clearErrors(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch) => {
            dispatch(update({ errors: {} }));
        };
    }
};

export const selectors = {
    //
};