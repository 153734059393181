/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { selectField, txtLabel } from '../styles';
import { Cell, ResponsiveContainer } from 'recharts';
import { XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { BarChart, Bar } from 'recharts';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CustomTooltip from './Utils/CustomTooltip';

const data = [
    { name: 'Accepted & Placed', value: 243 },
    { name: 'Accepted not placed', value: 300 },
    { name: 'Rejected', value: 200 },
    { name: 'Pending', value: 210 }
];

const ageBarColors = [
    { start: '#0071EA', end: '#00BDFD' },
    { start: '#00BEFF', end: '#00F3FF' },
    { start: '#fa2102', end: '#ff5036' },
    { start: '#00BEFF', end: '#00F3FF' },
    { start: '#00DA76', end: '#00F6B0' },
    { start: '#fa2102', end: '#ff5036' },
    { start: '#0071EA', end: '#00BDFD' },
    { start: '#FFBD3E', end: '#FFE76C' },
    { start: '#00CD6E', end: '#00FAB7' },
    { start: '#0071EA', end: '#00BDFD' }
];

const plBarColors = [
    { start: '#0071EA', end: '#00BDFD' },
    { start: '#FFBD3E', end: '#FFE76C' }
];

const genderBarColors = [
    { start: '#00CD6E', end: '#00FAB7' },
    { start: '#0071EA', end: '#00BDFD' }
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 240,
        width: 100
    },
    row: {
        height: 540,
        width: 100
    }
}));
interface Client_DemographicsProps {
    Gender_List: any;
    Age_List: any;
    Demo_List: any;
    Referral: any;
    filter: any;
    onSelectChange: (src: any) => void;
}

const compare = (a, b) => {
    if (a.value > b.value) return 1;
    if (a.value < b.value) return -1;
    return 0;
};

const Client_Demographics: React.FC<Client_DemographicsProps> = (props) => {
    const classes = useStyles();
    const [filters, setfilters] = useState({ q: 'lang', referral_source: '' });
    const queryNames = [
        { name: 'lang', value: 'Primary Language' },
        { name: 'ls_type', value: 'Legal Status' },
        { name: 'sec_inv_cyf', value: 'Secondary Involvement' }
    ];

    const genderdata = [
        {
            name: 'Male',
            count: props.Gender_List ? props.Gender_List.male : 0
        },
        {
            name: 'Female',
            count: props.Gender_List ? props.Gender_List.female : 0
        }
    ];

    const prLanguage = props.Demo_List ? props.Demo_List : 0;

    const ageData = props.Age_List ? props.Age_List.sort(compare) : 0;

    useEffect(() => {
        props.onSelectChange(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.q, filters.referral_source]);

    useEffect(() => {
        setfilters((prevState) => {
            return { ...prevState, referral_status: '', referral_source: '', location: '' };
        });
    }, [props.filter.days_count]);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <label
                        css={txtLabel}
                        //style={{ marginTop: 16 }}
                    >
                        Select graph:
                    </label>
                    <select
                        css={selectField}
                        name="q"
                        value={filters.q || ''}
                        onChange={(e) =>
                            setfilters((prevState) => {
                                return { ...prevState, q: e.target.value };
                            })
                        }
                    >
                        <option value="lang">Primary Language</option>
                        <option value="ls_type">Legal Status</option>
                        <option value="sec_inv_cyf">Secondary Involvement</option>
                    </select>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <label
                        css={txtLabel}
                        //style={{ marginTop: 16 }}
                    >
                        Referral sources:
                    </label>

                    <select
                        css={selectField}
                        name="referral_source"
                        value={filters.referral_source || ''}
                        onChange={(e) => {
                            const val = e.target.value;
                            setfilters((prevState) => {
                                return { ...prevState, referral_source: val, location: '' };
                            });
                        }}
                    >
                        <option value="">Select</option>
                        {props.Referral.map((r) => (
                            <option key={r.id} value={r.id}>
                                {r.value}
                            </option>
                        ))}
                    </select>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Typography className={classes.root}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Card style={{ padding: '10px' }}>
                                <h4 style={{ marginBottom: '40px' }}>
                                    {queryNames.find((queryName) => queryName.name === filters.q)?.value}
                                </h4>
                                <ResponsiveContainer width="100%" aspect={3}>
                                    <BarChart
                                        layout="vertical"
                                        width={500}
                                        height={300}
                                        data={prLanguage}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <defs>
                                            {data.map((entry, index) => (
                                                <linearGradient id={`plGradient${index}`}>
                                                    <stop
                                                        offset="0%"
                                                        stopColor={plBarColors[index % plBarColors.length].start}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor={plBarColors[index % plBarColors.length].end}
                                                    />
                                                </linearGradient>
                                            ))}
                                        </defs>
                                        <CartesianGrid strokeDasharray="1 1" />
                                        <XAxis type="number" />
                                        <YAxis type="category" dataKey="value" />
                                        <Tooltip
                                            cursor={false}
                                            content={<CustomTooltip nameKey="value" activeColor="#006efd" />}
                                        />
                                        <Bar dataKey="count" barSize={30} radius={[0, 5, 5, 0]}>
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={`url(#plGradient${index})`} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card style={{ padding: '10px' }}>
                                <h4 style={{ marginBottom: '40px' }}>Gender</h4>
                                <ResponsiveContainer width="100%" aspect={3}>
                                    <BarChart
                                        layout="vertical"
                                        width={500}
                                        height={300}
                                        data={genderdata}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <defs>
                                            {data.map((entry, index) => (
                                                <linearGradient id={`genderGradient${index}`}>
                                                    <stop
                                                        offset="0%"
                                                        stopColor={
                                                            genderBarColors[index % genderBarColors.length].start
                                                        }
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor={genderBarColors[index % genderBarColors.length].end}
                                                    />
                                                </linearGradient>
                                            ))}
                                        </defs>
                                        <CartesianGrid strokeDasharray="1 1" />
                                        <XAxis type="number" />
                                        <YAxis type="category" dataKey="name" />
                                        <Tooltip
                                            cursor={false}
                                            content={<CustomTooltip nameKey="name" activeColor="#006efd" />}
                                        />
                                        <Bar dataKey="count" barSize={30} radius={[0, 5, 5, 0]}>
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={`url(#genderGradient${index})`} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Card style={{ padding: '10px' }}>
                            <h4 style={{ marginBottom: '40px' }}>Age</h4>
                            <ResponsiveContainer width="100%" aspect={3}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={ageData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}
                                >
                                    <defs>
                                        {ageData.map((entry, index) => (
                                            <linearGradient id={`ageGradient${index}`}>
                                                <stop
                                                    offset="0%"
                                                    stopColor={ageBarColors[index % ageBarColors.length].start}
                                                />
                                                <stop
                                                    offset="100%"
                                                    stopColor={ageBarColors[index % ageBarColors.length].end}
                                                />
                                            </linearGradient>
                                        ))}
                                    </defs>
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <XAxis dataKey="value" />
                                    <YAxis />
                                    <Tooltip
                                        cursor={false}
                                        content={<CustomTooltip nameKey="value" activeColor="#006efd" />}
                                    />
                                    <Bar dataKey="count" barSize={30} fill="url(#colorAge)" radius={[5, 5, 0, 0]}>
                                        {ageData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={`url(#ageGradient${index})`} />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>
                </Typography>
            </Grid>
        </div>
    );
};

export default Client_Demographics;
