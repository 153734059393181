/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { domainPath } from '../App';

import { subHeading, tableRow, dataTable } from './styles';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface NotificationsFetchDetails {
    clientList: any;
    clearData: () => void;
    isLoading: boolean;
    error: string;
    headerColor: string;
    // isLoading: boolean;
    // hasError: boolean;
    // error: string;
    is_Searched: boolean;
    org_type: number;
}

const NotificationsFetchDetails: React.FC<NotificationsFetchDetails> = (props) => {
    const history = useHistory();
    let { clientList } = props;

    useEffect(() => {
        props.clearData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (clientList.length > 0) {
            let clientDetails = clientList[0];
            const version: number | undefined = clientDetails.version ? clientDetails.version : undefined;
            history.push({
                pathname: `/${domainPath}/existing-client/client-details/${clientDetails['id_n']}`,
                state: { version: version }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientList]);

    let clientListSection = clientList ? clientList.map((sec) => sec.sections) : [];
    return (
        <React.Fragment>
            {clientListSection.length === 0 && (
                <React.Fragment>
                    <h1 css={subHeading} style={{ color: props.headerColor }}>
                        Collecting Details
                    </h1>
                    <Table aria-label="users table" css={dataTable}>
                        <TableHead> </TableHead>
                        <TableBody>
                            {[1, 2, 3, 4, 5].map((p: any, id) => (
                                <TableRow key={id} css={tableRow}>
                                    <Skeleton height="50px" />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default NotificationsFetchDetails;
