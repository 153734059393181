/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { tableHeader, tableRow, dataTable } from '../styles';
import CustomPieChartLabel from './Utils/CustomPieChartLabel';

const data = [
    { name: 'Accepted & Placed', value: 243 },
    { name: 'Accepted not placed', value: 300 },
    { name: 'Rejected', value: 200 },
    { name: 'Pending', value: 210 }
];

interface Allocation_AnalyticsProps {
    Allocation_List: any;
    Program_Alloc_List: any;
}

const Allocation_Analytics: React.FC<Allocation_AnalyticsProps> = (props) => {
    const PSAS_COLORS = [
        { start: '#FFC443', end: '#FFE970' },
        { start: '#00CEFF', end: '#00F8FF' }
    ];

    const psasdata = [
        {
            name: 'Tool',
            value: parseFloat(
                props.Allocation_List.tool ? props.Allocation_List.tool.percentage.replace(/%/g, '') : '0'
            )
        },
        {
            name: 'Chosen',
            value: parseFloat(
                props.Allocation_List.chosen ? props.Allocation_List.chosen.percentage.replace(/%/g, '') : '0'
            )
        }
    ];

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: '10px'
            }}
        >
            <ResponsiveContainer width="70%" height={300}>
                <PieChart>
                    <Legend layout="vertical" iconType="circle" verticalAlign="middle" align="right" />
                    <defs>
                        {data.map((entry, index) => (
                            <linearGradient id={`psasGradient${index}`}>
                                <stop offset="0%" stopColor={PSAS_COLORS[index % PSAS_COLORS.length].start} />
                                <stop offset="100%" stopColor={PSAS_COLORS[index % PSAS_COLORS.length].end} />
                            </linearGradient>
                        ))}
                    </defs>
                    <Pie
                        isAnimationActive={false}
                        data={psasdata}
                        labelLine={false}
                        label={<CustomPieChartLabel labelRadiusPercent={5} showLabelWithPercent />}
                        dataKey="value"
                        nameKey="name"
                        outerRadius={140}
                        innerRadius={80}
                        fill="#8884d8"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={`url(#psasGradient${index})`} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>

            <Table aria-label="clients table" css={dataTable}>
                <TableHead>
                    <TableRow css={tableHeader}>
                        <TableCell>Program</TableCell>
                        <TableCell>No of Clients​</TableCell>
                        <TableCell>% Tool​</TableCell>
                        <TableCell>% Chosen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.Program_Alloc_List.length > 0 ? (
                        props.Program_Alloc_List.map((pgm, key) => (
                            <TableRow hover key={key} css={tableRow}>
                                <TableCell>{pgm.program}</TableCell>
                                <TableCell>{pgm.total.count}</TableCell>
                                <TableCell>{pgm.tool.percentage}</TableCell>
                                <TableCell>{pgm.chosen.percentage}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow key={9999}>
                            <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                No results found
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
};

export default Allocation_Analytics;
