/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Formik, FormikErrors, ErrorMessage } from 'formik';
import { format } from 'date-fns';
import { domainPath } from '../App';
// import { format } from "date-fns";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import { subHeading, fieldRow, twoCol, inputField, tableHeader, tableRow, dataTable, label, backdrop } from './styles';
import { useHistory } from 'react-router-dom';

interface Notification3Props {
    Notification_data: any;
    onSearch: (Type: any, start_date: any, end_date: any) => void;
    onSearchDClient: (
        client_code: string,
        ssn: string,
        first_name: string,
        last_name: string,
        dob: string,
        version: number,
        id_n: string
    ) => void;
    downloadReport: (Type: any, start_date: any, end_date: any) => void;
    isLoading: boolean;
    error: string;
    headerColor: string;
    // isLoading: boolean;
    // hasError: boolean;
    // error: string;
    org_type: number;
}

interface FormValues {
    start_date: any;
    end_date: any;
}
const Notifications3: React.FC<Notification3Props> = (props) => {
    const history = useHistory();
    const [startdate, setStartDate] = useState<string | null>(null);
    const [enddate, setEndDate] = useState<string | null>(null);
    const getInitialValues = (): FormValues => {
        let start_date: any = null;
        let end_date: any = null;
        return {
            start_date: start_date || '',
            end_date: end_date || ''
        };
    };
    const is_date = (date) => {
        let dateObj: any = new Date(date.replace(/-/g, '/'));
        const regex = new RegExp(
            '([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})'
        );
        if (regex.test(date)) {
            let year = dateObj.getFullYear();
            let month = 1 + dateObj.getMonth();
            let datee = dateObj.getDate();
            let date1 = [month.toString().length > 1 ? month : `0${month}`, datee > 9 ? datee : `0${datee}`, year].join(
                '/'
            );
            return date1;
        }
        return date;
    };
    const getSearchDClient = async (version: number, id_n: string) => {
        history.push({
            pathname: `/${domainPath}/notifications/fetching-client/client-details/${id_n}`,
            state: { version: version }
        });
        let specialVersion: any = version;
        let currentVersion: number =
            specialVersion &&
            (parseInt(specialVersion.substring(1)) > 0
                ? parseInt(specialVersion.substring(1)) - 1
                : parseInt(specialVersion.substring(1)));
        let id_n1: string = id_n ? id_n : '';

        await props.onSearchDClient('', '', '', '', '', currentVersion, id_n1);
    };
    return (
        <React.Fragment>
            <Formik
                initialValues={getInitialValues()}
                validate={(values) => {
                    const errors: FormikErrors<FormValues> = {};
                    if (!values.start_date) {
                        errors.start_date = 'Required';
                    }
                    if (!values.end_date) {
                        errors.end_date = 'Required';
                    }
                    return errors;
                }}
                enableReinitialize
                onSubmit={async (values) => {
                    const sd = values.start_date ? format(new Date(values.start_date), 'yyyy-MM-dd') : '';
                    const ed = values.end_date ? format(new Date(values.end_date), 'yyyy-MM-dd') : '';
                    await props.onSearch('pcr', sd, ed);
                }}
            >
                {({ values, handleSubmit, handleChange, setFieldValue }) => (
                    <form name="UsersForm" onSubmit={handleSubmit}>
                        <h1 css={subHeading} style={{ color: props.headerColor }}>
                            Clients without a Program Completion and End Date:
                        </h1>
                        <div css={fieldRow}>
                            <div css={twoCol}>
                                <label css={label}>Start Date From</label>
                                <DatePicker
                                    css={inputField}
                                    name="start_date"
                                    selected={values.start_date}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        setFieldValue('start_date', date);
                                    }}
                                    placeholderText={'MM/DD/YYYY'}
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                                <ErrorMessage component="span" name="start_date" />
                            </div>
                            <div css={twoCol}>
                                <label css={label}>To</label>
                                <DatePicker
                                    css={inputField}
                                    name="end_date"
                                    selected={values.end_date}
                                    onChange={(date) => {
                                        setEndDate(date);
                                        setFieldValue('end_date', date);
                                    }}
                                    placeholderText={'MM/DD/YYYY'}
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                                <ErrorMessage component="span" name="end_date" />
                            </div>
                            <div css={twoCol}>
                                <label css={label}>&nbsp;</label>
                                <Button
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    style={{
                                        marginRight: 10,
                                        backgroundColor: props.headerColor,
                                        color: '#fff'
                                    }}
                                    // onClick={this.onLoad}
                                >
                                    Search
                                </Button>
                            </div>
                        </div>

                        <div css={fieldRow}></div>
                    </form>
                )}
            </Formik>
            <Backdrop css={backdrop} open={props.isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {props.Notification_data.length > 0 ? (
                <React.Fragment>
                    <Table aria-label="users table" css={dataTable}>
                        <TableHead>
                            {props.org_type === 2 ? (
                                <TableRow css={tableHeader}>
                                    {props.Notification_data.length > 0 &&
                                        Object.keys(props.Notification_data[0]).map((p, id) => (
                                            <TableCell>{p}</TableCell>
                                        ))}
                                </TableRow>
                            ) : (
                                <TableRow css={tableHeader}>
                                    <TableCell>Client ID</TableCell>
                                    {domainPath === 'perseus-house' && <TableCell>Date of Referral</TableCell>}
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    {domainPath === 'perseus-house' && <TableCell>Program</TableCell>}
                                    <TableCell>Referral Status</TableCell>
                                    {domainPath !== 'perseus-house' && <TableCell>Location</TableCell>}
                                    <TableCell>Start Date</TableCell>
                                </TableRow>
                            )}
                        </TableHead>
                        <TableBody>
                            {props.Notification_data.length > 0 ? (
                                props.Notification_data.map((p: any, id) =>
                                    props.org_type === 2 ? (
                                        <TableRow
                                            hover
                                            key={id}
                                            onClick={() => getSearchDClient(p?.version, p['Identification Number'])}
                                            css={tableRow}
                                        >
                                            {Object.values(p).map((col: any, id) => (
                                                <TableCell>{is_date(col)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ) : (
                                        <TableRow
                                            hover
                                            key={id}
                                            onClick={() => (
                                                history.push(
                                                    `/${domainPath}/existing-client/client-details/${p['Client Code']}`
                                                ),
                                                window.location.reload()
                                            )}
                                            css={tableRow}
                                        >
                                            <TableCell>{p['Client Code']}</TableCell>
                                            {domainPath === 'perseus-house' && (
                                                <TableCell>
                                                    {format(new Date(p['Date of Referral']), 'MM-dd-yyyy')}
                                                </TableCell>
                                            )}
                                            <TableCell>{p['First Name']} </TableCell>
                                            <TableCell>{p['Last Name']} </TableCell>
                                            {domainPath === 'perseus-house' && <TableCell> {p.Program} </TableCell>}
                                            <TableCell>{p.referral_status} </TableCell>
                                            {domainPath !== 'perseus-house' && (
                                                <TableCell>{p.client_selected_locations} </TableCell>
                                            )}
                                            <TableCell>{p.start_date} </TableCell>
                                        </TableRow>
                                    )
                                )
                            ) : (
                                <TableRow key={9999}>
                                    <TableCell colSpan={2}>No records were found.</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <div css={fieldRow} style={{ justifyContent: 'flex-end' }}>
                        <label css={label}>&nbsp;</label>
                        <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            style={{
                                marginRight: 10,
                                marginTop: 10,
                                backgroundColor: props.headerColor,
                                color: '#fff'
                            }}
                            onClick={() => props.downloadReport('pcr', startdate, enddate)}
                        >
                            Download Report
                        </Button>
                    </div>
                </React.Fragment>
            ) : (
                <Table aria-label="users table" css={dataTable}>
                    <TableBody>
                        <TableRow key={9999}>
                            <TableCell colSpan={2}>No records were found.</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
        </React.Fragment>
    );
};

export default Notifications3;
