/* tslint:disable */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, memo } from 'react';
import Modal from 'react-modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

interface messageList {
orgType: number,
configType: number,
isPredictionsAvailable: boolean,
timeLag: number,
messages: string[]
}
interface ProgressLoaderComponentProps {
    isLoading: boolean;
    progressMessage: messageList;
    updateLocalState: (data: any) => void;
    redirectToNextStep: () => void;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        padding: '50px',
        boxShadow: ' 12px 12px 15px rgb(204, 204, 204)',

    }
};

const ProgressLoaderComponent: React.FC<ProgressLoaderComponentProps> = ({isLoading, progressMessage, updateLocalState, redirectToNextStep}) => {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    
    useEffect(() => {
                 
            const intervalId = setInterval(() => {
                setCurrentMessageIndex(prevIndex => (prevIndex + 1) % progressMessage.messages.length);
            }, 3000);
            
            const timeoutId = setTimeout(() => { 
                
                clearInterval(intervalId);
                updateLocalState({
                    isLoading: false,
                    progressMessage: {}
                });
                
                redirectToNextStep();
            }, progressMessage.timeLag);

            return () => {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
            };
        
        }, [progressMessage]);
   
    const closeModal = (value) => {

    }

    return (
         <Modal
                isOpen={isLoading}
                ariaHideApp={false}
                onRequestClose={() => closeModal(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ position: "relative", display: "inline-flex", justifyContent:'center' }}>
                     <CircularProgress color="inherit"  />
                </Box>
                </div>
                <div>
                        <strong>{progressMessage.messages[currentMessageIndex]}</strong>
                    </div>
            </Modal>
    );
};

export default memo(ProgressLoaderComponent);
