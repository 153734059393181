/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import { wrap, subHeading, fieldRow, mainContent, twoCol, label1, label2, tableRow, dataTable } from '../styles';
import Dropdown from '../../Common/DropdownComponent';
import Radio from '../../Common/RadioOptionComponent';
import Checkbox from '../../Common/CheckboxComponent';
import NumberField from '../../Common/NumberComponent';
import DateField from '../../Common/DateComponent';
import InputField from '../../Common/InputComponent';
import { FormGeneratorState } from './IFormGenerator.interface';
import { ErrorMessageComponent } from '../NewFamilyDetails/ErrorMessageComponent';
import DynamicGeneratedQuestions from '../NewFamilyDetails/DynamicGeneratedQuestions';
import * as Utility from '../../Common/Base';
import { FormStateService } from '../../Services/StateUpdateService';
import { DatePickerService } from '../../Services/DatePickerService';
import { TextFieldService } from '../../Services/TextFieldService';
import { NumberFieldService } from '../../Services/NumberFieldService';
import { CheckboxService } from '../../Services/CheckboxService';
import { KeyUpService } from '../../Services/KeyUpService';
import { DropDownService } from '../../Services/DropDownService';

export const FormGeneratorComponent = (props) => {
    let initialState = {
        ...props,
        uniqueIdField: '',
        isLoading: false,
        ClientCode: '',
        hasError: false,
        isSubmitted: false,
        DynamicQuestions: [],
        error: [],
        errors: '',
        client_form: [],
        DateTypeQuestions: [],
        isValid_date: [],
        Required_List: [],
        prevJump: [],
        prevQuestionJump: [],
        err_msg: [],
        staticHeader: 'Please correct the following errors and try again.',
        isOpen: false,
        isEdit: props.isEdit,
        header_color: '',
        visitedQuestion: [],
        isSuccess: false,
        childQuestionsList: [],
        subConfig:  null,
        configType: null
    };
    const [state, setState] = useState<FormGeneratorState>(initialState);

    useEffect(() => {
       let DynamicQuestion = [...props.DynamicQuestions];
        //Grouping all not answered question's jumptoquestions and changing the related flag for not related ones to "yes"
        let relatedqueswithNoAnswer: any = [];
        DynamicQuestion.map((item) =>
            item.questions.map((ques) => {
                if (ques.answer_type === 'RADIO' || ques.answer_type === 'SELECT') {
                    if (ques.suggested_jump.length > 0) {
                        if (!ques.answer?.length) {
                            let relatedques = ques.suggested_jump.map((item) =>
                                item.question_jumpto ? item.question_jumpto : []
                            );
                            relatedques = relatedques?.length > 0 && relatedques?.flat(1);
                            if (relatedques && relatedques.length) {
                                relatedques && relatedqueswithNoAnswer.push(...relatedques);
                            }
                        }
                    }
                }
            })
        );
        const updatedArray = DynamicQuestion.map((obj, idx) => {
            const updatedQuestions = obj.questions.map((ques, idy) => {
                MappedAnswersHandler('', idx, idy, ques, DynamicQuestion, 'true');
                if (ques.related === 'no') {
                    if (relatedqueswithNoAnswer?.includes(ques.question)) {
                        return { ...ques, related: 'yes' };
                    } else {
                        return ques;
                    }
                } else {
                    return ques;
                }
            });

            return { ...obj, questions: updatedQuestions };
        });
        DynamicQuestion = updatedArray;
        //Grouping all related questions and changing related flag as 'no' for unanswered questions
        if (state.isEdit) {
            let relatedquestions: any = [];
            DynamicQuestion.map((item) =>
                item.questions.map((ques) => {
                    if (ques.answer_type === 'RADIO' || ques.answer_type === 'SELECT') {
                        if (Array.isArray(ques.answer) && ques.suggested_jump.length > 0) {
                            const jumpQuestions = ques.suggested_jump?.filter((item) =>
                                ques.answer?.includes(item.answer)
                            );
                            const relatedques = jumpQuestions.map((item) => item.question_jumpto);

                            if (jumpQuestions.length && relatedques && relatedques.length) {
                                relatedques[0] && relatedquestions.push(...relatedques[0]);
                            }
                        } else {
                            const jumpQuestions = ques.suggested_jump?.filter((item) => item.answer === ques.answer);

                            const relatedques = jumpQuestions.map((item) => item.question_jumpto);

                            if (jumpQuestions.length && relatedques && relatedques.length) {
                                relatedques[0] && relatedquestions.push(...relatedques[0]);
                            }
                        }
                    }
                })
            );
            const updatedArrayOfObjects = DynamicQuestion.map((obj) => {
                const updatedQuestions = obj.questions.map((ques) => {
                    if (ques.related === 'yes') {
                        if (relatedquestions?.includes(ques.question)) {
                            return { ...ques, related: 'no' };
                        } else {
                            return ques;
                        }
                    } else {
                        return ques;
                    }
                });

                return { ...obj, questions: updatedQuestions };
            });
            DynamicQuestion = updatedArrayOfObjects;
        }

        //Find and store : all date type question
        let date: any[] = [];
        DynamicQuestion &&
            DynamicQuestion.map((section) => {
                section.questions &&
                    section.questions.map((ques) => {
                        if (ques.answer_type === 'DATE') {
                            date.push(ques.question.toString());
                        }
                    });
            }); 
            const {Config, subConfig} = KeyUpService.getConfSubConf(props.location.pathname);
        // Create a question : For Reason for modification
        setState((prevState) => ({
            ...prevState,
            DynamicQuestions: DynamicQuestion,
            DateTypeQuestions: date,
            isOpen: props.errors ? true : false,
            err_msg: props.errors,
            configType: Config,
            subConfig: subConfig
            //     header_color: props.user && props.user.user.header_color
        }));
    }, [props.DynamicQuestions]);

    const formState = async () => {
        const data =  await FormStateService.processDynamicQuestions(state.DynamicQuestions, 'Prediction');
         
        let visitedQuestion = [] as any;
        setState ((prevState) => ({
            ...prevState,
            client_form: data.client_form,
            ClientCode: data.client_form[`${data.uniqueIdField}`],
            Required_List: data.Required_List,
            visitedQuestion: Object.assign({}, ...visitedQuestion),
            prevJump: data.prevJump,
            prevQuestionJump: data.prevQuestionJump,
            uniqueIdField: data.uniqueIdField
        }));
    };

    const formStateCallback = useCallback(formState, [formState]);

    useEffect(() => {
        formStateCallback();
    }, [state.DynamicQuestions]);

    const MappedAnswersHandler = (id, idx, idy, data, DynamicQuestions, isLoadTime) => {
        let mapped_answers_list = data.suggested_jump.find(
            (item) => item.answer?.toString() === data.answer?.toString()
        )?.mapping_answers;
        let getQuestions = mapped_answers_list?.map((item) =>
            DynamicQuestions[idx]?.questions.find(
                (list, index) =>
                    list.question === item.question &&
                    list.suggested_answers.map((ans, i) =>
                        item.answers?.includes(ans.value)
                            ? ((DynamicQuestions[idx].questions[index].suggested_answers[i].is_deleted = false),
                              !isLoadTime && (DynamicQuestions[idx].questions[index].answer = ''))
                            : ((DynamicQuestions[idx].questions[index].suggested_answers[i].is_deleted = true),
                              isLoadTime !== 'true' && (DynamicQuestions[idx].questions[index].answer = ''))
                    )
            )
        );
    };

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            isOpen: false,
            err_msg: [],
            isSuccess: false
        }));
    };

    const setChildAnswerstoParent = (state, question) => {
        let client_form: any = state.client_form;
        client_form[question] = state;
        setState((prevState) => ({
            ...prevState,
            client_form
        }));
    };

    const updateState = async(data) => {
       setState((prevState) => ({
            ...prevState,  // Spread the previous state
            ...data        // Spread the new data
        }));
        
        // setState( (prevState) => ({
        //     ...prevState,
        //     ...data
        // }))
       await formState();  
    }

    const handleChange = async (e) => {
        const { name, value } = e.target;
        let DynamicQuestions = [...state.DynamicQuestions];
        const type = e.target.dataset.type;

            if (type === 'file') {
                const idx = e.target.dataset.idx;
                const idy = e.target.dataset.idy;
                DynamicQuestions[idx].questions[idy].answer = value;
            }
            if (!state.DateTypeQuestions.includes(name)) {
                await setState((prevState) => ({
                    ...prevState,
                    DynamicQuestions,
                    client_form: {
                        ...state.client_form,
                        [name]: value
                    },
                    hasError: false
                }));
            }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const client_form = state.client_form;
        let Required_List = state.Required_List;
        let error = state.error;

        setState((prevState) => ({
            ...prevState,
            isSubmitted: true,
            isOpen: false
        }));

        let data = [] as any;
        let isValid_Data = true as any;
        Object.keys(client_form).map(
            (ele, i) => (
                data.push({ [ele]: client_form[ele] }),
                !client_form[ele] && Required_List[ele] === 'yes' && (isValid_Data = false)
            )
        );
        console.log(error, "error")
        const isError = Object.values(error).every((value) => (value === '' ? true : false));
        let formData = Object.assign({}, ...data);
       
        
        const everyDateQuestion = Object.values(state.isValid_date).every((value) => value === false);
        
        if (isValid_Data && isError && everyDateQuestion) {
           if (state.isEdit || !state.hasError) {
                setState((prevState) => ({
                    ...prevState,
                    isSubmitted: false,
                    err_msg: props.errors
                }));
                const response: any = await props.onFormSubmit(formData);
                
                if (response?.status === 'success') {
                    const clientCode = Object.keys(props.client).includes('New Client Code')
                            ? props.client['New Client Code']
                            : props.client[`${props.uniqueIdField}`];
                       await props.setClientCode(clientCode);
                    setState((prevState) => ({
                        ...prevState,
                        DynamicQuestions: props.DynamicQuestions,
                        isSuccess: true,
                        isOpen: props.errors ? true : false,
                        err_msg: props.errors,
                        staticHeader: 'Please correct the following errors and try again.',
                        header_color: props.user && props.user.user.header_color
                    }));
                                       
                    formState();
                } else {
                   await setState((prevState) => ({
                        ...prevState,
                        isOpen: props.errors ? true : false,
                        err_msg: props.errors,
                        staticHeader: 'Please correct the following errors and try again.',
                        header_color: props.user && props.user.user.header_color
                    }));
                }
            }
        }
    };

    const { DynamicQuestions, isSuccess, header_color, configType, subConfig } = state;
    return (
        <div css={wrap}>
            <div css={mainContent}>
                <ErrorMessageComponent
                    state={state}
                    isSuccess={isSuccess}
                    handleClose={handleClose}
                    subHeading={subHeading}
                    headerColor={header_color}
                />
                <form name="newForm" onSubmit={handleSubmit}>
                    {DynamicQuestions.map((sections, index) =>
                        sections.related === 'true' ? (
                            ''
                        ) : (
                            <React.Fragment>
                                {/* <h1 css={subHeading} style={{ color: header_color }} key={index}>
                                    {sections.section}
                                </h1> */}
                                <Table aria-label="users table" css={dataTable}>
                                    {sections.questions.map((ques, ind) => {
                                        return (
                                            <TableRow key={ind} css={tableRow}>
                                                {ques.for_prediction_screen === 'yes' &&
                                                   ques.is_child !== 'yes' && (
                                                        <div key={ind} css={fieldRow} style={{ marginBottom: 0 }}>
                                                            <div key={ind} css={twoCol}>
                                                                <label
                                                                    css={
                                                                        ['Reason for modification'].includes(
                                                                            ques.question
                                                                        )
                                                                            ? label2
                                                                            : label1
                                                                    }
                                                                >
                                                                    {ques.question}
                                                                </label>
                                                                {ques.description && (
                                                                    <label>
                                                                        <small>({ques.description})</small>
                                                                    </label>
                                                                )}{' '}
                                                            </div>
                                                            <br />
                                                            <div key={ind + 'option'} css={twoCol}>
                                                            {ques.answer_type === 'SELECT' ? (
                                                                <Dropdown
                                                                    data={ques}
                                                                    sectionIndex={index}
                                                                    questionIndex={ind}
                                                                    state={state}
                                                                    handleChange={DropDownService.handleDropDown}
                                                                    disableField={false}
                                                                    updateLocalState={updateState}
                                                                />
                                                            ) : ques.answer_type === 'RADIO' ? (
                                                                <React.Fragment>
                                                                    <Radio
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={state}
                                                                        handleChange={DropDownService.handleDropDown}
                                                                        updateLocalState={updateState}
                                                                        disableField={false}
                                                                    />
                                                                </React.Fragment>
                                                            ) : ques.answer_type === 'CHECKBOX' ? (
                                                                <Fragment>
                                                                    <Checkbox
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={state}
                                                                        handleChange={CheckboxService.handleCheckboxChange}
                                                                        updateLocalState={updateState}
                                                                        disableField={false}
                                                                    />
                                                                </Fragment>
                                                            ) : ques.answer_type === 'TEXT' ? (
                                                                <Fragment>
                                                                    <InputField
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={state}
                                                                        preventCopyPaste={Utility.preventCopyPaste}
                                                                        handleChange={TextFieldService.handleTextField}
                                                                        updateLocalState={updateState}
                                                                        keyUp={KeyUpService.handleKeyUp}
                                                                        disableField={false}
                                                                        configType={configType}
                                                                        subConfigType={subConfig}
                                                                    />
                                                                </Fragment>
                                                            ) : ques.answer_type === 'DATE' ? (
                                                                <DateField
                                                                    data={ques}
                                                                    sectionIndex={index}
                                                                    questionIndex={ind}
                                                                    state={state}
                                                                    validateDateFormate={Utility.validateDateFormate}
                                                                    handleDatePicker={
                                                                        DatePickerService.handleDatePicker
                                                                    }
                                                                    updateLocalState={updateState}
                                                                    DynamicQuestions={state.DynamicQuestions}
                                                                    disableField={false}
                                                                    configType={configType}
                                                                    subConfigType={subConfig}
                                                                />
                                                            ) : ques.answer_type === 'FILE' ? (
                                                                <Fragment>
                                                                    <input
                                                                        data-idx={index}
                                                                        data-idy={ind}
                                                                        name={ques.question}
                                                                        value={state.client_form[ques.question]}
                                                                        type={ques.answer_type.toLowerCase()}
                                                                        onChange={handleChange}
                                                                        disabled={false}
                                                                    />
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>
                                                                    <NumberField
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={state}
                                                                        getAge={Utility.getAge}
                                                                        isPredictionScores={
                                                                            props.isPredictionScores
                                                                        }
                                                                        handleChange={
                                                                            NumberFieldService.handleNumberField
                                                                        }
                                                                        updateLocalState={updateState}
                                                                        keyUp={KeyUpService.handleKeyUp}
                                                                        disableField={false}
                                                                        configType={configType}
                                                                        subConfigType={subConfig}
                                                                    />
                                                                </Fragment>
                                                            )}
                                                                {state.error[ques.question] && (
                                                                    <div style={{ color: 'red' }}>
                                                                        {state.error[ques.question]}
                                                                    </div>
                                                                )}
                                                                {state.isSubmitted ? (
                                                                    state.client_form[
                                                                        ques.question
                                                                    ]?.toString() ? (
                                                                        ''
                                                                    ) : ques.required === 'yes' ? (
                                                                        <div style={{ color: 'red' }}>Required</div>
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {ques.is_parent === 'yes' && (
                                                                    <div className="inline">
                                                                        <DynamicGeneratedQuestions
                                                                            DynamicQuestions={DynamicQuestions}
                                                                            QuestionsData={[ques]}
                                                                            state={state}
                                                                            validateDateFormate={
                                                                                Utility.validateDateFormate
                                                                            }
                                                                            index={index}
                                                                            setChildAnswerstoParent={
                                                                                setChildAnswerstoParent
                                                                            }
                                                                            questionIndex={ind}
                                                                            isSubmitted={state.isSubmitted}
                                                                            configType={configType}
                                                                        subConfigType={subConfig}

                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                            </TableRow>
                                        );
                                    })}
                                </Table>
                            </React.Fragment>
                        )
                    )}
                    <div css={fieldRow} style={{ justifyContent: 'flex-end' }}>
                        <Button type="submit" variant="contained" size="large" color="primary">
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
            {/* MAIN CONTENT */}
        </div>
    );
};

export default withRouter(FormGeneratorComponent);