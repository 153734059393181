/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { domainPath } from '../../App';
import { modifyOutcomeButton } from '../styles';
const ModifyHardSaveData = ({ client, is_role_type, SelectedVersion, modifyOutcome, setModifyOutcome, orgType, latestVersion, configType, subConfigType }) => {
    const [exclusionary_criteria, setExclusionary_Criteria] = useState('No');
    const [enableOutcomes, setEnableOutcomes] = useState<boolean>(false);
    const exclusionList1 = ["exclusionary", "criteria", "exists"];
    const exclusionList2 = ["referral", "rejected"];
    let string = "Exclusionary Criteria Exists/Referral Rejected";

    // Check if any word matches a list
    const matchesList = (list, words) => words.some(word => list.includes(word));

    // Check conditions
    const checkConditions = (words) => {
        words = string.toLowerCase().split(/[\s/]+/);
       
        const matchesExclusion1 = matchesList(exclusionList1, words);
        const matchesExclusion2 = matchesList(exclusionList2, words);
       
        // condition1: any word matches exclusionList1, regardless of exclusionList2
        if (matchesExclusion1 && !matchesExclusion2) return true;

        // condition2: word matches exclusionList2, and at least one matches exclusionList1
        if (matchesExclusion2 && matchesExclusion1) return true;

        // condition3: word matches both exclusionList1 and exclusionList2
        if (matchesExclusion1 && matchesExclusion2) return true;

        return false;
    };

    useEffect(() => {
        
        if (SelectedVersion) {
            let isExclusionary =
                SelectedVersion &&
                SelectedVersion.sections
                    .map(
                        (section) =>
                            section &&
                            section.questions.some(
                                (ques) => checkConditions(ques.question)
                            )
                    )
                    ?.find((item) => item !== false);

            if (isExclusionary) {
                SelectedVersion.sections.map((section) => {
                    section &&
                        section.questions.find((question) => {
                            if (checkConditions(question.question)) {
                                setExclusionary_Criteria(
                                    Array.isArray(question.answer) ? question.answer[0] : question.answer
                                );
                            }
                        });
                });
            } else {
                if ([3, 4].includes(orgType)) {
                    setExclusionary_Criteria('No');
                } else {
                    setExclusionary_Criteria('Yes');
                }
            }
        }


        if (latestVersion?.version === SelectedVersion?.version) {
            setEnableOutcomes(latestVersion?.version === SelectedVersion?.version);


        } else {
            setModifyOutcome('');
            setEnableOutcomes(latestVersion?.version === SelectedVersion?.version);
        }

    }, [SelectedVersion]);

    return (
        <Fragment>
            {['Super Admin', 'Tester'].includes(is_role_type) ? (
                <Fragment>
                    Modify
                    <Link
                        to={{
                            pathname: `/${domainPath}/existing-client/edit-details/config=${configType}&subConfig=${subConfigType}&index=${client}&isEdit=true&Rerefer=false`,
                            state: {
                                isModifyVersion: SelectedVersion && SelectedVersion._id,
                                isPredictionScores: false
                            }
                        }}
                    >
                        <Button>
                            <u css={modifyOutcomeButton}>
                                <strong>Client</strong>
                            </u>
                        </Button>
                    </Link>
                    {exclusionary_criteria === 'Yes' ? (
                        ''

                    ) : (enableOutcomes) && (


                        <Fragment>
                            &nbsp;|
                            <Button
                                onClick={() => setModifyOutcome(modifyOutcome ? '' : SelectedVersion._id)}
                            >
                                <u css={modifyOutcomeButton}>
                                    <strong style={{ color: modifyOutcome ? 'blue' : 'red' }}>Outcome</strong>
                                </u>
                            </Button>
                        </Fragment>
                    )}
                </Fragment>
            ) : (
                ''
            )}
        </Fragment>
    );
};

export default ModifyHardSaveData;