/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { AppState } from '../redux-modules/root';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, ErrorMessage, FormikErrors } from 'formik';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import PictureAsPdfIcon from '@material-ui/icons/NoteAdd';
import {
    wrap,
    subHeading,
    fieldRow,
    selectField,
    mainContent,
    twoCol,
    inputField,
    label,
    txtDetail,
    backdrop
} from './styles';
import Dropdown from './Dropdown';
import { baseApiUrl } from '../api/api';
import * as Types from '../api/definitions';
import { useSelector } from 'react-redux';
import { domainPath } from '../App';
interface CountyProgramSelectionProps {
    client: Types.Client;
    programList: Types.Program[];
    onProgramSelect: (selected_program: string, selected_name: string, uniqueIdField: string) => void;
    onLocationSelect: (selected_location: string, pcr_score: any, roc_score: any, callBack: (result: boolean)=> void) => Promise<void>;
    submitPrediction: (client: Types.Client) => void;
    isLoading: boolean;
    hasError: boolean;
    error: string;
    uniqueIdField: string;
}

interface FormValues {
    Program: any;
    Confidence: string;
    Roc_confidence: string;
    client_selected_location: string;
    Likelihood_to_Recidivate1: string;
    Likelihood_to_Recidivate2: string;
}
const CountyProgramSelection: React.FC<CountyProgramSelectionProps> = (props) => {
    const [clientCode, setClientCode] = useState<string | null>(null);
    const history = useHistory();

    const location: any = useLocation();
    const appState = useSelector((state: AppState) => state.user && state.user.user);

    useEffect(() => {
        let program_type: any = props.client.SuggestedPrograms
            ? props.client.SuggestedPrograms.find((item: any) => item.value === props.client.program_type)
            : null;
        props.client?.program_type && props.onProgramSelect(program_type ? program_type.id : 0, "", "");
    }, [props.client.program_type]);
    const programOptions = props.client.SuggestedPrograms
        ? props.client.SuggestedPrograms.map((p: any) => {
              return {
                  label: p.value,
                  value: p.id
                  //   predicted: p.value === props.client.program_type?.toString()
              };
          })
        : [];

    

    const getInitialValues = () => {
        const { client } = props;
        let program: any = null;

        if (client.client_selected_program?.toString()) {
            program = {
                label: programOptions.find((p) => p.value === client.client_selected_program)?.label,
                value: client.client_selected_program
            };
        }
        return {
            Program: program,
            Confidence: client.Confidence,
            Roc_confidence: client.Roc_confidence,
            Likelihood_to_Recidivate1: client.Likelihood_to_Recidivate1,
            Likelihood_to_Recidivate2: client.Likelihood_to_Recidivate2,
            client_selected_location: ''
        };
    };

    const onProgramChange = (program: any) => {
        props.onProgramSelect(program.value, "", "");
    };
    const loginPassToken: string = appState ? appState.accessToken + '/' : '';
    
    return (
        <div css={wrap}>
            <div css={mainContent}>
                <Backdrop css={backdrop} open={props.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <h1 css={subHeading}>FirstMatch&reg; Prediction</h1>
                <Formik
                    initialValues={getInitialValues()}
                    validate={(values) => {
                        const errors: FormikErrors<FormValues> = {};
                        // if (!values.client_selected_location) {
                        //   errors.client_selected_location = "Required";
                        // }
                        return errors;
                    }}
                    enableReinitialize
                    onSubmit={async (values) => {
                        const clientCode = props.client[`${props.uniqueIdField}`];
                        
                        try {
                            await props.onLocationSelect(
                                values.client_selected_location,
                                values.Confidence,
                                values.Roc_confidence,
                                function(result) {
                                    if(result){
                                        setClientCode(clientCode);
                                    }
                                }
                            );
                            
                        }
                        catch (error) {
                          console.error('Something went wrong.')
                        }
                       
                    }}
                >
                    {({ values, handleSubmit, handleChange }) => (
                        <form name="submitPredictionForm" onSubmit={handleSubmit}>
                            {clientCode && (
                                <div css={fieldRow} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                    Prediction program for client {props.client['First Name']}{' '}
                                    {props.client['Last Name']} has been created with client-id{' '}
                                  { props.uniqueIdField}
                                    .
                                    <a
                                        css={[txtDetail]}
                                        style={{ display: 'flex', marginRight: 15 }}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        onClick={() => history.push(`/${domainPath}/new-client`)}
                                        href={
                                            location.state.isPredictionScores
                                                ? `${baseApiUrl}/${domainPath}/index/${clientCode}/${loginPassToken}?is_for_scores=yes`
                                                : `${baseApiUrl}/${domainPath}/index/${clientCode}/${loginPassToken}?is_for_scores=no`
                                        }
                                    >
                                        <PictureAsPdfIcon /> Download Report
                                    </a>
                                </div>
                            )}
                            {!clientCode && (
                                <div>
                                    <div css={fieldRow}>
                                        <div css={twoCol}>
                                            <label css={label}>Program/Pathway: {props.client.referral_type}</label>
                                        </div>
                                        <div css={twoCol}>
                                            <Dropdown
                                                name="Program"
                                                options={programOptions}
                                                onChange={onProgramChange}
                                                defaultValue={programOptions.find(
                                                    (p: any) => p.value === props.client.client_selected_program
                                                )}
                                                value={values.Program || null}
                                            />
                                        </div>
                                    </div>

                                    <div css={fieldRow}>
                                        <div css={twoCol}>
                                            <label css={label}>Program/Pathway Successful Completion Likelihood</label>
                                        </div>
                                        <div css={twoCol}>
                                            <input
                                                type="text"
                                                readOnly
                                                name="Confidence"
                                                css={inputField}
                                                placeholder=""
                                                value={values.Confidence === null ? '' : values.Confidence}
                                                onChange={handleChange}
                                            />
                                            <ErrorMessage component="span" name="Confidence" />
                                        </div>
                                    </div>
                                    {values.Roc_confidence && (
                                        <div css={fieldRow}>
                                            <div css={twoCol}>
                                                <label css={label}>
                                                    Remain Out of Residential Placement Likelihood
                                                </label>
                                            </div>
                                            <div css={twoCol}>
                                                <input
                                                    type="text"
                                                    readOnly
                                                    name="roc_confidence"
                                                    css={inputField}
                                                    placeholder=""
                                                    value={values.Roc_confidence === null ? '' : values.Roc_confidence}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage component="span" name="roc_confidence" />
                                            </div>
                                        </div>
                                    )}
                                    {!values.Roc_confidence && (
                                        <React.Fragment>
                                    <div css={fieldRow}>
                                        <div css={twoCol}>
                                            <label css={label}>Likelihood to Recidivate: 1 Year</label>
                                        </div>
                                        <div css={twoCol}>
                                            <input
                                                type="text"
                                                readOnly
                                                name="Likelihood_to_Recidivate1"
                                                css={inputField}
                                                placeholder=""
                                                value={
                                                    values.Likelihood_to_Recidivate1 === null
                                                        ? ''
                                                        : values.Likelihood_to_Recidivate1
                                                }
                                                onChange={handleChange}
                                            />
                                            <ErrorMessage component="span" name="Likelihood_to_Recidivate1" />
                                        </div>
                                    </div>
                                    
                                    <div css={fieldRow}>
                                        <div css={twoCol}>
                                            <label css={label}>Likelihood to Recidivate: 2 Years</label>
                                        </div>
                                        <div css={twoCol}>
                                            <input
                                                type="text"
                                                readOnly
                                                name="Likelihood_to_Recidivate2"
                                                css={inputField}
                                                placeholder=""
                                                value={
                                                    values.Likelihood_to_Recidivate2 === null
                                                        ? ''
                                                        : values.Likelihood_to_Recidivate2
                                                }
                                                onChange={handleChange}
                                            />
                                            <ErrorMessage component="span" name="Likelihood_to_Recidivate1" />
                                        </div>
                                    </div>
                                    </React.Fragment>
                                    )}
                                    {props.client.super_vision_list && (
                                        <div css={fieldRow}>
                                            <div css={twoCol}>
                                                <label css={label}>Supervision Level</label>
                                            </div>
                                            <div css={twoCol}>
                                                <select
                                                    css={selectField}
                                                    name="client_selected_location"
                                                    value={values.client_selected_location}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select</option>
                                                    {props.client.super_vision_list &&
                                                        props.client.super_vision_list.map((loc) => (
                                                            <option key={loc} value={loc.id}>
                                                                {loc.value}
                                                            </option>
                                                        ))}
                                                </select>
                                                <ErrorMessage component="span" name="client_selected_location" />
                                            </div>
                                        </div>
                                    )}
                                    <div css={fieldRow} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <Button type="submit" size="large" variant="contained" color="primary">
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </form>
                    )}
                </Formik>
            </div>
            {/* MAIN CONTENT */}
        </div>
    );
};

export default CountyProgramSelection;
