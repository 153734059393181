import { number } from "yup";

export class FormStateService {
    static async processDynamicQuestions(DynamicQuestions, ScreenType) {
      let client_form = [] as any;
      let Required_List = [] as any;
      let prevJump = [] as any;
      let prevQuestionJump = [] as any; 
      let uniqueIdField = '';
  
      
      const newDynamicQuestions = [...DynamicQuestions];
      
      newDynamicQuestions &&
        newDynamicQuestions.map((sec, secIndex) => {
          sec.related === 'false' &&
            sec.questions &&
            sec.questions.forEach((ques, quesIndex) => {
              if(ques.is_primary_key) {
                uniqueIdField = ques.question;
              }
              this.getPermission(ques, ScreenType) &&
               client_form.push({
                  [ques.question]: this.getProcessedAnswer(ques)
                });
  
              ques.suggested_jump.filter((n) => {
                if (n && n.answer === ques.answer?.toString()) {
                  prevQuestionJump.push({
                    [ques.question.toString()]: n.question_jumpto
                  });
                  prevJump.push({
                    [ques.question.toString()]: n.jumpto
                  });
                }
              });
              ques.related === 'no' &&
                Required_List.push({
                  [ques.question.toString()]: ques.required
                });
            });
        });
  
      let form_data = Object.assign({}, ...client_form);

      return {
        DynamicQuestions: newDynamicQuestions,
        client_form: form_data,
        Required_List: Object.assign({}, ...Required_List),
        prevJump: Object.assign({}, ...prevJump),
        prevQuestionJump: Object.assign({}, ...prevQuestionJump),
        uniqueIdField: uniqueIdField
      };
    }
  
    static getPermission(ques, screen) {
      
      if (screen === 'Prediction') {
         if(ques.is_child !== 'yes' && ques.for_prediction_screen === 'yes' && ques.is_child !== 'yes') {
              return true;
         } 
       } else if(ques.related === 'no' && ques.is_child !== 'yes') {
        return true;
       } else {
        return false;
       }
      }

    static getProcessedAnswer(ques) {
      
      if (typeof ques.answer === 'object' && !Array.isArray(ques.answer)) {
        return ques.is_parent === 'no'
          ? ques.suggested_answers.find((item) => item.value === ques.answer?.value)?.value
          : ques.answer;
      } else if (Array.isArray(ques.answer)) {
        let answerValue = ques.suggested_answers.filter((q) => 
          !q.is_deleted && (ques.answer.includes(q.value) || ques.answer.includes(q.id)))?.map((item) => item.id?.toString());
        return answerValue;
      } else if (ques.answer === 0) {
        return ques.answer.toString();
      } else if (ques.suggested_answers.length >= 1) {
       const matchedAnswer = ques.suggested_answers.find(
          (p) => !p.is_deleted && (p.value === ques.answer || p.id?.toString() === ques.answer?.toString())
        );
         return matchedAnswer?.id.toString();
        
      } else {
         return ques.answer  ? ques.answer.toString() : '';
      }
    }
  }
  