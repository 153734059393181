import moment from "moment";
import { ClipboardEvent } from 'react';

export const validateDateFormate = (date) => {
    let result = moment(date).locale("en_us").format('YYYY-MM-DD');
        return result
};

export const preventCopyPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    alert('Copying and pasting is not allowed!');
};

export const getAge = (state, data, updateLocalState) => {

let date = state.client_form['Date of Birth'];
let fromDate =  state.client_form['Date of Referral'];
let range1 =  data.validation1;
 let range2= data.validation2;
let  error_msg= data.error_msg;
                        
    if (!date) {
        return '';
    }
    let today: Date;
    if (!fromDate) {
        today = new Date();
    } else {
        today = new Date(fromDate);
    }
    let birthDate = new Date(date);
    let age: any = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

   const err = parseInt(age) < parseInt(range1);
    const err1 = parseInt(age) > parseInt(range2);
   
        const error = err === true ? error_msg : err1 === true ? error_msg : '';
   const result = {error: error, Age: age.toString()}
   updateLocalState(result);
    return age.toString();
};

export const get_length_of_stay = (state) => {
    let startDate = state.client_form['Start Date'];
    let endDate = state.client_form['End Date'];
      let result ;  
    if (startDate && endDate) {
        let ONE_WEEK = 1000 * 60 * 60 * 24;
        // Convert both dates to milliseconds
        let date1_ms: any = new Date(startDate);
        let date2_ms: any = new Date(endDate);
        // Calculate the difference in milliseconds
        let difference_ms = Math.round(date2_ms - date1_ms);
        // Convert back to weeks and return hole weeks
        
        result = Math.floor(difference_ms / ONE_WEEK);
    }
    return result;
};

export const regexHandler = (type, spl_char, min, max, value, is_exact) => {
    min = min ? min : 0;
    max = max ? max : 1000;
    spl_char = spl_char ? spl_char : `+-@_!#$%^&*()<>?/|';"}{~: `;
    let allowSecondvalidation = true;
    let regex = '';
    if (is_exact) {
        switch (type) {
            case 'Both':
                // Switch: to check character , number & special character at least one available or not
                regex = `^(?=.*[A-Za-z])(?=.*\\d)(?=.*[^A-Za-z\\d]).{${min},${max}}$`;
                break;
            case 'Special characters':
                // Switch: to check characters , special character at least one available or not
                regex = `^(?=.*[A-Za-z])(?=.*[!@#$%^&*()_+{}\\[\\]:;<>,.?~])[A-Za-z!@#$%^&*()_+{}\\[\\]:;<>,.?~]+$`;
                break;
            case 'Numbers':
                // Switch: to check characters , special character at least one available or not
                regex = `^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{${min},${max}}$`;
                break;
            default:
                break;
        }
    }
    if (regex !== '') {
        const textRegex = new RegExp(regex);
        let result = textRegex.test(value);

        if (result) {
            allowSecondvalidation = true;
        } else {
            allowSecondvalidation = false;
        }
    }

    if (allowSecondvalidation) {
        let reGex =
            type === 'Both'
                ? `^[A-Za-z0-9${spl_char}]{${min},${max}}$`
                : type === 'Special characters'
                ? `^[A-Za-z${spl_char}]{${min},${max}}$`
                : type === 'Numbers'
                ? `^[a-zA-Z0-9]{${min},${max}}$`
                : type === 'Alphabet'
                ? `^[a-zA-Z]{${min},${max}}$`
                : `^.{${min},${max}}$`;
        return reGex;
    } else {
        return regex;
    }
};