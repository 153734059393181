/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { subHeading, fieldRow, twoCol, inputField, tableHeader, tableRow, dataTable, label, backdrop } from './styles';
import { domainPath } from '../App';
import {
    downloadDynamicNotifications,
    fetchNotificationDetails,
    fetchNotificationsData,
    getDateRangeDynamicNotifications
} from '../api/api';
import { TableSortLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Formik, FormikErrors, ErrorMessage } from 'formik';
import { format } from 'date-fns';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

interface FormValues {
    start_date: any;
    end_date: any;
}

const DynamicNotificationDetails: React.FC<any> = (props) => {
    const { id } = useParams<{ id: any }>();
    const [notificationDetails, setNotificationDetails] = useState<any>({});
    const [tableData, setTableData] = useState<any>([]);
    const notificationId: any = id;
    const history = useHistory();
    const [startdate, setStartDate] = useState<string | null>(null);
    const [enddate, setEndDate] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [dateField, setDateField] = useState('');
    const [sortOrder, setSortOrder] = useState<any>('asc');
    const [sortedColumn, setSortedColumn] = useState<any>(null);

    const handleSort = (column) => {
        if (sortedColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder('asc');
            setSortedColumn(column);
        }
    };

    const sortedData = sortedColumn
        ? tableData.sort((a, b) => {
              if (sortedColumn) {
                  if (sortOrder === 'asc') {
                      return a[sortedColumn].localeCompare(b[sortedColumn]);
                  } else {
                      return b[sortedColumn].localeCompare(a[sortedColumn]);
                  }
              }
          })
        : tableData;

    const getInitialValues = (): FormValues => {
        let start_date: any = null;
        let end_date: any = null;
        return {
            start_date: start_date || '',
            end_date: end_date || ''
        };
    };

    const downloadReport = async (startDate: any, endDate: any) => {
        const is_accessToken: any = props.accessToken;
        let start_date = startDate ? format(new Date(startDate), 'yyyy-MM-dd') : '';
        let end_date = endDate ? format(new Date(endDate), 'yyyy-MM-dd') : '';
        try {
            setLoading(true);
            await downloadDynamicNotifications(start_date, end_date, is_accessToken, notificationId);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const response: any = await fetchNotificationDetails(props.accessToken, id);
                setNotificationDetails(response);
                if (response) {
                    setDateField(response?.date_field);
                }
                const notificationsTableData: any = await fetchNotificationsData(props.accessToken, id);
                setTableData(notificationsTableData.response);
            } catch (e) {
                alert('Unable to fetch notifications list');
            }
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const columns = tableData && tableData.length > 0 ? Object.keys(tableData[0]) : [];

    const onSearch = async (startDate, endDate) => {
        const is_accessToken: any = props.accessToken;
        try {
            setLoading(true);
            const response: any = await getDateRangeDynamicNotifications(
                is_accessToken,
                startDate,
                endDate,
                notificationId
            );
            setTableData(response);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <h1 css={subHeading} style={{ color: props.headerColor }}>
                {notificationDetails && notificationDetails?.name}
            </h1>
            {notificationDetails && notificationDetails?.time_filter === 1 && (
                <Formik
                    initialValues={getInitialValues()}
                    validate={(values) => {
                        const errors: FormikErrors<FormValues> = {};
                        if (!values.start_date) {
                            errors.start_date = 'Required';
                        }
                        if (!values.end_date) {
                            errors.end_date = 'Required';
                        }
                        return errors;
                    }}
                    enableReinitialize
                    onSubmit={async (values) => {
                        const sd = values.start_date ? format(new Date(values.start_date), 'yyyy-MM-dd') : '';
                        const ed = values.end_date ? format(new Date(values.end_date), 'yyyy-MM-dd') : '';
                        await onSearch(sd, ed);
                    }}
                >
                    {({ values, handleSubmit, handleChange, setFieldValue }) => (
                        <form name="UsersForm" onSubmit={handleSubmit}>
                            <div css={fieldRow}>
                                <div css={twoCol}>
                                    <label css={label}>{dateField && dateField + ' From'}</label>
                                    <DatePicker
                                        css={inputField}
                                        name="start_date"
                                        selected={values.start_date}
                                        onChange={(date) => {
                                            setStartDate(date);
                                            setFieldValue('start_date', date);
                                        }}
                                        placeholderText={'MM/DD/YYYY'}
                                        showYearDropdown
                                        scrollableYearDropdown
                                    />
                                    <ErrorMessage component="span" name="start_date" />
                                </div>
                                <div css={twoCol}>
                                    <label css={label}>To</label>
                                    <DatePicker
                                        css={inputField}
                                        name="end_date"
                                        selected={values.end_date}
                                        onChange={(date) => {
                                            setEndDate(date);
                                            setFieldValue('end_date', date);
                                        }}
                                        placeholderText={'MM/DD/YYYY'}
                                        showYearDropdown
                                        scrollableYearDropdown
                                    />
                                    <ErrorMessage component="span" name="end_date" />
                                </div>
                                <div css={twoCol}>
                                    <label css={label}>&nbsp;</label>
                                    <Button
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        style={{
                                            marginRight: 10,
                                            backgroundColor: props.headerColor,
                                            color: '#fff'
                                        }}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>

                            <div css={fieldRow}></div>
                        </form>
                    )}
                </Formik>
            )}
            <Backdrop css={backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {sortedData.length > 0  ? (
                <React.Fragment>
                    <Table aria-label="users table" css={dataTable}>
                        <TableHead>
                            <TableRow css={tableHeader}>
                                {columns &&
                                    columns.map((item, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <TableSortLabel
                                                    active={sortedColumn === item}
                                                    direction={sortOrder}
                                                    onClick={() => handleSort(item)}
                                                >
                                                    {item}
                                                </TableSortLabel>
                                            </TableCell>
                                        );
                                    })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((item, index) => (
                                <TableRow
                                    key={index}
                                    hover
                                    onClick={() => (
                                        history.push({
                                            pathname: `/${domainPath}/existing-client/client-details/config=${props.Config}&subConfig=${props.subConfig}&index=${item[columns[0]]}`,
                                            state: { version: item.version }
                                        }),
                                         window.location.reload()
                                    )}
                                    css={tableRow}
                                >
                                    {columns.map((column) => (
                                        <TableCell key={column}>{item[column]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div css={fieldRow} style={{ justifyContent: 'flex-end' }}>
                        <label css={label}>&nbsp;</label>
                        <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            style={{
                                marginRight: 10,
                                marginTop: 10,
                                backgroundColor: props.headerColor,
                                color: '#fff'
                            }}
                            onClick={() => downloadReport(startdate, enddate)}
                        >
                            Download Report
                        </Button>
                    </div>
                </React.Fragment>
            ) : (
                <Table aria-label="users table" css={dataTable}>
                    <TableBody>
                        <TableRow key={9999}>
                            <TableCell colSpan={2}>No records were found.</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
        </React.Fragment>
    );
};

export default DynamicNotificationDetails;