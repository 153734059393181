import * as Types from '../api/definitions';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface messageList {
    orgType: number,
    configType: number,
    isPredictionsAvailable: boolean,
    timeLag: number,
    messages: string[]
    }

export interface PredictionFormStepState {
    uniqueIdField: string;
    isLoading: boolean;
    error: any;
    errors: any;
    hasError: boolean;
    isSubmitted: boolean;
    ClientCode: any;
    client_form: any;
    DateTypeQuestions: any;
    isValid_date: any;
    Required_List: any;
    DynamicQuestions: any;
    prevJump: any;
    prevQuestionJump: any;
    csvfile: any;
    isOpen: boolean;
    err_msg: any;
    staticHeader: string;
    isEdit: boolean;
    reReffer: string;
    header_color: string;
    trauma_score: number;
    visitedQuestion: any;
    client_id: string;
    isSuccess: boolean;
    childQuestionsList: any;
    isPartialListAvailable: boolean;
    PartialSavedList: string[];
    IsLoadSingleRecord: boolean;
    progressMessage: messageList;
    subConfig: string | null;
    configType: string | null;
}

export interface PredictionFormStepProps extends RouteComponentProps {
    Referral: Types.Referral[];
    DynamicQuestions: any;
    //client: Types.Client; 
    onFormSubmit: (client: Types.Client, action: string) => void;
    getSingleSavedClient: (id: string, Config: string, subConfig: string, accessToken: string, methodType: string) => void; 
    loadSavedClient: (id: string, methodType: string , callback: (result: boolean) => void) => void;
    onFormSave: (client: Types.Client) => void;
    GetQuestions: () => void;
    GetSoftSaveList: (callback: (result) => void) => void;
    isSavedClientLoaded: boolean;
    isLoading: boolean;
    hasError: boolean;
    error: string;
    isEdit: boolean;
    isPredictionScores: boolean;
    isModifyVersion: string;
    reReffer: string;
    errors: any;
    user: any;
    client_id: string;
    kidCode: string; 
    progressMessage: string;
    redirectToNextStep: () => void;
}

export const ReasonForModifyList = {
    section: '',
    section_id: 0,
    related: 'false',
    is_odm: 'no',
    questions: [
        {
            question_id: 0,
            question: 'Reason for modification',
            description: '',
            field_type: '1',
            answer_type: 'TEXT',
            suggested_answers: [],
            suggested_jump: [],
            validation1: 'Not Contains',
            validation2: 'Special characters',
            error_msg: 'It should not contains special characters',
            re_type: 'no',
            is_to_be_mask: 'no',
            related: 'no',
            required: 'yes',
            flag: '0',
            is_to_be_search: "no",
            is_parent: "no",
            is_child: "no",
            add_type: "normal",
            for_ml_model: "no",
            is_ans_dep_on_prev_que_ans: "no",
            for_prediction_screen: "no",
            api_call: "no",
            ml_model_path: "no",
            ml_input_fields_path: "no",
            is_primary_key: false,
            is_for_hiding: "no"
                
        }
    ]
}

export const initialState = {
    isLoading: false,
    uniqueIdField: '',
    ClientCode: '',
    hasError: true,
    isSubmitted: false,
    DynamicQuestions: [],
    error: [],
    errors: '',
    client_form: [],
    DateTypeQuestions: [],
    isValid_date: [],
    Required_List: [],
    prevJump: [],
    prevQuestionJump: [],
    csvfile: '',
    err_msg: [],
    staticHeader: 'Please correct the following errors and try again.',
    isOpen: false,
    isEdit: false,
    reReffer: '',
    header_color: '',
    trauma_score: 0,
    visitedQuestion: [],
    client_id: '',
    isSuccess: false,
    childQuestionsList: [],
    isPartialListAvailable: false,
    PartialSavedList: [],
    IsLoadSingleRecord: false,
    progressMessage: {
        orgType: 0,
        configType: 0,
        isPredictionsAvailable: false,
        timeLag: 100000,
        messages: [
            "FirstMatch is submitting data..."
        ]},
        subConfig: null,
        configType: null
}