import * as Utility from "../Common/Base";

export class TextFieldService {
    static handleTextField = async (e, data, secId, quesId, prevState, updateLocalState) => {
        const { name, value } = e.target;
        let { DynamicQuestions, client_form, error, hasError } = prevState;

        let updatedDynamicQuestions = DynamicQuestions;
        let updatedClientForm = { ...client_form };
        let updatedError = { ...error };

        const val2 = data.validation2 || '';
        const error_msg = data.error_msg || '';
        const error_msg2 = data.error_msg2 || '';
        const parent_question = data?.parent_question || '';
        const is_re_type = data.re_type || '';
        const is_exact = data?.exact_match || '';
        const currQues = name.replaceAll('_', ' ');

        // Compare Parent Question's Answer Type with Child Question's Answer Type
        let match_ans_err = '';
        if (is_re_type === 'yes') {
            match_ans_err = client_form[`Re-Type ${name}`] && value !== client_form[`Re-Type ${name}`] ? `${currQues} and Re-Type ${currQues} must match` : '';
        }

        data.answer = value;

        // Parent Question Validation
        if (parent_question) {
            const isEqualAnswer = client_form[parent_question] === value;
            updatedError[name] = isEqualAnswer ? '' : error_msg;
            hasError = !isEqualAnswer;
        } else {
            // Validation based on defined validation at tool
            const regex = Utility.regexHandler(val2, data.spl_characters, data.validation3, data.validation4, value, is_exact);
            const textRegex = new RegExp(regex);
            updatedError[name] = textRegex.test(value) ? match_ans_err : error_msg ? error_msg : error_msg2;
            hasError = data.required === 'no' ? false : !textRegex.test(value);
        }

        updatedDynamicQuestions = updatedDynamicQuestions.map((question) => {
            return {
                ...question,
                questions: question.questions.map((ques) => {
                    if (ques.name === name) {
                        return {
                            ...ques,
                            answer: value
                        };
                    }
                    return ques;
                })
            };
        });

        updatedClientForm[name] = value;
        updateLocalState({
            DynamicQuestions: updatedDynamicQuestions,
            client_form: updatedClientForm,
            error: updatedError,
            hasError: hasError
        });
    };
}

export default TextFieldService;
