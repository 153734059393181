/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { selectField, fieldRow, twoCol, twoColl, txtLabel, label } from '../styles';
import Crossingicon from './../../assets/shuffle-crossing-arrows.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },

    paper: {
        padding: theme.spacing(5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 240,
        width: 100
    },

    row: {
        height: 540,
        width: 100
    },

    leftBox: {
        textAlign: 'left'
    },

    rightBox: {
        textAlign: 'right'
    },

    iconClass: {
        marginTop: '15px'
    },

    iconClasses: {
        marginTop: '28px'
    }
}));
interface Other_AnalyticsProps {
    Replace_analytics: any;
    Location: any;
    Referral: any;
    filter: any;
    onSelectChange: (src: any) => void;
}

const OtherParam_Analytics: React.FC<Other_AnalyticsProps> = (props) => {
    const classes = useStyles();
    const [filters, setfilters] = useState({ referral_source: '', location: '' });

    useEffect(() => {
        props.onSelectChange(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.referral_source, filters.location]);

    useEffect(() => {
        setfilters((prevState) => {
            return { ...prevState, referral_status: '', referral_source: '', location: '' };
        });
    }, [props.filter.days_count]);
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                    <label
                        css={txtLabel}
                        //style={{ marginTop: 16 }}
                    >
                        Referral sources:
                    </label>

                    <select
                        css={selectField}
                        name="referral_source"
                        value={filters.referral_source || ''}
                        onChange={(e) => {
                            const val = e.target.value;
                            setfilters((prevState) => {
                                return { ...prevState, referral_source: val, location: '' };
                            });
                        }}
                    >
                        <option value="">Select</option>
                        {props.Referral.map((r) => (
                            <option key={r.id} value={r.id}>
                                {r.value}
                            </option>
                        ))}
                    </select>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <label
                        css={txtLabel}
                        //style={{ marginTop: 16 }}
                    >
                        Locations:
                    </label>

                    <select
                        css={selectField}
                        name="location"
                        value={filters.location || ''}
                        onChange={(e) => {
                            const val = e.target.value;
                            setfilters((prevState) => {
                                return { ...prevState, location: val };
                            });
                        }}
                    >
                        <option value="">Select</option>
                        {props.Location.map((l) => (
                            <option key={l.location} value={l.location_names}>
                                {l.location_names}
                            </option>
                        ))}
                    </select>
                </Grid>
            </Grid>

            <div css={fieldRow} style={{ display: 'grid', justifyContent: 'center', marginTop: '10px' }}>
                <div css={twoColl}>
                    <Typography className={classes.root}>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <Grid className={classes.rightBox} item>
                                <div
                                    style={{
                                        borderRadius: '18px',
                                        border: '4px solid #00E6A7',
                                        padding: '20px',
                                        width: '170px',
                                        height: '200px',
                                        textAlign: 'center',
                                        marginTop: '20px'
                                    }}
                                >
                                    <img
                                        className={classes.iconClass}
                                        src={Crossingicon}
                                        alt="Shuffle Crossing arrow"
                                    />
                                    <p
                                        style={{
                                            marginTop: '-8px',
                                            fontSize: '45px',
                                            color: '#00E6A7',
                                            fontWeight: 600
                                        }}
                                    >
                                        {props.Replace_analytics.replacement_rate
                                            ? props.Replace_analytics.replacement_rate
                                            : 0}
                                    </p>
                                    <p
                                        style={{
                                            marginTop: '-25px',
                                            fontSize: '12px',
                                            lineHeight: '1.3em',
                                            letterSpacing: '1px'
                                        }}
                                    >
                                        Total Discharges
                                        <br />
                                        to
                                        <br />
                                        Total Admissions
                                    </p>
                                </div>
                            </Grid>
                            <Grid className={classes.leftBox} item>
                                <div
                                    style={{
                                        borderRadius: '18px',
                                        border: '4px solid #00E6A7',
                                        padding: '20px',
                                        width: '170px',
                                        height: '200px',
                                        textAlign: 'center',
                                        marginTop: '20px'
                                    }}
                                >
                                    <img
                                        className={classes.iconClass}
                                        src={Crossingicon}
                                        alt="Shuffle Crossing arrow"
                                    />
                                    <p
                                        style={{
                                            marginTop: '-8px',
                                            fontSize: '45px',
                                            color: '#00E6A7',
                                            fontWeight: 600
                                        }}
                                    >
                                        {props.Replace_analytics.total_replacement_rate
                                            ? props.Replace_analytics.total_replacement_rate
                                            : 0}
                                    </p>
                                    <p
                                        style={{
                                            marginTop: '-25px',
                                            fontSize: '12px',
                                            lineHeight: '1.3em',
                                            letterSpacing: '1px'
                                        }}
                                    >
                                        Negative Discharges
                                        <br />
                                        to
                                        <br />
                                        Total Admissions
                                    </p>
                                </div>
                            </Grid>
                        </div>
                    </Typography>
                </div>
                <div css={twoCol}></div>
            </div>
        </div>
    );
};

export default OtherParam_Analytics;
