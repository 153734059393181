/** @jsx jsx */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { jsx } from '@emotion/core';

const useStyles = makeStyles({
    container: {
        backgroundColor: 'black',
        opacity: 0.6,
        color: 'white',
        borderRadius: '5px',
        padding: '4px'
    },
    margin: {
        marginBottom: 0,
        marginTop: 0
    },
    dot: {
        height: '13px',
        width: '13px',
        backgroundColor: (props: any) => props.color,
        borderRadius: '50%',
        display: 'inline-block'
    },
    labelStyle: {
        paddingLeft: '10px',
        display: 'inline'
    }
});

interface CustomToolTipProps {
    active?: boolean;
    payload?: any;
    labelText?: string;
    nameKey: string;
    activeColor?: string;
}
const CustomTooltip: React.FC<CustomToolTipProps> = (props) => {
    const { active, payload, labelText, nameKey, activeColor } = props;
    const classes = useStyles({ color: activeColor ? activeColor : payload[0]?.color });
    let dataKey = payload[0]?.dataKey;
    
    if (active && payload && payload.length) {
        return (
            <div className={classes.container}>
                <p className={classes.margin}>{`${payload[0]?.payload?.[nameKey]}`}</p>
                <div>
                    <span className={classes.dot}></span>
                    <p className={classes.labelStyle}>{`${labelText ? labelText : payload[0]?.name} : ${
                        payload[0]?.payload?.[dataKey]
                    }`}</p>
                </div>
            </div>
        );
    }

    return null;
};

export default CustomTooltip;
