/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Modal from 'react-modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { dataTable, tableHeader } from './styles';
import { domainPath } from '../App';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        // marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto'
    }
};

const SoftSavedList = (props) => {
    let { isPartialListAvailable, PartialSavedList, updateState } = props;
    // Extract headers from the data
    let headers = PartialSavedList.length > 0 ? Object.keys(PartialSavedList[0]) : [];
    headers = headers.filter((item) => item !== '_id');

    return (
        <div className="container-fluid mb-5" style={{ marginTop: '3rem' }}>
            <Modal
                isOpen={props.isPartialListAvailable}
                onRequestClose={() => props.updateState({ isPartialListAvailable: !isPartialListAvailable })}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Data dump filters"
                key={1}
            >
                <Table aria-label="clients table" css={dataTable}>
                    <TableHead>
                        <TableRow css={tableHeader}>
                            {headers.map((header) => (
                                <TableCell key={header}>{header}</TableCell>
                            ))}
                            <TableCell key={`Action`}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {PartialSavedList.map((item, i) => (
                            <TableRow
                                key={i}
                                //    css={tableRow}
                            >
                                {headers.map((header) => (
                                    <React.Fragment>
                                        <TableCell key={header}>{item[header]}</TableCell>
                                    </React.Fragment>
                                ))}
                                <React.Fragment>
                                    <TableCell key={i}>
                                        <u
                                            onClick={() => (
                                                props.singleRecordLoading(item._id, 'get')
                                            )}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Edit
                                        </u>{' '}
                                        <u
                                            onClick={() => (
                                                props.singleRecordLoading(item._id, 'delete'))}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Delete
                                        </u>
                                    </TableCell>
                                </React.Fragment>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Modal>
        </div>
    );
};

export default SoftSavedList;
