/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const App = css`
    margin: 80px auto;
    width: 100%;
    background-color: #fff;
    padding: 16px;
    position: relative;
    @media all and (min-width: 520px) {
        padding: 40px;
        margin: 100px auto;
        width: 60vw;
    }
    @media all and (min-width: 520px) and (max-width: 1024px) {
        width: 90vw;
    }
`;

const useStyles = makeStyles((theme) => ({
    link: {
        margin: theme.spacing(1, 1.5)
    },

    root: {
        // height: "80vh",
        width: '100%'
    },
    image: {
        background: 'linear-gradient(to right, #0078ff, #00bfff)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },

    paper: {
        width: 'auto',
        margin: theme.spacing(8, 4)
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    appBar: {
        backgroundColor: '#fff',
        paddingTop: 10,
        paddingLeft: 8
    },
    submit: {
        width: '45%',
        height: 'auto',
        // marginLeft: "100px",
        margin: theme.spacing(2, 0, 0)
    },
    logo: {
        width: '150px',
        height: 'auto',
        marginRight: '10px'
    },
    toolbar: {
        flexWrap: 'wrap'
    },
    toolbarTitle: {
        flexGrow: 1
    },
    brand: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        textDecoration: 'none'
    },
    brandTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 25,
        color: '#1e3056'
    },
    errormsg: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 15,
        color: 'red'
    }
}));

interface LoginFormValues {
    password: string;
    email: string;
}

interface LoginFormProps {
    onLogin: (pin: string, emailid: string) => void;
    isLoading: boolean;
    error: string;
    hasLoginError: boolean;
}

const Welcomepage: React.FC<LoginFormProps> = (props) => {
    const classes = useStyles();

    return (
        <div>
            <Container component="main" maxWidth="sm" css={App}>
                <div className={classes.paper}>
                    {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
                    <Typography component="div" variant="h2" className={classes.brandTitle}>
                        Thank you for registering with FirstMatch&reg;. Configuration is in Progress...
                    </Typography>
                </div>
            </Container>
        </div>
    );
};

export default Welcomepage;
