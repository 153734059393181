import axios from 'axios';
import * as Types from './definitions';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { store } from '../index';
import * as user from '../redux-modules/user';
import { domainPath } from '../App';
import { KeyUpService } from '../Services/KeyUpService';
import FormData from 'form-data';
export const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
export const loginApiUrl = process.env.REACT_APP_LOGIN_API_URL;
export const fileApiUrl = process.env.REACT_APP_STATIC_FILE_URL;


interface PredictionResponse {
    referred_program: string;
    model_program: string;
}

interface LocationsResponse {
    result?: string;
    'Suggested Locations': string[];
}



const refreshAuthLogic = (failedRequest: { response: { config: { headers: { [x: string]: string } } } }) => {
    const userState = store.getState().user;
    const currentUser = userState.user;
    // const refreshToken = localStorage.getItem("refreshToken");
    if (!userState) {
        return Promise.reject();
    }

    return axios
        .post(`${loginApiUrl}/login/refresh/`, {
            refresh: currentUser.refreshToken
        })
        .then((tokenRefreshResponse) => {
            // localStorage.setItem("refresh", tokenRefreshResponse.data.token);
            const accessToken = tokenRefreshResponse.data.access_token;
            store.dispatch(
                user.actions.update({
                    user: {
                        ...currentUser,
                        accessToken
                    }
                })
            );
            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + accessToken;
            return Promise.resolve();
        });
};
// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios as any, refreshAuthLogic);

// Make a call. If it returns a 401 error, the refreshAuthLogic will be run,
// and the request retried with the new token
export const login = async (email: string, password: string, domain: string) => {
    try {
        const response: any = await axios.post(`${loginApiUrl}/organizations/${domain}/login`, {
            username: email,
            password: password
        });

        // localStorage.setItem("refreshToken", response.data&&response.data.response.token);
        // localStorage.setItem("user_role", response.data&&response.data.response.role_type)
        return response.data;
    } catch (error) {
        console.log(error);
        console.error('api function login error');
        throwError(error);
    }
};

export const preLogin = async (email: string) => {
    const formData = new FormData();
    formData.append('username', email);
    try {
        const response: any = await axios.post(`${baseApiUrl}/${domainPath}/pre-login-check`, formData);
        return response.data;
    } catch (error) {
        console.log(error);
        console.error('api function preLogin error');
        throwError(error);
    }
};

export const Logout = async (accessToken) => {
    const currentUser = store.getState().user.user.accessToken;

    try {
        let myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${accessToken ? accessToken : currentUser}`);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders
        };

        await fetch(`${baseApiUrl}/${domainPath}/logout`, requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log('error', error));
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user_role');
    } catch (error) {
        console.error('api function fetchLocationsList error');
        throwError(error);
    }
};
export const updateConfiguration = async (configuration: Types.Configuration) => {
    try {
        const response = await axios.post(`${baseApiUrl}/${domainPath}/dataSave`, configuration);
        return response.data.data;
    } catch (error) {
        console.error('api function updateConfiguration error');
        throwError(error);
    }
};

export const insertClient = async (client: Types.Client) => {
    try {
        const response = await axios.post(`${baseApiUrl}/${domainPath}/list_view/`, client);
        if (response.data['ERROR'] && response.data['ERROR'].trim() !== '') {
            throw new Error(response.data['ERROR']);
        }
        if (response.data['Result'] && response.data['Result'].trim() !== '') {
            return response.data;
        }
        const r = {
            ...response.data,
            program_type: response.data.program_type[0],
            referred_program: response.data.program_type[0],
            model_program: response.data.program_type[0]
        };

        return r as unknown as Partial<Types.Client>;
    } catch (error) {
        if (error.response.status === 403 || error.response.status === 401) {
            throwError(error);
        }
        const data = error.response.data;
        let clientErrors: { [x: string]: any } = {};
        Object.keys(data).map((key) => {
            return (clientErrors[key] = data[key][0]);
        });
        console.error('api function insertClient error');
        throw clientErrors;
    }
};

export const updateClient = async (client: Types.Client) => {
    try {
        const response = await axios.put(`${baseApiUrl}/${domainPath}/latest_update/${client.client_code}/`, client);
        if (response.data['ERROR'] && response.data['ERROR'].trim() !== '') {
            throw new Error(response.data['ERROR']);
        }
        if (response.data['Result'] && response.data['Result'].trim() !== '') {
            return response.data;
        }
        const r = {
            ...response.data,
            program_type: response.data.program_type[0],
            referred_program: response.data.program_type[0],
            model_program: response.data.program_type[0]
        };

        return r as unknown as Partial<Types.Client>;
    } catch (error) {
        if (error.response.status === 403 || error.response.status === 401) {
            throwError(error);
        }
        const data = error.response.data;
        let clientErrors: { [x: string]: any } = {};
        Object.keys(data).map((key) => {
            return (clientErrors[key] = data[key][0]);
        });
        console.error('api function updateClient error');
        throw clientErrors;
    }
};

export const updateFamily = async (client: Types.DynamicFamily) => {
    try {
        const response = await axios.put(`${baseApiUrl}/${domainPath}/latest_update/${client.client_code}/`, client);
        if (response.data['ERROR'] && response.data['ERROR'].trim() !== '') {
            throw new Error(response.data['ERROR']);
        }
        if (response.data['Result'] && response.data['Result'].trim() !== '') {
            return response.data;
        }
        const r = {
            ...response.data,
            program_type: response.data.program_type[0],
            referred_program: response.data.program_type[0],
            model_program: response.data.program_type[0]
        };

        return r as unknown as Partial<Types.Client>;
    } catch (error) {
        if (error.response.status === 403 || error.response.status === 401) {
            throwError(error);
        }
        const data = error.response.data;
        let clientErrors: { [x: string]: any } = {};
        Object.keys(data).map((key) => {
            return (clientErrors[key] = data[key][0]);
        });
        console.error('api function updateClient error');
        throw clientErrors;
    }
};

export const uploadcsvfile = async (data, is_accessToken, Config, subConfig) => {
    data['config_type'] = Config;
    data['sub_config_type'] = subConfig;
    try {
        return await axios
            .post(`${baseApiUrl}/${domainPath}/clients`, data, {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            })
            .then((response) => {
                return response.data;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const sendTicket = async (data, email_id: string = '') => {
    if (email_id) {
        try {
            return await axios.post(`${baseApiUrl}/tickets`, data).then((response) => {
                return response.data;
            });
        } catch (error) {
            console.log('error');

            throwError(error);
        }
    } else {
        try {
            return await axios
                .post(`${baseApiUrl}/${domainPath}/tickets`, data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.refreshToken}`
                    }
                })
                .then((response) => {
                    return response.data;
                });
        } catch (error) {
            console.log('error');

            throwError(error);
        }
    }
};

export const downloadKidReport = async ( clientCode: string, is_accessToken: string, Config: string, subConfig: string, is_for_scores: boolean, callBack: (result:any)=>void) => {
    let tokenAvailable = is_accessToken ? is_accessToken + `/?is_for_scores=${is_for_scores}` : "" ;
    try {
         return await axios.get(
          `${baseApiUrl}/${domainPath}/index/${clientCode}/${tokenAvailable}&config_type=${Config}&sub_config_type=${subConfig}`
        )
   
    .then(async (response) => {
        
        if (response?.data?.status === 'success') {
            const path = response.data.response;
            window.open(`${fileApiUrl}/${path}`);
            callBack(response.data);
        } else {
            callBack(response.data);
        }
    });
        
      } catch (error) {
        callBack(error);
        console.error('Error downloading the file', error);
      }
   
};

export const downloadcsvfile = async ( config_type: string, sub_config_type: string, is_accessToken: string) => {
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/client-config?download&config_type=${config_type}&sub_config_type=${sub_config_type}`, {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            })
            .then((response) => {
                const path = response.data.response.csv;
                const pdfPath = response.data.response.pdf;
                window.open(`${fileApiUrl}/${path}`);
                window.open(`${fileApiUrl}/${pdfPath}`);
                return response.data;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const downloadDataReport = async (is_accessToken, config_type) => {
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/client-config?download=dump&config_type=${config_type}`, {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            })
            .then((response) => {
                const path = response.data.response;
                window.open(`${fileApiUrl}/${path}`);
                return response.data;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const downloadDataDumpReport = async (data, is_accessToken, config_type, sub_config_type) => {
    let payLoad = { filter_config: data, config_type: config_type, sub_config_type: sub_config_type };
    try {
        return await axios
            .post(`${baseApiUrl}/${domainPath}/data-report`, payLoad, {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            })
            .then((response) => {
                const path = response?.data?.response;
               
                if(response.data.status === 'success'){
                    window.open(`${fileApiUrl}/${path}`);
                 } 
               return response.data;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};
export const getModifyHardSave = async (is_accessToken, selectedVersion_id, config_type, sub_config_type) => {
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/download-comments?_id=${selectedVersion_id}&config_type=${config_type}&sub_config_type=${sub_config_type}`, {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            })
            .then((response) => {
                if (response.data.status === 'success') {
                    const path = response.data.response;
                    window.open(`${fileApiUrl}/${path}`);
                }
                return response.data;
            });
    } catch (error) {
        console.log('error');
        throwError(error);
    }
};

export const insertDClient = async (client_form, Config, subConfig, action, is_accessToken, isPredictionScores?: boolean) => {
    let methodUpdate = client_form._id === '' ? false : true;
    if(client_form.hasOwnProperty('temp_id')){
        client_form._id =client_form.temp_id;
        delete client_form.temp_id;
    }
    let data = new FormData();
    if (client_form['Reason for modification']) {
        data.append('comments', JSON.stringify(client_form['Reason for modification']));
        delete client_form['Reason for modification'];
    }

    let myJSON = JSON.stringify(client_form);
    data.append('config_type', Config);
    data.append('sub_config_type', subConfig);
    data.append('client_form', myJSON);
    if (action) {
        !isPredictionScores && data.append('action', action);
    }

    try {
        if (isPredictionScores) {
            var response = await axios.post(`${baseApiUrl}/${domainPath}/prediction-scores`, data, {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            });
        } else {
            response = methodUpdate
                ? await axios.put(`${baseApiUrl}/${domainPath}/clients`, data, {
                      headers: {
                          Authorization: `Bearer ${is_accessToken}`
                      }
                  })
                : await axios.post(`${baseApiUrl}/${domainPath}/clients`, data, {
                      headers: {
                          Authorization: `Bearer ${is_accessToken}`
                      }
                  });
        }
        const r = {
            ...response,
            program_type: response.data.response.program_type && response.data.response.program_type[0],
            referred_program: response.data.response.program_type && response.data.response.program_type[0],
            model_program: response.data.response.program_type && response.data.response.program_type[0]
        };

        return r as unknown as Partial<Types.DynamicClient>;
    } catch (error) {
        if (error.response.status === 403 || error.response.status === 401) {
            throwError(error);
        }
        const data = error.response ? error.response : '';
        let clientErrors: { [x: string]: any } = {};
        Object.keys(data).map((key) => {
            return (clientErrors[key] = data[key][0]);
        });
        console.error('api function insertClient error');
        throw clientErrors;
    }
};

export const softSaveRecord = async (client_form, config_type, sub_config_type, is_accessToken) => {
    let data = new FormData();
    let myJSON = JSON.stringify(client_form);
    data.append('config_type', config_type);
    data.append('sub_config_type', sub_config_type);
    data.append('temp_data', myJSON);

    try {
        let response = await axios.post(`${baseApiUrl}/${domainPath}/temp-data`, data, {
            headers: {
                Authorization: `Bearer ${is_accessToken}`
            }
        });
        const r = {
            ...response
        };

        return r as unknown as Partial<Types.DynamicClient>;
    } catch (error) {
        if (error.response.status === 403 || error.response.status === 401) {
            throwError(error);
        }
        const data = error.response ? error.response : '';
        let clientErrors: { [x: string]: any } = {};
        Object.keys(data).map((key) => {
            return (clientErrors[key] = data[key][0]);
        });
        console.error('api function insertClient error');
        throw clientErrors;
    }
};

export const getSoftSaveList = async (configType, subConfigType, is_accessToken) => {
    try {
        let response = await axios.get(`${baseApiUrl}/${domainPath}/temp-data?config_type=${configType}&sub_config_type=${subConfigType}`, {
            headers: {
                Authorization: `Bearer ${is_accessToken}`
            }
        });

        return response.data;
    } catch (error) {
        if (error.response.status === 403 || error.response.status === 401) {
            throwError(error);
        }
        const data = error.response ? error.response : '';
        let clientErrors: { [x: string]: any } = {};
        Object.keys(data).map((key) => {
            return (clientErrors[key] = data[key][0]);
        });
        console.error('api function insertClient error');
        throw clientErrors;
    }
};

export const getSingleSavedRecord = async (configType, subConfigType,is_accessToken, id, methodType) => {
    try {
        let response = await axios[methodType](`${baseApiUrl}/${domainPath}/temp-data?config_type=${configType}&sub_config_type=${subConfigType}&_id=${id}`, {
            headers: {
                Authorization: `Bearer ${is_accessToken}`
            }
        });

        return response.data;
    } catch (error) {
        if (error.response.status === 403 || error.response.status === 401) {
            throwError(error);
        }
        const data = error.response ? error.response : '';
        let clientErrors: { [x: string]: any } = {};
        Object.keys(data).map((key) => {
            return (clientErrors[key] = data[key][0]);
        });
        console.error('api function insertClient error');
        throw clientErrors;
    }
};

export const updateDClient = async (client: Types.Client) => {
    try {
        const response = await axios.put(`${baseApiUrl}/${domainPath}/latest_update/${client.client_code}/`, client);
        if (response.data['ERROR'] && response.data['ERROR'].trim() !== '') {
            throw new Error(response.data['ERROR']);
        }
        if (response.data['Result'] && response.data['Result'].trim() !== '') {
            return response.data;
        }

        const r = {
            ...response.data,
            program_type: response.data.program_type[0],
            referred_program: response.data.program_type[0],
            model_program: response.data.program_type[0]
        };

        return r as unknown as Partial<Types.Client>;
    } catch (error) {
        if (error.response.status === 403 || error.response.status === 401) {
            throwError(error);
        }
        const data = error.response.data;
        let clientErrors: { [x: string]: any } = {};
        Object.keys(data).map((key) => {
            return (clientErrors[key] = data[key][0]);
        });
        console.error('api function updateClient error');
        throw clientErrors;
    }
};

export const insertDFamily = async (client_form, Config, subConfig, action, is_accessToken, isPredictionScores?: boolean) => {
    let methodUpdate = client_form._id === '' ? false : true;
    if(client_form.hasOwnProperty('temp_id')){
        client_form._id =client_form.temp_id;
        delete client_form.temp_id;
    }
    let data = new FormData();
    if (client_form['Reason for modification']) {
        data.append('comments', JSON.stringify(client_form['Reason for modification']));
        delete client_form['Reason for modification'];
    }

    let myJSON = JSON.stringify(client_form);
    data.append('client_form', myJSON);
    data.append('config_type', Config);
    data.append('sub_config_type', subConfig);
    if (action) {
        !isPredictionScores && data.append('action', action);
    }

    try {
        if (isPredictionScores) {
            var response = await axios.post(`${baseApiUrl}/${domainPath}/prediction-scores`, data, {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            });
        } else {
            response = methodUpdate
                ? await axios.put(`${baseApiUrl}/${domainPath}/clients`, data, {
                      headers: {
                          Authorization: `Bearer ${is_accessToken}`
                      }
                  })
                : await axios.post(`${baseApiUrl}/${domainPath}/clients`, data, {
                      headers: {
                          Authorization: `Bearer ${is_accessToken}`
                      }
                  });
        }
        const r = {
            ...response,
            program_type: response.data.response.program_type && response.data.response.program_type[0],
            referred_program: response.data.response.program_type && response.data.response.program_type[0],
            model_program: response.data.response.program_type && response.data.response.program_type[0]
        };

        return r as unknown as Partial<Types.DynamicClient>;
    } catch (error) {
        if (error.response.status === 403 || error.response.status === 401) {
            throwError(error);
        }
        const data = error.response ? error.response : '';
        let clientErrors: { [x: string]: any } = {};
        Object.keys(data).map((key) => {
            return (clientErrors[key] = data[key][0]);
        });
        console.error('api function insertClient error');
        throw clientErrors;
    }
};

export const fetchConfiguredQuestions = async ( configType, subConfigType, is_accessToken) => {
    try {
        const response = await axios.get(
            `${baseApiUrl}/${domainPath}/client-config?config_type=${configType}&sub_config_type=${subConfigType}&form_for=new`,
            {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            }
        );
        const data = response.data;
        return removeHiddenObject(data.response);
    } catch (error) {
        return [];
        console.error('api function fetchConfiguredQuestions error');
        throwError(error);
    }
};

const removeHiddenObject = (array) => {
    array.forEach((section) => {
        section.questions = section.questions.filter((ques) => ques.is_for_hiding !== 'yes');
    });

    return array;
};

const removeHiddenObjectFromMultiple = (array) => {
    array.forEach((item) => removeHiddenObject(item.sections));
    return array;
};

export const insertPrediction = async (client: Types.Client) => {
    if (!client.client_code) {
        throw new Error('client code required');
    }
    try {
        const response = await axios.put(`${baseApiUrl}/${domainPath}/refer/${client.client_code}/`, {
            referred_program: client.program_type
        });

        return response;
    } catch (error) {
        console.error('api function insertPrediction error');
        throwError(error);
    }
};

export const assignSelectedFamilies = async (id_n: string, version: string, data: any, is_accessToken: string, uniqueField: string) => {
    try {
        const response = await axios.put(
            `${baseApiUrl}/${domainPath}/fml-to-youth`,
            {
                version: version,
                families: data,
                [uniqueField]: id_n
            },
            {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            }
        );

        return response;
    } catch (error) {
        console.error('api function insertPrediction error');
        throwError(error);
    }
};

export const fetchUsers = async () => {
    try {
        const response = await axios.get(`${loginApiUrl}/organizations/${domainPath}/users/`);
        const data = response.data as unknown as Types.Users[];

        return data;
    } catch (error) {
        console.error('api function fetchUsers error');
        throwError(error);
    }
};
export const fetchRoles = async (is_accessToken: any) => {
    try {
        const response = await axios.get(`${loginApiUrl}/organizations/${domainPath}/groups/`, {
            headers: {
                Authorization: `Bearer ${is_accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('api function fetchRoles error');
        throwError(error);
    }
};

export const fetchAvailableUsers = async (userID: any) => {
    const currentUser = store.getState().user.user.accessToken;
    try {
        const response = await axios.get(`${loginApiUrl}/organizations/${domainPath}/users/${userID}/`, {
            headers: {
                Authorization: `Bearer ${currentUser}`
            }
        });
        return response.data.response;
    } catch (error) {
        console.error('api function fetchAvailableUsers error');
        throwError(error);
    }
};

export const createUsers = async (users: Types.Users, is_accessToken: any) => {
    const data = {
        first_name:
            users.first_name !== null ? users.first_name.charAt(0).toUpperCase() + users.first_name.substr(1) : '',
        last_name: users.last_name !== null ? users.last_name.charAt(0).toUpperCase() + users.last_name.substr(1) : '',
        email_id: users.email_id,
        gender: users.gender,
        mobile: users.mobile,
        group_id: users.role_type
    };
    try {
        const response = await axios.post(`${loginApiUrl}/organizations/${domainPath}/users/`, data, {
            headers: {
                Authorization: `Bearer ${is_accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('api function createUsers error');
        throwError(error);
    }
};

export const updateUsers = async (users: Types.Users, is_accessToken: any) => {
    try {
        const response = await axios.put(
            `${loginApiUrl}/organizations/${domainPath}/users/${users.id}/`,
            {
                first_name:
                    users.first_name !== null
                        ? users.first_name.charAt(0).toUpperCase() + users.first_name.substr(1)
                        : '',
                last_name:
                    users.last_name !== null ? users.last_name.charAt(0).toUpperCase() + users.last_name.substr(1) : '',
                email_id: users.email_id,
                mobile: users.mobile,
                gender: users.gender,
                group_id: users.role_type
            },
            {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('api function updateUsers error');
        throwError(error);
    }
};

export const deleteUsers = async (users: any, is_accessToken: any) => {
    let u_id = users.id;
    let email = users.adminEmail && `?email_id=${users.adminEmail}`;
    try {
        const response = await axios.delete(`${loginApiUrl}/organizations/${domainPath}/users/${u_id}/${email}`, {
            headers: {
                Authorization: `Bearer ${is_accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('api function deleteUser error');
        throwError(error);
    }
};

export const fetchBillingStatus = async (accessToken) => {
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/billing-status/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const fetchAllRecords = async (sDate, EDate, accessToken) => {
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/orders/?start_date=${sDate}&end_date=${EDate}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const getRecord = async (id, accessToken) => {
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/orders/${id}/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const downloadRecords = async (accessToken) => {
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/download/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                const path = response.data.response;
                window.open(`${fileApiUrl}/${path}`);
                return response.data;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const getOrderDownload = async (sDate, eDate, accessToken) => {
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/download/?start_date=${sDate}&end_date=${eDate}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                const path = response.data.response;
                window.open(`${fileApiUrl}/${path}`);
                return response.data;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const downloadReportCSV = async (id, accessToken) => {
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/download/${id}/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                const path = response.data.response;
                window.open(`${fileApiUrl}/${path}`);
                return response.data;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const fetchNotificationsList = async (accessToken: string) => {
    const { Config, subConfig } = KeyUpService.getConfSubConf(window.location.pathname);
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/d-notifications?config_type=${Config}&sub_config_type=${subConfig}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const fetchNotificationDetails = async (accessToken: string, notificationId) => {
    const { Config, subConfig } = KeyUpService.getConfSubConf(window.location.pathname);
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/d-notifications/?id=${notificationId}&config_type=${Config}&sub_config_type=${subConfig}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const fetchNotificationsData = async (accessToken: string, notificationId) => {
    const { Config, subConfig } = KeyUpService.getConfSubConf(window.location.pathname);
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/process-notification?id=${notificationId}&config_type=${Config}&sub_config_type=${subConfig}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const getDateRangeDynamicNotifications = async (
    is_accessToken: any,
    start_date: any = '',
    end_date: any = '',
    notificationId: any
) => {
    const { Config, subConfig } = KeyUpService.getConfSubConf(window.location.pathname);
    let daterange = '';
    if (start_date) {
        daterange = `&start_date=${start_date}&end_date=${end_date}`;
    }
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/process-notification?id=${notificationId}${daterange}&config_type=${Config}&sub_config_type=${subConfig}`, {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            })
            .then((response) => {
                return response.data.response;
            });
    } catch (error) {
        console.log('error');
        throwError(error);
    }
};
export const downloadDynamicNotifications = async (
    start_date: any = '',
    end_date: any = '',
    is_accessToken: any,
    notificationId: any
) => {
    const { Config, subConfig } = KeyUpService.getConfSubConf(window.location.pathname);
    let daterange = '';
    if (start_date) {
        daterange = `&start_date=${start_date}&end_date=${end_date}`;
    }
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/process-notification?id=${notificationId}${daterange}&download&config_type=${Config}&sub_config_type=${subConfig}`, {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            })
            .then((response) => {
                const path = response.data.response.csv;
                window.open(`${fileApiUrl}/${path}`);
                return response.data.response;
            });
    } catch (error) {
        console.log('error');
        throwError(error);
    }
};

export const fetchPendingNotifications = async (
    accessToken: string,
    type: string,
    start_date: any = '',
    end_date: any = ''
) => {
    let daterange = '';
    if (start_date) {
        daterange = `?start_date=${start_date}&end_date=${end_date}`;
    }
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/notifications/${type}${daterange}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.response;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};
export const fetchPCRNotifications = async (
    accessToken: string,
    type: string,
    start_date: any = '',
    end_date: any = ''
) => {
    let daterange = '';
    if (start_date) {
        daterange = `?start_date=${start_date}&end_date=${end_date}`;
    }
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/notifications/${type}${daterange}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.response;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const fetchAllNotifications = async (
    accessToken: string,
    type: string,
    filter: any,
    start_date: any = '',
    end_date: any = ''
) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(filter)) {
        if (value) {
            queryString = queryString + `${key}=${value}&`;
        }
    }
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/notifications/${type}?${queryString}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.response;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const Download_Notifications = async (
    type: string,
    start_date: any = '',
    end_date: any = '',
    is_accessToken: any,
    filter?: any
) => {
 
    let daterange = '';
    if (start_date) {
        daterange = `&start_date=${start_date}&end_date=${end_date}`;
    }
    let queryString: any = '';
    if (filter) {
        for (const [key, value] of Object.entries(filter)) {
            if (value) {
                queryString = `${key}=${value}&`;
            }
        }
    }
    //`${baseApiUrl}/${domainPath}/notifications/${type}?download${daterange}&out_come=residential&`
    try {
        return await axios
            .get(`${baseApiUrl}/${domainPath}/notifications/${type}?download${daterange}&${queryString}`, {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            })
            .then((response) => {
                const path = response.data.response.csv;
                window.open(`${fileApiUrl}/${path}`);
                return response.data.response;
            });
    } catch (error) {
        console.log('error');

        throwError(error);
    }
};

export const fetchReferral = async () => {
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/referral_list`);
        const data = response.data as unknown as Types.Referral[];

        return data;
    } catch (error) {
        console.error('api function fetchReferral error');
        throwError(error);
    }
};

export const fetchDReferral = async (accessToken) => {
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/clients/referralsources`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        const data = response.data.response as unknown as any;

        return data;
    } catch (error) {
        console.error('api function fetchDReferral error');
        throwError(error);
    }
};

export const fetchAvailableReferral = async () => {
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/referral_list`);
        const data = response.data as unknown as Types.Referral[];

        return data;
    } catch (error) {
        console.error('api function fetchAvailableReferral error');
        throwError(error);
    }
};

export const createReferral = async (referral: Types.Referral) => {
    try {
        const response = await axios.post(`${baseApiUrl}/${domainPath}/referral_save`, {
            referral_name: referral.referral_name
        });
        return response.data;
    } catch (error) {
        console.error('api function createReferral error');
        throwError(error);
    }
};

export const updateReferral = async (referral: Types.Referral) => {
    try {
        const response = await axios.put(`${baseApiUrl}/${domainPath}/referral_modify/${referral.referral_code}/`, {
            referral_name: referral.referral_name
        });
        return response.data;
    } catch (error) {
        console.error('api function updateReferral error');
        throwError(error);
    }
};

export const deleteReferral = async (referral: Types.Referral) => {
    try {
        const response = await axios.delete(`${baseApiUrl}/${domainPath}/referral_modify/${referral.referral_code}/`, {
            //referral_name: referral.referral_name
        });
        return response.data;
    } catch (error) {
        console.error('api function deleteReferral error');
        throwError(error);
    }
};

export const fetchPrograms = async () => {
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/program_list`);

        const data = response.data as unknown as Types.Program[];

        return data;
    } catch (error) {
        console.error('api function fetchPrograms error');
        throwError(error);
    }
};

export const fetchAvailablePrograms = async () => {
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/available_programs`);

        const data = response.data as unknown as Types.Program[];

        return data;
    } catch (error) {
        console.error('api function fetchAvailablePrograms error');
        throwError(error);
    }
};

export const createProgram = async (program: Types.Program) => {
    try {
        const response = await axios.post(`${baseApiUrl}/${domainPath}/program_save`, {
            program_name: program.program_name
        });
        return response.data;
    } catch (error) {
        console.error('api function createProgram error');
        throwError(error);
    }
};

export const updateProgram = async (program: Types.Program) => {
    try {
        const response = await axios.put(`${baseApiUrl}/${domainPath}/programs/${program.program}/`, {
            program_name: program.program_name
        });
        return response.data;
    } catch (error) {
        console.error('api function updateProgram error');
        throwError(error);
    }
};

export const deleteProgram = async (program: Types.Program) => {
    try {
        const response = await axios.delete(`${baseApiUrl}/${domainPath}/programs/${program.program}/`, {
            //program_name: program.program_name
        });
        return response.data;
    } catch (error) {
        console.error('api function deleteProgram error');
        throwError(error);
    }
};

export const fetchLocationsList = async () => {
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/location_list`);
        const data = response.data as unknown as Types.Location[];

        return data;
    } catch (error) {
        console.error('api function fetchLocationsList error');
        throwError(error);
    }
};

export const createLocation = async (location: Types.Location) => {
    try {
        const response = await axios.post(`${baseApiUrl}/${domainPath}/location_save`, {
            location_name: location.location_names
        });
        return response.data;
    } catch (error) {
        console.error('api function createLocation error');
        throwError(error);
    }
};

export const updateLocation = async (location: Types.Location) => {
    try {
        const response = await axios.put(`${baseApiUrl}/${domainPath}/locations/${location.location}/`, {
            location_names: location.location_names
        });
        return response.data;
    } catch (error) {
        console.error('api function updateLocation error');
        throwError(error);
    }
};

export const deleteLocation = async (location: Types.Location) => {
    try {
        const response = await axios.delete(`${baseApiUrl}/${domainPath}/locations/${location.location}/`, {
            //location_names: location.location_names
        });
        return response.data;
    } catch (error) {
        console.error('api function deleteLocation error');
        throwError(error);
    }
};

export const fetchLocations = async (client_code: string, referred_program: string, is_accessToken: any) => {
    try {
        const response = await axios.get(
            `${baseApiUrl}/${domainPath}/location/${client_code}/?referred_program=${referred_program}`,
            {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            }
        );
        const data = response.data as unknown as LocationsResponse;
        return data;
        // return data["Suggested Locations"];
    } catch (error) {
        console.error('api function fetchLocations error');
        throwError(error);
    }
};

export const fetchAnalytics = async (accessToken: any) => {
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/referrals?days_count=30`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchAnalytics error');
        throwError(error);
    }
};

export const fetchDateAnalytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/referrals?${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchAnalytics error');
        throwError(error);
    }
};

export const fetchPCRAnalytics = async (filter: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(filter)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/pcr?${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchprogramAnalytics error');
        throwError(error);
    }
};
export const fetchROCAnalytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/roc?${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchprogramAnalytics error');
        throwError(error);
    }
};

export const fetchReplacementAnalytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/replacement?${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchprogramAnalytics error');
        throwError(error);
    }
};

export const fetchStayAnalytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/stay?${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchprogramAnalytics error');
        throwError(error);
    }
};
export const fetchOccupancyAnalytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/occupancy?${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchprogramAnalytics error');
        throwError(error);
    }
};

export const fetchAllocationAnalytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/statistics?${queryString}&q=all`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchprogramAnalytics error');
        throwError(error);
    }
};

export const fetchAllocated_ProgramAnalytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/statistics?q=programs&${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchprogramAnalytics error');
        throwError(error);
    }
};

export const fetch_Market_Analytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/market?${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetch_Market_Analytics error');
        throwError(error);
    }
};

export const fetch_Performance_Analytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/performance?${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchPerformance_Analytics error');
        throwError(error);
    }
};
export const fetch_ROC_Calibration_Analytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(
            `${baseApiUrl}/${domainPath}/analytics/calibration?q=graph&${queryString}&q1=roc`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        );
        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchAge_Analytics error');
        throwError(error);
    }
};
export const fetch_PCR_Calibration_Analytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(
            `${baseApiUrl}/${domainPath}/analytics/calibration?q=graph&${queryString}&q1=pcr`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        );
        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchAge_Analytics error');
        throwError(error);
    }
};
export const fetch_Gender_Analytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/demographics?q=sex&${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchGender_Analytics error');
        throwError(error);
    }
};
export const fetch_Age_Analytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/demographics?q=age&${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchAge_Analytics error');
        throwError(error);
    }
};
export const fetch_Demo_Analytics = async (analytics: any, accessToken: any) => {
    let queryString: any = '';
    for (const [key, value] of Object.entries(analytics)) {
        if (value) {
            queryString = `${key}=${value}&` + queryString;
        }
    }

    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/analytics/demographics?${queryString}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        const data = response.data.response as unknown as Types.Analytics[];

        return data;
    } catch (error) {
        console.error('api function fetchAge_Analytics error');
        throwError(error);
    }
};
export const fetchPcr = async (client_code: string, referred_program: string, currentUser: any) => {
    try {
        const response = await axios.put(
            `${baseApiUrl}/${domainPath}/program_pcr/${client_code}/`,
            { client_selected_program: referred_program },
            {
                headers: {
                    Authorization: `Bearer ${currentUser}`
                }
            }
        );

        const data = response.data as unknown as any;
        return data;
    } catch (error) {
        console.error('api function fetch program_pcr error');
        throwError(error);
    }
};

export const fetchPredictions = async (
    client_code: string,
    referred_program: string,
    currentUser: any,
    selected_name: string,
    is_prediction_screen: boolean,
    isPredictionScores: boolean,
    Config: string,
    subConfig: string,
) => {
    try {
        const response = await axios.get(
            `${baseApiUrl}/${domainPath}/predictions/${client_code}?${selected_name}=${referred_program}&is_for_scores=${isPredictionScores}&config_type=${Config}&sub_config_type=${subConfig}`,
            {
                headers: {
                    Authorization: `Bearer ${currentUser}`
                }
            }
        );

        const data = response.data as unknown as any;
        return data;
    } catch (error) {
        console.error('api function fetch program_pcr error');
        throwError(error);
    }
};

export const fetchProgramAndLikelihood = async (client_code: string, referred_program: string, Config: string, subConfig: string, currentUser: any) => {
    const formData = new FormData();
    formData.append('Program', referred_program);
    try {
        const response = await axios.put(`${baseApiUrl}/${domainPath}/program_pcr/${client_code}?config_type=${Config}&sub_config_type=${subConfig}`, formData, {
            headers: {
                Authorization: `Bearer ${currentUser}`
            }
        });

        const data = response.data as unknown as any;
        return data;
    } catch (error) {
        console.error('api function fetch program_pcr error');
        throwError(error);
    }
};

export const saveProgramAndLikelihood = async (
    client_code: string,
    selected_program: string,
    selected_location: string,
    pcr_score: any,
    roc_score: any,
    is_accessToken: any,
    isPredictionScores?: boolean
) => {
    const data = {};
    data['Program'] = selected_program;
    if (selected_location) {
        data['Supervision Level'] = selected_location;
    }

    try {
        const response = await axios.put(
            `${baseApiUrl}/${domainPath}/update_list/${client_code}/`,
            {
                ...data,
                is_for_scores: isPredictionScores?.toString()
            },
            {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('api function saveLocationAndProgram error');
        throwError(error);
    }
};

export const saveLocationAndProgram = async (
    client_code: string,
    selected_program: string,
    selected_location: string,
    pcr_score: any,
    roc_score: any,
    is_accessToken: any,
    isPredictionScores?: boolean
) => {
    const data = {
        client_selected_program: selected_program,
        client_selected_locations: selected_location,
        pcr_score: pcr_score,
        roc_score: roc_score
    };

    try {
        const response = await axios.put(
            `${baseApiUrl}/${domainPath}/update_list/${client_code}/`,
            {
                ...data,
                is_for_scores: isPredictionScores?.toString()
            },
            {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('api function saveLocationAndProgram error');
        throwError(error);
    }
};

export const savePredictionsToCLient = async (
    client_code: string,
    Config: string,
    subConfig: string,
    data: any,
    is_accessToken: string,
    isPredictionScores?: boolean
) => {
    try {
        data['config_type'] = Config;
        data['sub_config_type'] = subConfig;
        const response = await axios.put(
            `${baseApiUrl}/${domainPath}/predictions/${client_code}`,
            {
                ...data,
                is_for_scores: isPredictionScores?.toString()
            },
            {
                headers: {
                    Authorization: `Bearer ${is_accessToken}`
                }
            }
        );

        return response?.data;
    } catch (error) {
        console.error('api function saveLocationAndProgram error');
        throwError(error);
    }
};

export const updateProgramCompletion = async (
    client_code: string,
    Program_Completion: number | null,
    Returned_to_Care: number | null,
    Remained_Out_of_Care: number | null,
    program_significantly_modified: number,
    program: any,
    location: any,
    start_date: any,
    end_date: any,
    referral_status: any,
    confidence: any,
    roc_confidence: any,
    currentUser: any,
    db_row_id: string | null,
    comment: string | null
) => {
    try {
        let data = {
            Program_Completion: Program_Completion,
            Returned_to_Care: Returned_to_Care,
            'Remained Out of Care': Remained_Out_of_Care,
            program_significantly_modified: program_significantly_modified,
            program: program,
            location: location,
            start_date: start_date,
            end_date: end_date,
            referral_status: referral_status,
            pcr_score: confidence,
            roc_score: roc_confidence
        };
        if (db_row_id) {
            data['action'] = 'modify_ability';
            data['_id'] = db_row_id;
            data['comments'] = comment;
        }
        const response = await axios.put(`${baseApiUrl}/${domainPath}/program_complete/${client_code}/`, data, {
            headers: {
                Authorization: `Bearer ${currentUser}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('api function updateProgramCompletion error');
        throwError(error);
    }
};

export const updateProgramCompletion1 = async (
    client_code: string,
    Program_Completion: number | null,
    Remained_Out_of_Care: number | null,
    program_significantly_modified: number,
    client_psychiatrically_hospitalized: number,
    program: any,
    discharge_location: any,
    start_date: any,
    end_date: any,
    referral_status: any,
    length_of_stay: any,
    Reason_not_accepted: string | null,
    Reason_for_rejected: string | null,
    client_recidivate: string | null,
    currentUser: any,
    db_row_id: string | null,
    comment: string | null
) => {
    try {
        const data = {
            Program_Completion: Program_Completion,
            Remained_Out_of_Care: Remained_Out_of_Care,
            program_significantly_modified: program_significantly_modified,
            client_psychiatrically_hospitalized: client_psychiatrically_hospitalized,
            Program: program,
            discharge_location: discharge_location,
            start_date: start_date,
            end_date: end_date,
            referral_status: referral_status,
            length_of_stay: length_of_stay,
            Reason_not_accepted: Reason_not_accepted,
            Reason_for_rejected: Reason_for_rejected,
            client_recidivate: client_recidivate
        };
        if (db_row_id) {
            data['action'] = 'modify_ability';
            data['_id'] = db_row_id;
            data['comments'] = comment;
        }
        let final_data: any = '';
        for (const [key, value] of Object.entries(data)) {
            if (value || value === 0) {
                final_data = { ...final_data, [key]: value };
            }
        }
        const response = await axios.put(`${baseApiUrl}/${domainPath}/program_complete/${client_code}/`, final_data, {
            headers: {
                Authorization: `Bearer ${currentUser}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('api function updateProgramCompletion1 error');
        throwError(error);
    }
};

export const updateProgramCompletion2 = async (data: Types.DynamicClient, client_code: string, config: string, subConfig: string, currentUser: any) => {
    try {
        if (data.hasOwnProperty('comments')) {
            data['action'] = 'modify_ability';
        }
        data['config_type'] = config;
        data['sub_config_type'] = subConfig;
        let final_data: any = '';
        for (const [key, value] of Object.entries(data)) {
            if (value || value === 0) {
                final_data = { ...final_data, [key]: value };
            }
        }
        const response = await axios.put(`${baseApiUrl}/${domainPath}/program_complete/${client_code}/`, final_data, {
            headers: {
                Authorization: `Bearer ${currentUser}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('api function updateProgramCompletion2 error');
        throwError(error);
    }
};

export const searchClient = async (client_code: string, client_name: string = '') => {
    try {
        const response = await axios.get(
            `${baseApiUrl}/${domainPath}/search/?name=${client_name}&client_code=${client_code}`
        );

        return response.data;
    } catch (error) {
        console.error('api function searchClient error');
        throwError(error);
    }
};

export const searchDClient = async (
    client_code: string,
    ssn: string = '',
    first_name: string = '',
    last_name: string = '',
    dob: string = '',
    is_accessToken: any,
    id_n: string
) => {
    try {
        let client_code1 = client_code ? client_code : '';
        let id_n1 = id_n ? id_n : '';
        let config: any = {
            method: 'get',
            url: `${baseApiUrl}/${domainPath}/clients?client_code=${client_code1}&ssn=${ssn}&first_name=${first_name}&last_name=${last_name}&dob=${dob}&id_n=${id_n1}`,
            headers: {
                Authorization: `Bearer ${is_accessToken}`
            }
        };
        const response = await axios(config);

        return response.data;
    } catch (error) {
        console.error('api function searchClient error');
        throwError(error);
    }
};

export const searchDFamily = async (
    client_code: string,
    ssn: string = '',
    first_name: string = '',
    last_name: string = '',
    dob: string = '',
    is_accessToken: any,
    id_n: string
) => {
    try {
        let client_code1 = client_code ? client_code : '';
        let id_n1 = id_n ? id_n : '';
        let config: any = {
            method: 'get',
            url: `${baseApiUrl}/${domainPath}/clients?config_type=2&client_code=${client_code1}&ssn=${ssn}&first_name=${first_name}&last_name=${last_name}&dob=${dob}&id_n=${id_n1}`,
            headers: {
                Authorization: `Bearer ${is_accessToken}`
            }
        };
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error('api function searchClient error');
        throwError(error);
    }
};

export const dynamicSearchFamily = async (data: any, is_accessToken: string, is_uid_existence?: boolean) => {
    try {
        let searchQueary = '';
        Object.keys(data).map((item, index) => {
            if (data[item]?.toString()) searchQueary += `${item}=${data[item]}&`;
        });
        let uid_existence = '';
        if(is_uid_existence) {
            uid_existence = 'yes';
        } else {
            uid_existence =  'no';
        }
        const { Config, subConfig } = KeyUpService.getConfSubConf(window.location.pathname);
        let config: any = {
            method: 'get',
            url: `${baseApiUrl}/${domainPath}/search?${searchQueary}is_uid_existence=${uid_existence}&config_type=${Config}&sub_config_type=${subConfig}`,
            headers: {
                Authorization: `Bearer ${is_accessToken}`
            }
        };
        const response = await axios(config);
        let finalResponse = response.data;
        if (response.data && response.data.response) {
            finalResponse = removeHiddenObjectFromMultiple(response.data?.response);
        }
        return finalResponse;
    } catch (error) {
        console.error('api function searchFamily error');
        throwError(error);
    }
};

export const dynamicSearchDetails = async (data: any, is_accessToken: string, Config: string, subConfig: string, is_uid_existence?: boolean) => {
    try {
        let searchQueary = '';
        Object.keys(data).map((item, index) => {
            if (data[item]?.toString()) searchQueary += `${item}=${data[item]}&`;
        });
        let uid_existence = '';
        if(is_uid_existence) {
            uid_existence = 'yes';
        } else {
            uid_existence =  'no';
        }
        
        let config: any = {
            method: 'get',
            url: `${baseApiUrl}/${domainPath}/search?${searchQueary}is_uid_existence=${uid_existence}&config_type=${Config}&sub_config_type=${subConfig}`,
            headers: {
                Authorization: `Bearer ${is_accessToken}`
            }
        };
        const response = await axios(config);
        let finalResponse = response.data;
        if (response.data && response.data.response) {
            finalResponse = removeHiddenObjectFromMultiple(response.data?.response);
        }
        return finalResponse;
    } catch (error) {
        console.error('api function searchClient error');
        throwError(error);
    }
};

export const getEmbedAnalytics = async (accessToken: string) => {
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/pbi-analytics`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('api function getEmbedAnalytics error');
        throwError(error);
    }
};

export const getSearchFields = async (config_type: string, sub_config_type: string, accessToken: string) => {
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/search-config?config_type=${config_type}&sub_config_type=${sub_config_type}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('api function getEmbedAnalytics error');
        throwError(error);
    }
};

export const fetchProgramsForClient = async (client_code: string, currentUser: any) => {
    try {
        const response = await axios.get(`${baseApiUrl}/${domainPath}/program/${client_code}/`, {
            headers: {
                Authorization: `Bearer ${currentUser}`
            }
        });
        const data = response.data as unknown as any;
        return data;
    } catch (error) {
        console.error('api function fetch fetchProgramForClient error');
        throwError(error);
    }
};

function throwError(error: any) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response);
        console.log(error.response.data);
        console.log('code', error.response.status);
        console.log(error.response.headers);
        if (error.response.status === 403 || error.response.status === 401) {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user_role');
            alert(error.response.data.error);
            window.location.reload();
        }
        const errorResponse = {
            data: error.response.data || undefined,
            status: error.response.status || undefined
        };
        throw errorResponse;
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        const errorResponse = {
            data: { Error: 'unknown error occurred while contacting the server' },
            status: undefined
        };
        throw errorResponse;
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
}